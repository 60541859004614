<template>
<div class="js-backdrop" :class="{'open' : open}" @click="handleClose" />
</template>

<script>

export default {
	name: 'Backdrop',
	props: {
		open: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['close'],
	// data: {
	// 	open: false,
	// },
	methods: {
		handleClose() {
			// this.open = false;
			this.$emit('close', true);
		},
	},
};

</script>
