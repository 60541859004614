var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "FooterSponsors" } },
    [
      _c(
        "swiper",
        {
          ref: "swiper",
          attrs: { type: "carousel", options: _vm.swiperOptions }
        },
        _vm._l(_vm.slidesFormatted, function(slide, i) {
          return _c("swiper-slide", { key: i, class: _vm.slideClass }, [
            _c("img", { attrs: { src: slide.src, alt: slide.title } })
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }