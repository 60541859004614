var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal", class: { open: _vm.open } }, [
    _c(
      "div",
      { staticClass: "modal__container" },
      [
        _c(
          "button",
          {
            staticClass: "close",
            staticStyle: { left: "unset" },
            on: { click: _vm.handleClose }
          },
          [
            _c(
              "svg",
              { attrs: { width: "15", height: "15", viewbox: "0 0 15 15" } },
              [
                _c("path", { attrs: { d: "M1 1L15 15", stroke: "black" } }),
                _vm._v(" "),
                _c("path", { attrs: { d: "M15 1L1 15", stroke: "black" } })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }