<template>
<div class="add-to-cart-events-wrap">
	<FormulateForm>
		<div
			class="form-group form-group--inline flex flex-wrap justify-start items-end mx-0 "
		>
			<div v-if="events.length > 1" class="mr-xs mb-30 relative z-10">
				<label>Event Location</label>
				<Dropdown
					:value="activeVenue"
					class="vue-dropdown--normal"
					:options="events"
					@selected="updateEventId"
				/>
			</div>
			<div v-if="availableTickets.length > 1" class="mr-xs mb-30 relative">
				<label>Ticket Type</label>
				<Dropdown
					:options="JSON.parse(JSON.stringify(ticketOptions))"
					placeholder="1"
					class="vue-dropdown--normal"
					@selected="updatePurchaseableId"
				/>
			</div>
			<div v-if="availableTickets.length === 1" class="mr-15 mb-30 relative">
				<label>Ticket Type</label>
				<span class="block pt-xs">
					{{ ticketOptions[0].title }}
				</span>
			</div>
			<input type="hidden" name="purchasableId" :value="purchasableId">

			<FormulateInput
				v-model="quantity"
				name="qty"
				type="number"
				label="Qty"
				value="1"
				outer-class="form-group form-group--formulate form-group--styled form-group--spaced w-50 text-center mb-30 "
				element-class=" w-full"
			/>
		</div>
	</FormulateForm>
	<button
		:class="{ loading: loading }"
		:disabled="loading"
		class="btn btn--primary"
		@click="addToCart"
	>
		Add to Cart
	</button>
	<p
		v-for="(error, index) in cartError"
		:key="`error-${index}`"
		class="text-red mt-sm"
	>
		{{ error }}
	</p>
</div>
</template>

<script>
// import axios from 'axios';

import { getters, mutations, actions } from 'vstore/store';
import Dropdown from 'vcomponents/Dropdown';

export default {
	name: 'AddToCartCourse',
	components: {
		Dropdown,
	},
	props: {
		events: {
			type: Array,
			required: true,
		},
		tickets: {
			type: Array,
			required: true,
		},
	},
	emits: ['addToCart'],

	data() {
		return {
			quantity: 1,
			eventId: '',
			purchasableId: '',
			loading: false,
			availableOptions: {},
			availableTickets: [],
		};
	},
	computed: {
		cartError() {
			return getters.cartError();
		},
		activeVenue() {
			return getters.selectedLocation();
		},
		ticketOptions() {
			const ticketsArray = [];
			this.availableTickets.forEach((item) => {
				const ticket = {
					value: item.id,
					label: `${item.title} $${parseInt(item.price, 10)}`,
					handle: `${item.title} $${parseInt(item.price, 10)}`
						.replace(' ', '-')
						.toLowerCase(),
					title: `${item.title} $${parseInt(item.price, 10)}`,
				};
				ticketsArray.push(ticket);
			});
			return ticketsArray;
		},
	},
	watch: {
		activeVenue: {
			deep: true,
			handler(newValue) {
				this.eventId = newValue;
			},
		},
		eventId() {
			this.availableTickets = this.tickets.filter(
				ticket => ticket.eventId === parseInt(this.eventId, 10),
			);
			this.purchasableId =				this.ticketOptions[0] !== undefined
				? this.ticketOptions[0].value
				: undefined;
		},
	},
	mounted() {
		actions.csrfSet();

		this.purchasableId =			this.ticketOptions[0] !== undefined
			? this.ticketOptions[0].value
			: undefined;
		if (this.activeVenue === undefined) {
			mutations.setSelectedLocation(this.events[0].value);
			this.eventId = this.events[0].value;
		} else {
			const [defaultEvent] = this.events.filter(
				event => event.value === this.activeVenue,
			);
			this.eventId = defaultEvent.value;
		}
	},
	beforeDestroy() {
		mutations.setSelectedLocation(undefined);
	},
	methods: {
		addToCart() {
			this.$emit('addToCart');
			this.loading = true;
			const eventId = this.purchasableId;
			const qty = this.quantity;
			// eslint-disable-next-line quote-props
			actions.addToCart({ purchasableId: eventId, quantity: qty }).then(() => {
				setTimeout(() => {
					this.loading = false;
				}, 300);
			});
		},
		updatePurchaseableId(newVal) {
			this.purchasableId = newVal.value;
		},
		updateEventId(newVal) {
			this.eventId = newVal.value;
			mutations.setSelectedLocation(newVal.value);
		},
		findAvailableOptions(data) {
			// function that will loop through the variant options and make object
			// with all possible values for each key.
			// eg: { foo: [1, 2, 3], bar:[a, b] }
			// this is being used to generate the selects
			if (this.selectedOptions !== null) {
				const optionsObject = {};
				Object.keys(variant).forEach((key) => {
					if (optionsObject[key] === undefined) {
						optionsObject[key] = [];
					}
					optionsObject[key].push(variant[key]);
					optionsObject[key] = [...new Set(optionsObject[key])];
				});

				this.availableTickets = optionsObject;
			}
		},
	},
};
</script>
