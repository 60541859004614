import Vue from 'vue';
import axios from 'axios';
import marked from 'marked';
import ImgixClient from '@imgix/js-core';

const state = Vue.observable({
  // child section
  activeChild: undefined,
  activeChildId: undefined,
  childrenData: undefined,
  childModalOpen: false,
  // cart
  cart: {},
  cartOpen: false,
  cartError: '',
  csrfToken: undefined,
  csrfName: undefined,
  user: undefined,
  couponMessage: '',
  lineItems: [],
  // course
  selectedLocation: undefined,
  // other
  loading: true,
  imgix: new ImgixClient({
    domain: 'parenting-place.imgix.net',
    secureURLToken: '6088e5d86c782234f5a5d01f',
  }),
  gScore: 0,
});

const getters = {
  // child section
  activeChild: () => state.activeChild,
  activeChildId: () => state.activeChildId,
  // cart
  cart: () => state.cart,
  cartItems: () => state.cartItems,
  cartError: () => state.cartError,
  cartOpen: () => state.cartOpen,
  childModalOpen: () => state.childModalOpen,
  childrenData: () => state.childrenData,
  csrfName: () => state.csrfName,
  csrfToken: () => state.csrfToken,
  lineItems: () => state.lineItems,
  couponMessage: () => state.couponMessage,
  user: () => state.user,
  selectedLocation: () => state.selectedLocation,
  // other
  loading: () => state.loading,
  imgix: () => state.imgix,
  gScore: () => state.gScore,
};

const mutations = {
  // child section
  setActiveChild: (v) => {
    state.activeChild = v;
  },
  setActiveChildId: (v) => {
    state.activeChildId = v;
  },
  // cart
  setCartItems: (v) => {
    state.cartItems = v;
  },
  setCart: (v) => {
    state.cart = v;
  },
  setLineItems: (v) => {
    state.lineItems = v;
  },
  setCartOpen: (v) => {
    state.cartOpen = v;
  },
  setChildModalOpen: (v) => {
    state.childModalOpen = v;
  },
  setChildrenData: (v) => {
    state.childrenData = v;
  },
  setCartError: (v) => {
    state.cartError = v;
  },
  setCsrfName: (v) => {
    state.csrfName = v;
  },
  setCsrfToken: (v) => {
    state.csrfToken = v;
  },
  setCouponMessage: (v) => {
    state.couponMessage = v;
  },
  setUser: (v) => {
    state.user = v;
  },
  // course
  setSelectedLocation: (v) => {
    state.selectedLocation = v;
  },
  // other
  setLoading: (v) => {
    state.loading = v;
  },
  setGScore: (v) => {
    state.gScore = v;
  },
};

const actions = {
  async addToCart(options) {
    // actions.csrfSet();
    const data = new FormData();
    mutations.setLoading(true);
    data.append('action', 'commerce/cart/update-cart');
    data.append('purchasableId', options.purchasableId);
    data.append('qty', options.quantity);
    data.append('CRAFT_CSRF_TOKEN', window.csrfToken);

    return axios
      .post('/', data, {
        headers: { Accept: 'application/json' },
      })
      .then((response) => {
        console.log(response);
        const { status } = response;
        if (status == '200') {
          console.log('success');
          mutations.setCart(response.data.cart);
          mutations.setCartOpen(true);
        } else {
          console.log('fail');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        actions.handleCartError(error);
      });
  },
  handleCartError(error) {
    const { data } = error.response;
    mutations.setLoading(false);
    if (data) {
      const { message, errors } = data;
      let cartErrors = [];
      console.log(message, errors);
      Object.keys(errors).forEach((x) => {
        cartErrors.push(`${message} ${errors[x][0].replace(/-/g, ' ')}`);
      });
      mutations.setCartError(cartErrors);
      setTimeout(() => {
        mutations.setCartError('');
      }, 7000);
    }
  },
  async csrfSet() {
    await axios
      .get('/api/csrf')
      .then((response) => {
        if (response.data.token) {
          mutations.setCsrfName(response.data.name);
          mutations.setCsrfToken(response.data.token);
          return true;
        }
        return false;
      })
      .catch(() => false);
  },
  async fetchCart() {
    mutations.setLoading(true);
    actions.csrfSet().then(() => {
      const data = new FormData();
      // console.log("token", getters.csrfToken());
      data.append('CRAFT_CSRF_TOKEN', getters.csrfToken());
      data.append('action', 'commerce/cart/get-cart');
      //
      axios
        .post('/', data, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((response) => {
          mutations.setLoading(false);
          mutations.setCart(response.data.cart);
        })
        .catch((error) => {
          mutations.setLoading(false);
          // this.error = error;
          console.error(error);
        });
    });
  },
  async fetchChildrenData() {
    const query = await axios('/user.json')
      .then((response) => {
        // console.log(response.data.data[0].children);
        mutations.setChildrenData(response.data.data[0].children);
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
    return query;
  },
  async fetchLineItems() {
    mutations.setLoading(true);
    await axios
      .get('/commerce/cart/get-line-items?view=json')
      .then((response) => {
        mutations.setLoading(false);
        mutations.setLineItems(response.data);
      })
      .catch((error) => {
        this.error = error;
        console.error(error);
        mutations.setLoading(false);
      });
  },

  formatMoney(amount) {
    const formatter = new Intl.NumberFormat('en-NZ', {
      style: 'currency',
      currency: 'NZD',
    });

    return formatter.format(amount);
  },
  handelize(str) {
    return str
      .replace('& ', '')
      .replace(/\s/g, '-')
      .toLowerCase();
  },
  imgixImg(img, options = { auto: 'compress' }) {
    const newImg = img;
    if (img.path) {
      newImg.url = state.imgix.buildURL(newImg.path, options);
    } else {
      const newUrl = img.url
        .replace(window.location.hostname, '')
        .replace(window.location.protocol, '')
        .replace('//images', 'images');
      newImg.url = state.imgix.buildURL(newUrl, options);
    }

    newImg.url = state.imgix.buildURL(img.path, options);
    newImg.small = state.imgix.buildURL(img.path, { q: 30, blur: 50 });

    return newImg;
  },
  marked(value, options) {
    if (options && options.inline) {
      return marked.parseInline(value);
    }
    return marked(value);
  },
  async submitDiscountCode(couponCode) {
    mutations.setLoading(true);
    actions.csrfSet().then(() => {
      const data = new FormData();
      data.append('action', 'commerce/cart/update-cart');
      data.append('couponCode', couponCode);
      data.append('CRAFT_CSRF_TOKEN', window.csrfToken);

      axios
        .post('/', data, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => {
          const { status, data } = response;

          if (status == '200') {
            mutations.setCart(data.cart);
            if (data.cart.totalDiscount === 0) {
              mutations.setCouponMessage('Conditions Not Meet');
            } else {
              mutations.setCouponMessage('Discount Applied');
            }
            setTimeout(() => {
              mutations.setCouponMessage('');
            }, 7000);
          } else if (response.errors && response.errors.couponCode.length) {
            mutations.setCouponMessage(response.data.errors.couponCode[0]);
          }
        })
        .catch((error) => {
          // this.error = error;
          console.error(error);
        })
        .finally(() => {
          mutations.setLoading(false);
        });
    });
  },
  async updateQty(id, qty) {
    mutations.setLoading(true);
    actions.csrfSet().then(() => {
      const data = new FormData();
      data.append('CRAFT_CSRF_TOKEN', getters.csrfToken());
      data.append('action', 'commerce/cart/update-cart');
      data.append(`lineItems[${id}][qty]`, qty);

      // needs to go one after the other async or sync
      axios
        .post('/', data, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => {
          // console.log('qty resp', response);
          const { status } = response;
          if (status == '200') {
            mutations.setCart(response.data.cart);
            mutations.setCartOpen(true);
          }
          mutations.setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          actions.handleCartError(error);
        })
        .finally(() => {
          mutations.setLoading(false);
        });
    });
  },
  userInit() {
    if (getters.user() === undefined) {
      mutations.setUser(JSON.parse(window.user));
    }
  },
};

export { getters, mutations, actions, state };
