var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-payment-form-slideout" },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showButton,
              expression: "showButton"
            }
          ],
          staticClass: "btn",
          style: _vm.buttonColor.length ? "color:" + _vm.buttonColor : "",
          attrs: { id: _vm.formHandle },
          on: { click: _vm.handleOpen }
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.buttonText) + "\n\t")]
      ),
      _vm._v(" "),
      _c(
        "SlideOut",
        {
          attrs: { open: _vm.open, "wrap-class": _vm.bgColor },
          on: {
            close: function($event) {
              return _vm.handleClose()
            }
          }
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.success
              ? _c("div", { ref: "thanks", staticClass: "thanks w-full " }, [
                  _c("h1", { staticClass: "h2 w-full mb-md text-white" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.marked(_vm.successMessage, { inline: true })
                        ) +
                        "\n\t\t\t\t"
                    )
                  ]),
                  _vm._v(" "),
                  _vm.successPrompt
                    ? _c("div", {
                        staticClass: "markdown text-white",
                        domProps: {
                          innerHTML: _vm._s(_vm.marked(_vm.successPrompt))
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.successButton && _vm.successButton.length
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn--outline-white",
                          attrs: { href: _vm.successButton[0].buttonLink }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.successButton[0].buttonText)
                          )
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "btn btn--outline-white",
                          attrs: { href: "/" }
                        },
                        [_vm._v("\n\t\t\t\t\tReturn to homepage\n\t\t\t\t")]
                      ),
                  _vm._v(" "),
                  _vm.successImage.length
                    ? _c("img", {
                        staticClass: "mt-lg max-w-150",
                        attrs: { src: _vm.successImage, alt: "logo" }
                      })
                    : _vm._e()
                ])
              : _c(
                  "div",
                  {
                    key: "form",
                    ref: "payment-section",
                    staticClass: "payment-form w-full pb-lg"
                  },
                  [
                    _c(
                      "h1",
                      {
                        staticClass: "h2 w-full mb-30 text-white",
                        on: {
                          click: function($event) {
                            _vm.success = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.formText.length
                      ? _c("div", {
                          staticClass:
                            "markdown markdown--reduced-spacing mb-30",
                          domProps: {
                            innerHTML: _vm._s(_vm.marked(_vm.formText))
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.other && _vm.other !== "null"
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "underline self-start w-full ml-0 pl-0 mb-30 text-left",
                            attrs: { type: "button" },
                            on: { click: _vm.openOther }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  _vm.other === "oneOff"
                                    ? "Would you like to make a one-off donation instead?"
                                    : "Would you like to give monthly instead?"
                                ) +
                                "\n\t\t\t\t"
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "FormulateForm",
                      {
                        ref: "donationForm",
                        staticClass: "w-full",
                        attrs: { id: "enupal-stripe-" + _vm.formId },
                        on: { submit: _vm.handleSubmit },
                        model: {
                          value: _vm.formValues,
                          callback: function($$v) {
                            _vm.formValues = $$v
                          },
                          expression: "formValues"
                        }
                      },
                      [
                        _c("input", {
                          attrs: { type: "hidden", name: _vm.csrf.name },
                          domProps: { value: _vm.csrf.token }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            "aria-hidden": "true",
                            name: "action",
                            value: "enupal-stripe/stripe/save-order"
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: { type: "hidden", name: "token", value: "" }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: { type: "hidden", name: "formId" },
                          domProps: { value: _vm.formId }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name: "quantity",
                            value: "1"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2" },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  name: "firstName",
                                  label: "First Name",
                                  validation: "required",
                                  "wrapper-class":
                                    "form-group--styled form-group--white"
                                },
                                model: {
                                  value: _vm.formValues.firstName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formValues, "firstName", $$v)
                                  },
                                  expression: "formValues.firstName"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2" },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  name: "lastName",
                                  label: "Last Name",
                                  validation: "required",
                                  "wrapper-class":
                                    "form-group--styled form-group--white"
                                },
                                model: {
                                  value: _vm.formValues.lastName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formValues, "lastName", $$v)
                                  },
                                  expression: "formValues.lastName"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("FormulateInput", {
                          attrs: {
                            name: "email",
                            value: _vm.currentUser,
                            label: "Email Address",
                            validation: "required",
                            "wrapper-class":
                              "form-group--styled form-group--white"
                          },
                          model: {
                            value: _vm.formValues.email,
                            callback: function($$v) {
                              _vm.$set(_vm.formValues, "email", $$v)
                            },
                            expression: "formValues.email"
                          }
                        }),
                        _vm._v(" "),
                        _c("FormulateInput", {
                          attrs: {
                            name: "phone",
                            label: "Phone",
                            validation: "required",
                            "wrapper-class":
                              "form-group--styled form-group--white"
                          },
                          model: {
                            value: _vm.formValues.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.formValues, "phone", $$v)
                            },
                            expression: "formValues.phone"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2" },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  name: "addressLine1",
                                  label: "Address line 1",
                                  validation: "required",
                                  "wrapper-class":
                                    "form-group--styled form-group--white"
                                },
                                model: {
                                  value: _vm.formValues.addressLine1,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formValues,
                                      "addressLine1",
                                      $$v
                                    )
                                  },
                                  expression: "formValues.addressLine1"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2" },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  name: "addressLine2",
                                  label: "Address line 2",
                                  "wrapper-class":
                                    "form-group--styled form-group--white"
                                },
                                model: {
                                  value: _vm.formValues.addressLine2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formValues,
                                      "addressLine2",
                                      $$v
                                    )
                                  },
                                  expression: "formValues.addressLine2"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2" },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  name: "city",
                                  label: "City",
                                  validation: "required",
                                  "wrapper-class":
                                    "form-group--styled form-group--white"
                                },
                                model: {
                                  value: _vm.formValues.city,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formValues, "city", $$v)
                                  },
                                  expression: "formValues.city"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "w-full md:w-1/2" },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  name: "postCode",
                                  label: "Post Code",
                                  validation: "required",
                                  "wrapper-class":
                                    "form-group--styled form-group--white"
                                },
                                model: {
                                  value: _vm.formValues.postCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formValues, "postCode", $$v)
                                  },
                                  expression: "formValues.postCode"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex justify-start flex-wrap my-10 "
                          },
                          [
                            _vm._l(_vm.amounts, function(amount, index) {
                              return _c(
                                "button",
                                {
                                  key: amount + "-" + index,
                                  staticClass: "donation-circle",
                                  class: {
                                    active: _vm.formValues.amount === amount
                                  },
                                  attrs: { value: amount },
                                  on: { click: _vm.toggleAmount }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t$" +
                                      _vm._s(amount) +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "donation-circle",
                                attrs: { value: "0" },
                                on: { click: _vm.toggleAmount }
                              },
                              [_vm._v("\n\t\t\t\t\t\t\tOther\n\t\t\t\t\t\t")]
                            )
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("FormulateInput", {
                          attrs: {
                            name: "amount",
                            label: "Donation Amount",
                            type: "number",
                            validation: "required|min:5",
                            "wrapper-class":
                              "form-group--styled form-group--white"
                          },
                          model: {
                            value: _vm.formValues.amount,
                            callback: function($$v) {
                              _vm.$set(_vm.formValues, "amount", $$v)
                            },
                            expression: "formValues.amount"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "stripeElements",
                            staticClass: "w-full mb-sm pt-40"
                          },
                          [
                            _c("div", { staticClass: " flex flex-nowrap" }, [
                              _c("div", { staticClass: "w-full" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "body-text transform scale-80 origin-top-left"
                                  },
                                  [_vm._v("Card Number")]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  ref: "stripeNumber",
                                  staticClass: "stripe-input border-white",
                                  attrs: { id: _vm.formId + "-number-element" }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex-shrink-0 stripe-expiry" },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "body-text transform scale-80 origin-top-left"
                                    },
                                    [_vm._v("Exp Date")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    ref: "stripeExpiry",
                                    staticClass: "stripe-input border-white",
                                    attrs: {
                                      id: _vm.formId + "-expiry-element"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex-shrink-0 stripe-cvc" },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "body-text transform scale-80 origin-top-left"
                                    },
                                    [_vm._v("CVC")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    ref: "stripeCvc",
                                    staticClass: "stripe-input border-white",
                                    attrs: { id: _vm.formId + "-cvc-element" }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "card-errors" })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb-40" },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                name: "newsletter",
                                type: "checkbox",
                                label: "Sign up to the newsletter",
                                "wrapper-class":
                                  "form-group--styled form-group--white checkbox"
                              },
                              model: {
                                value: _vm.formValues.newsletter,
                                callback: function($$v) {
                                  _vm.$set(_vm.formValues, "newsletter", $$v)
                                },
                                expression: "formValues.newsletter"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        this.error.length
                          ? _c("p", { staticClass: "py-xs" }, [
                              _vm._v(" " + _vm._s(this.error) + " ")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--outline-white self-start",
                            class: { loading: _vm.loading },
                            attrs: { type: "submit", disabled: _vm.loading }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.formSubmit) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "recaptcha-blurb pt-sm" }, [
                          _c("p", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tThis site is protected by reCAPTCHA and the Google\n\t\t\t\t\t\t\t"
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://policies.google.com/privacy"
                                }
                              },
                              [_vm._v("Privacy Policy")]
                            ),
                            _vm._v("\n\t\t\t\t\t\t\tand\n\t\t\t\t\t\t\t"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://policies.google.com/terms"
                                }
                              },
                              [_vm._v("Terms of Service")]
                            ),
                            _vm._v("\n\t\t\t\t\t\t\tapply.\n\t\t\t\t\t\t")
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }