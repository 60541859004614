var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-summary md:sticky md:top-80" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.lineItems && _vm.lineItems.length
          ? _c("div", [
              _c(
                "div",
                { staticClass: "w-full border-t-1" },
                _vm._l(_vm.lineItems, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: "item-" + index,
                      staticClass:
                        "flex flex-no-wrap py-xs mb-15 border-b-1 relative"
                    },
                    [
                      _c("div", { staticClass: "w-3/12 " }, [
                        _c("img", { attrs: { src: item.image } })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-5/12 flex flex-col justify-between text-left"
                        },
                        [
                          _c("p", { staticClass: "body-copy w-10/12" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(item.title) +
                                "\n\t\t\t\t\t\t"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-2/12 text-right" }, [
                        _c("p", { staticClass: "body-copy" }, [
                          _vm._v("Qty: " + _vm._s(item.qty))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-2/12" }, [
                        _c("p", { staticClass: "body-copy text-right" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(
                                item.salePrice
                                  ? item.salePrice
                                  : item.variantPrice
                              ) +
                              "\n\t\t\t\t\t\t"
                          )
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "order-summary__section" }, [
                _c("div", { staticClass: "flex flex-wrap py-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "offset-3/12 w-9/12 flex justify-between mb-xs"
                    },
                    [
                      _c("p", { staticClass: "body-copy" }, [
                        _vm._v("Subtotal:")
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "body-copy" }, [
                        _vm._v(_vm._s(_vm.formatMoney(_vm.cart.itemSubtotal)))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.cart.storedTotalDiscount != 0 ||
                  _vm.cart.totalDiscount != 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "offset-3/12 w-9/12 flex justify-between mb-xs"
                        },
                        [
                          _c("p", { staticClass: "body-copy" }, [
                            _vm._v("Discount:")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "body-copy" }, [
                            _vm._v(_vm._s(_vm.cart.totalDiscountAsCurrency))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cart.totalSaleAmount
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "offset-3/12 w-9/12 flex justify-between mb-xs"
                        },
                        [
                          _c("p", { staticClass: "body-copy" }, [
                            _vm._v("Sale (incl):")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "body-copy" }, [
                            _vm._v(
                              "- " +
                                _vm._s(
                                  _vm.formatMoney(_vm.cart.totalSaleAmount)
                                )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cart.totalShippingCost
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "offset-3/12 w-9/12 flex justify-between mb-xs"
                        },
                        [
                          _c("p", { staticClass: "body-copy" }, [
                            _vm._v("Total Shipping:")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "body-copy" }, [
                            _vm._v(_vm._s(_vm.cart.totalShippingCostAsCurrency))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "offset-3/12 w-9/12 flex justify-between mb-xs"
                    },
                    [
                      _c("p", { staticClass: "body-copy" }, [_vm._v("Total")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "body-copy" }, [
                        _vm._v(_vm._s(_vm.formatMoney(_vm.cart.total)))
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-1/2 md:w-3/12 text-right" }, [
                  _c("div", { staticClass: "pt-md" })
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "order-summary__section" }, [
      _c("h2", { staticClass: "order-summary__title h5 mb-15" }, [
        _vm._v("Order Summary")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }