var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shipping-method-form checkout-step" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "SmoothReflow",
        {
          staticClass: "reflow",
          attrs: {
            options: {
              transitionEvent: {
                selector: ".reflow-element",
                propertyName: "opacity"
              }
            }
          }
        },
        [
          _c("transition-group", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.valid && _vm.step == 3,
                    expression: "!valid && step == 3"
                  }
                ],
                key: "form",
                staticClass: "checkout-step__inner reflow-element pt-25"
              },
              [
                _c(
                  "FormulateForm",
                  {
                    staticClass: "checkout-step__form form",
                    on: { submit: _vm.handleSubmit },
                    model: {
                      value: _vm.values,
                      callback: function($$v) {
                        _vm.values = $$v
                      },
                      expression: "values"
                    }
                  },
                  [
                    _c("div", { staticClass: "form__hidden" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "form__inner" }, [
                      Object.keys(_vm.shippingMethods).length > 1
                        ? _c(
                            "div",
                            { staticClass: "form__row" },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  type: "radio",
                                  name: "shippingMethodHandle",
                                  options: _vm.shippingMethods,
                                  validation: "^required",
                                  "validation-name": "This field is required",
                                  "wrapper-class": "py-xs",
                                  "outer-class": ""
                                },
                                model: {
                                  value: _vm.selectedShippingMethod,
                                  callback: function($$v) {
                                    _vm.selectedShippingMethod = $$v
                                  },
                                  expression: "selectedShippingMethod"
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", { staticClass: "free-shipping" }, [
                            _vm._v(
                              "\n              Standard Shipping\n            "
                            )
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form__row" }, [
                        _c(
                          "div",
                          { staticClass: "form__submit-wrapper pt-25" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn--primary",
                                class: { loading: _vm.loading },
                                attrs: { type: "submit" }
                              },
                              [
                                _vm._v(
                                  "\n                  Save & continue\n                "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.valid,
                    expression: "valid"
                  }
                ],
                key: "summary",
                staticClass: "checkout-step__summary reflow-element pt-25"
              },
              [
                _c("div", { staticClass: "address" }, [
                  _c("p", { staticClass: "address__row" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.selectedShippingMethod) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "underline",
                      attrs: { type: "button", value: "Edit" },
                      on: { click: _vm.stepBack }
                    },
                    [_vm._v("\n            Edit\n          ")]
                  )
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "checkout-step__header" }, [
      _c("h2", { staticClass: "checkout-step__title h5 " }, [
        _vm._v("3. Shipping")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }