var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "w-full" },
    [
      _c(
        "button",
        {
          staticClass:
            "body-text block mb-xs hover:text-red transition-color duration-200 ",
          class: { "text-red": _vm.visible },
          on: { click: _vm.handleLink }
        },
        [_vm._v("\n\t\tMy Family\n\t")]
      ),
      _vm._v(" "),
      _c("SmoothReflow", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible"
              }
            ],
            staticClass: "mb-sm"
          },
          [
            _c(
              "ul",
              { staticClass: "w-full mb-xs" },
              _vm._l(_vm.children, function(child, index) {
                return _c(
                  "li",
                  {
                    key: child.name.replace(" ", "-") + "-" + index,
                    staticClass:
                      "w-full ml-15 py-5 pr-sm flex justify-between cursor-pointer",
                    class: { "text-red": _vm.isChild(child) }
                  },
                  [
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.updateSelectedChild(child)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" + _vm._s(child.name) + "\n\t\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openEditChild(index)
                          }
                        }
                      },
                      [_vm._v("\n\t\t\t\t\t\t(Edit)\n\t\t\t\t\t")]
                    )
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "px-xs", on: { click: _vm.openAddChild } },
              [
                _c("span", [_vm._v("+")]),
                _vm._v(
                  "\n\t\t\t\tAdd\n\t\t\t\t" +
                    _vm._s(
                      _vm.children !== undefined && _vm.children.length > 0
                        ? "another"
                        : "a"
                    ) +
                    "\n\t\t\t\tchild\n\t\t\t"
                )
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }