var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slideout-container" },
    [
      _c(
        "aside",
        {
          staticClass: "js-popout ",
          class: [{ active: _vm.open }, _vm.classList]
        },
        [
          _c(
            "button",
            {
              staticClass:
                "js-close absolute top-25 right-30 lg:right-60 general-hover",
              class:
                _vm.wrapClass === "bg-bodyColor" ? "text-navy" : "text-white",
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [
              _c(
                "svg",
                { attrs: { width: "20", height: "20", viewBox: "0 0 20 20" } },
                [
                  _c("path", { attrs: { d: "M1 1L20 20" } }),
                  _vm._v(" "),
                  _c("path", { attrs: { d: "M20 1L1 20" } })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "px-30 sm:px-rg  py-lg sm:py-xl flex items-center flex-wrap text-left"
            },
            [_vm._t("default")],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("backdrop", {
        attrs: { open: _vm.open },
        on: {
          close: function($event) {
            return _vm.handleClose()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }