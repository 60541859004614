function scrollTo(target, options) {
	let scrollPostion = target.getBoundingClientRect().top + window.pageYOffset;
	if (options.offset) {
		scrollPostion += scrollPostion + options.offset;
	}
	window.scrollTo({ top: scrollPostion, behavior: 'smooth' });
}

// function scrollAnimate(eAmt, where) {
// 	if (where === 'center') window.scrollBy(0, eAmt / 2);
// 	if (where === 'top' || where === '') window.scrollBy(0, eAmt);
// }

const stopScroll = () => {
	const { scrollY } = window;
	const { body } = document;
	const html = document.querySelector('html');
	body.style.position = 'fixed';
	body.style.top = `-${scrollY}px`;
	html.style.overflowY = 'scroll';
};

const resumeScroll = () => {
	const { body } = document;
	const html = document.querySelector('html');
	const scrollY = body.style.top;
	body.style.position = '';
	body.style.top = '';
	html.style.overflowY = '';
	window.scrollTo({ top: parseInt(scrollY || '0', 10) * -1 });
};

export { scrollTo, stopScroll, resumeScroll };
