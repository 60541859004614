<template>
<a
	class="card"
	:class="cardModifiers"
	:href="content.url"
	:style="{ backgroundColor: content.color }"
>
	<div class="card__content">
		<!-- Resource cards image at top  -->
		<div
			v-if="content.type === 'resources' || content.type === 'general' || content.type === undefined"
			class="card__image-container"
		>
			<img
				class="card__image lazyload"
				:src="image.small"
				:alt="image.title"
				:data-src="image.url"
			>
			<img
				v-if="content.icon && content.icon.url"
				:src="content.icon.url"
				:alt="content.icon.title"
				class="card__icon"
			>
		</div>

		<div class="card__text-container">
			<p class="card__type ">
				<span
					v-for="(category, index) in content.categories"
					:key="`${index}-${category.id}`"
					class="small-caps"
				>{{ category.title ? category.title : category }}{{ index === content.categories.length - 1 ? '' : ', ' }}</span>
				<span v-if="content.type === undefined" class="small-caps"> Course </span>
			</p>
			<h4 class="card__title">{{ content.title }}</h4>
			<p
				v-if="content.text"
				class="card__text body-text text-center text-grey-500"
			>
				{{ content.text }}
			</p>
			<div v-if="price" class="card__cost mt-30">
				<span
					class="card__price body-text text-center text-grey-500"
					:class="{ 'line-through': salePrice != 0 }"
				>
					${{ price }}
				</span>
				<span
					v-if="salePrice != 0"
					class="body-text text-center text-red"
				>
					${{ salePrice }}
				</span>
			</div>
		</div>
	</div>

	<!-- Non resource cards images at the bottom -->
	<div
		v-if="content.type !== 'resources' && content.type !== 'general' && content.type !== undefined"
		class="card__image-container"
	>
		<img class="card__image" :src="image.url" :alt="image.title">
		<img
			v-if="content.icon && content.icon.url"
			:src="content.icon.url"
			:alt="content.icon.title"
			class="card__icon"
		>
	</div>
</a>
</template>

<script>

import { actions } from 'vstore/store';

export default {
	name: 'VueCard',
	components: {},
	props: {
		content: {
			type: Object,
			required: true,
		},
	},
	computed: {
		backgroundColor() {
			return this.content.color ? `background-color:${this.content.color};` : null;
		},
		image() {
			let image;
			if (this.content.image.url) {
				image = this.content.image;
			} else {
				[image] = this.content.image;
			}
			if (window.location.hostname.includes('test')) {
				return actions.imgixImg(image, { auto: 'compress', height: 250 });
			}
			return actions.imgixImg(image);
		},
		cardColor() {
			return this.content.color ? 'card--colored' : '';
		},
		cardType() {
			if (this.content.type === 'general') {
				return 'card--product';
			}
			if (this.content.type === undefined) {
				return 'card--resources';
			}
			return `card--${this.content.type}`;
		},
		price() {
			if (this.content.variants) {
				return this.content.variants[0].price;
			}
			return false;
		},
		salePrice() {
			if (this.content.variants) {
				if (
					this.content.variants[0].price === this.content.variants[0].salePrice
				) {
					return 0;
				}
				return this.content.variants[0].salePrice;
			}
			return false;
		},
		cardModifiers() {
			return `${this.cardColor} ${this.cardType}`;
		},
	},
};
</script>
