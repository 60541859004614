var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "vue-filter w-full border-b-0 special:border-b-1 border-grey-200 flex justify-center ",
      class: _vm.stacked ? "mb-sm md:mb-rg" : "mb-rg"
    },
    [
      _c(
        "div",
        { staticClass: "buttons hidden special:block" },
        _vm._l(JSON.parse(JSON.stringify(_vm.tags)), function(tag, i) {
          return _c(
            "button",
            {
              key: "resource-button-" + i,
              staticClass:
                "btn btn--tab ml-0 uppercase tracking-small text-small",
              class: { active: _vm.filters.tag === _vm.handelize(tag.handle) },
              on: {
                click: function($event) {
                  return _vm.updateFilterTag(tag)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(tag.title) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "select flex justify-center items-center special:hidden font-gilroy text-grey-500 tracking-small uppercase leading-standard text-small  "
        },
        [
          _c(
            "label",
            {
              staticClass:
                "font-gilroy text-grey-500 tracking-small uppercase leading-standard text-small ",
              class: _vm.stacked ? "xs:min-w-100 " : ""
            },
            [_vm._v("\n      Filter:\n    ")]
          ),
          _vm._v(" "),
          _c("Dropdown", {
            attrs: {
              options: JSON.parse(JSON.stringify(_vm.tags)),
              placeholder: "1",
              bg: "bg-" + _vm.bg
            },
            on: { selected: _vm.updateFilterTag }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }