var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-new-user" },
    [
      _c(
        "SmoothReflow",
        {
          staticClass: "reflow",
          attrs: {
            options: {
              transitionEvent: {
                selector: ".reflow-element",
                propertyName: "opacity"
              }
            }
          }
        },
        [
          _c("transition-group", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.valid && _vm.step == 1,
                    expression: "!valid && step == 1"
                  }
                ],
                key: "form",
                staticClass: "email-step formulate-form"
              },
              [
                _c("p", { staticClass: "mb-25" }, [
                  _vm._v("\n          Already have an account?\n          "),
                  _c(
                    "a",
                    {
                      staticClass: "underline",
                      attrs: { id: "show-login", href: "/login" }
                    },
                    [_vm._v("Login")]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "FormulateForm",
                  {
                    attrs: {
                      id: "new-user-form",
                      classes: "checkout-step__form form"
                    },
                    on: { submit: _vm.handleSubmit },
                    model: {
                      value: _vm.values,
                      callback: function($$v) {
                        _vm.values = $$v
                      },
                      expression: "values"
                    }
                  },
                  [
                    _c("p", { staticClass: "mb-25" }, [
                      _vm._v(
                        "\n            You must create an account with Parenting Place to start.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row " }, [
                      _c(
                        "div",
                        { staticClass: "col w-6/12" },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              type: "text",
                              name: "firstName",
                              validation: "^required",
                              "validation-name": "This field is required",
                              "input-class": "form-field",
                              label: "First Name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col w-6/12" },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              type: "text",
                              name: "lastName",
                              label: "Last Name",
                              validation: "^required",
                              "validation-name": "This field is required",
                              "input-class": "form-field"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "email",
                        name: "email",
                        label: "Email",
                        validation: "^required|email",
                        "input-class": "form-field"
                      }
                    }),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "password",
                        name: "password",
                        label: "Password",
                        validation: "^required|min:6,length",
                        "input-class": "form-field"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn--primary mt-25",
                        class: { " loading ": _vm.loading },
                        attrs: { disabled: _vm.loading, type: "submit" }
                      },
                      [_vm._v("\n            Save & continue\n          ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.error, function(problem, key) {
                  return _c(
                    "p",
                    { key: problem + "-" + key, staticClass: "text-red pt-15" },
                    [
                      key !== "unverifiedEmail" && key !== "username"
                        ? _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  problem
                                    .toString()
                                    .replace("[", "")
                                    .replace("]", "")
                                ) +
                                "\n            "
                            ),
                            key == "email"
                              ? _c("span", [
                                  _vm._v("If that's you,\n              "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "underline",
                                      attrs: { href: "/login" }
                                    },
                                    [_vm._v(" login here ")]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br")
                          ])
                        : _vm._e()
                    ]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.valid
              ? _c("div", { key: "summary", staticClass: "existing-user" }, [
                  _c("p", { staticClass: "body-copy" }, [
                    _vm._v(_vm._s(_vm.values.email))
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "underline",
                      attrs: { type: "button", value: "Edit" },
                      on: { click: _vm.stepBack }
                    },
                    [_vm._v("\n          Edit\n        ")]
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }