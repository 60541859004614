import { slideToggle, slideUp } from 'utils/slide';

const SearchBar = (() => {
	const init = () => {
		const searchTrigger = document.getElementById('search-trigger');
		const searchBar = document.getElementById('search-bar');
		if (searchBar !== null) {
			searchBar.querySelector('.js-close').addEventListener('click', () => { slideUp(searchBar); });
			searchTrigger.addEventListener('click', () => {
				slideToggle(searchBar);
				searchBar.querySelector('input').focus();
			});
		}
	};
	return {
		init,
	};
})();

export default SearchBar;
