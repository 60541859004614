// Throttle can not be passed arrow funtions.
//
// throttle function that catches and triggers last invocation
// use time to see if there is a last invocation
//
const throttle = (func, limit, ...args) => {
	let lastFunc;
	let lastRan;
	return () => {
		const context = this;
		if (!lastRan) {
			func.apply(context, args);
			lastRan = Date.now();
		} else {
			clearTimeout(lastFunc);
			lastFunc = setTimeout(() => {
				if ((Date.now() - lastRan) >= limit) {
					func.apply(context, args);
					lastRan = Date.now();
				}
			}, limit - (Date.now() - lastRan));
		}
	};
};
const filterObject = (obj, predicate) => {
	const result = {};

	Object.keys(obj).forEach((key) => {
		if (Object.prototype.hasOwnProperty.call(obj, key) && !predicate(obj[key])) {
			result[key] = obj[key];
		}
	});

	return result;
};
const debounce = (fn, time, ...args) => {
	let timeout;
	return () => {
		const functionCall = () => fn.apply(this, args);

		clearTimeout(timeout);
		timeout = setTimeout(functionCall, time);
	};
};

const isElement = o => (
	typeof HTMLElement === 'object' ? o instanceof HTMLElement // DOM2
		: o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string'
);

// Get siblings of elem.
// If selector is not null then also check that siblings match selector
const getSiblings = (elem, selector) => Array.prototype.filter.call(elem.parentNode.children, (sibling) => {
	if (selector !== undefined && selector !== null) {
		return sibling !== elem && sibling.matches(selector);
	}
	return sibling !== elem;
});

const shuffleArray = (array) => {
	let currentIndex = array.length; let temporaryValue; let
		randomIndex;

	// While there remain elements to shuffle...
	while (currentIndex !== 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
};

const isObject = object => object != null && typeof object === 'object';

function ObjectsAreIdentical(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	keys1.forEach((key) => {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		const examplesShareKeys = ObjectsAreIdentical(val1, val2);
		const bothObjectsButNotSame = areObjects && !examplesShareKeys;
		const botObjectsButDifferentValues = !areObjects && val1 !== val2;

		if (bothObjectsButNotSame || botObjectsButDifferentValues) {
			return false;
		}

		// console.log( object1, keys1)
		//  console.log( object2, keys2)
		return true;
	});
}

const parseTimeString = (timeString) => {
	const [, time] = timeString.split('T');
	const [hours, minutes] = time.split(':');
	const period = (hours < 12) ? 'AM' : 'PM';
	const hours12 = (hours > 12) ? hours - 12 : hours;
	return `${hours12}:${minutes} ${period}`;
};

export {
	isElement,
	throttle,
	debounce,
	getSiblings,
	shuffleArray,
	isObject,
	ObjectsAreIdentical,
	filterObject,
	parseTimeString,
};
