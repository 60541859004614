<template>
<div class="row">
	<content-filter :tags="tags" @updated="updateFilterTag" />

	<div class="w-full col">
		<div class="row">
			<div
				v-for="(product, i) in filteredProducts"
				:key="`${product.id}-${product.title}-${i}`"
				class="col w-full sm:w-1/2 md:w-4/12 xl:w-3/12 mb-30 fade-in"
			>
				<card :content="product" />
			</div>
		</div>
	</div>
</div>
</template>

<script>
// import { Dropdown } from "../modules/dropdown.js";
import axios from 'axios';
import { throttle } from 'utils/utilities';
import Card from 'vcomponents/Card';
import ContentFilter from 'vcomponents/ContentFilter';

export default {
	name: 'StoreIndex',
	components: {
		Card,
		ContentFilter,
	},
	props: {
		tags: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			windowWidth: '',
			resultsPerPage: 6,
			currentPage: 1,
			loading: false,
			filters: {
				tag: '',
			},
			products: [],
			shownProducts: [],
		};
	},
	computed: {
		limit() {
			if (this.windowWidth >= 1600) {
				return 8;
			}
			if (this.windowWidth >= 768) {
				return 6;
			}
			return 2;
		},
		filteredProducts() {
			const valCheck = this.handelize(this.filters.tag);
			if (this.shownProducts.length < 6 && this.nextLink !== undefined) {
				this.next();
			}
			if (this.shownProducts.length && this.filters.tag !== 'all') {
				return this.shownProducts.filter(item => item.categories.some(cat => cat.slug === valCheck));
			}
			return this.products;
		},
	},
	watch: {
		filters: {
			handler() {
				if (
					(this.shownProducts.length < 6 || this.filteredProducts.length < 6)
					&& this.nextLink !== undefined
				) {
					this.next();
				}
				const valCheck = this.handelize(this.filters.tag);
				if (valCheck === 'all') {
					this.shownProducts = this.products;
				}
			},
			deep: true,
		},
	},
	created() {
		this.getProducts(this.ProductQuery());
	},
	mounted() {
		this.filters.tag = this.handelize(this.tags[0].handle);
		this.windowWidth = window.innerWidth;
		window.addEventListener(
			'resize',
			throttle(() => {
				this.windowWidth = window.innerWidth;
			}, 200),
		);
	},
	methods: {
		handelize(str) {
			return str
				.replace('& ', '')
				.replace(/\s/g, '-')
				.toLowerCase();
		},
		updateFilterTag(value) {
			this.filters.tag = this.handelize(value);
		},
		ProductQuery(tag) {
			const params = [];
			params.push(`limit : ${this.resultsPerPage}`);
			params.push(`offset: ${(this.currentPage - 1) * this.resultsPerPage}`);
			if (tag) {
				params.push(`relatedToCategories: {slug: "${tag}"}`);
			}
			return `query products {
				products(${params.join(', ')}) {
					id
					title
					url: uri
					... on general_Product {
						text: abstract
						type: productTypeHandle
						categories: productType {
							title
							slug
						}
						image {
							id
							url
							title
							path
						}
					}
					variants {
						price
						priceAsCurrency
						id
						productTitle
						salePrice
						salePriceAsCurrency
					}
				}
			}
			`;
		},

		getProducts(qObject) {
			this.loading = true;
			axios('/api', {
				method: 'post',
				data: {
					query: qObject,
				},
			})
				.then(response => response.data)
				.then((response) => {
					this.products = response.data.products;
					this.products.forEach((product, index) => {
						this.products[index].url = `/${this.products[index].url}`;
					});
					this.shownProducts = this.products;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
