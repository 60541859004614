var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-children-wrap" },
    [
      _vm.childModalOpen
        ? _c("AddChildModal", {
            attrs: {
              open: _vm.childModalOpen,
              children: _vm.children,
              categories: _vm.categories,
              "active-id": _vm.activeId
            },
            on: {
              submit: _vm.updateChildren,
              close: function($event) {
                return _vm.handleClose()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.childModalOpen
        ? _c("backdrop", {
            attrs: { open: _vm.childModalOpen },
            on: {
              close: function($event) {
                return _vm.handleClose()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }