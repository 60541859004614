var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("content-filter", {
        attrs: { tags: _vm.tags },
        on: { updated: _vm.updateFilterTag }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "w-full col" }, [
        _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.filteredProducts, function(product, i) {
            return _c(
              "div",
              {
                key: product.id + "-" + product.title + "-" + i,
                staticClass:
                  "col w-full sm:w-1/2 md:w-4/12 xl:w-3/12 mb-30 fade-in"
              },
              [_c("card", { attrs: { content: product } })],
              1
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }