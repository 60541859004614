<template>
<div class="vue-dropdown" :class="{ isOpen: isOpen }">
	<div
		class="selected relative"
		:class="backgroundColor"
		@click="toggleIsOpen"
	>
		{{ selectedText }}
		<div class="chevron-wrap center-y right-neg-5 w-10 h-10">
			<svg
				class="chevron w-full transition-transform duration-200 transform  origin-center "
				viewBox="0 0 9 5"
				fill="none"
				:class="[isOpen ? 'rotate-180' : 'rotate-0']"
			>
				<path d="M1 1L4.5 4L8 1" stroke="#E63331" />
			</svg>
		</div>
	</div>
	<div
		ref="list"
		:class="{ isOpen: isOpen }"
		class="vue-dropdown__option-list "
	>
		<div class="vue-dropdown__list-container">
			<div
				v-for="(o, i) in options"
				:key="i"
				class="vue-dropdown__list-item"
				:class="{
					active:
						selected &&
						(selected === o.handle || selected === o.value || selected === o),
				}"
				@click="handleClick(o)"
			>
				{{ o.title ? o.title : o }}
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { slideDown, slideUp } from 'utils/slide';

export default {
	local: {
		id: '#vue-dropdown',
	},
	props: {
		bg: {
			type: String,
			default: 'transparent',
		},
		value: {
			type: String,
			default: '',
		},
		options: {
			type: Array,
			required: true,
		},
	},
	emits: ['selected'],
	data: () => ({
		selected: undefined,
		isOpen: false,
	}),
	computed: {
		selectedText() {
			if (this.selected !== undefined) {
				const [selectedOption] = this.options.filter(
					option => option.handle === this.selected || option.value === this.selected,
				);
				return selectedOption.title;
			}
			return '';
		},
		backgroundColor() {
			return `bg-${this.bg}`;
		},
	},
	watch: {
		value(newValue) {
			this.selected = newValue;
		},
		options: {
			deep: true,
			handler(newVal, oldVal) {
				if (newVal[0].value !== oldVal[0].value) {
					if (this.value.length) {
						this.handleClick(this.value);
					} else {
						this.handleClick(this.options[0]);
					}
				}
			},
		},
	},
	mounted() {
		if (this.value.length) {
			this.selected = this.value;
		} else {
			this.selected = this.options[0].value
				? this.options[0].value
				: this.options[0].handle;
		}
	},
	methods: {
		handleClick(o) {
			this.selected = o.value;
			this.$emit('selected', o);
		},

		toggleIsOpen() {
			if (this.isOpen) {
				this.handleClose();
			} else {
				this.handleOpen();
			}
		},
		handleOpen() {
			this.isOpen = true;
			slideDown(this.$refs.list, { duration: 400 }, () => {
				document.querySelector('body').addEventListener(
					'click',
					() => {
						this.handleClose();
					},
					{ once: true },
				);
			});
		},
		handleClose() {
			this.isOpen = false;
			slideUp(this.$refs.list);
		},
	},
};
</script>
