import { throttle } from 'utils/utilities';

const CourseHeaderOffset = (() => {
	let courseHeader;
	let bookCover;
	const setCourseHeaderOffset = () => {
		courseHeader = document.getElementById('course-header');
		bookCover = document.querySelector('.book-cover');

		// console.log(window.getComputedStyle(bookCover).paddingBottom);

		if (window.innerWidth > 997) {
			courseHeader.style.marginTop = `-${bookCover.getBoundingClientRect().height}px`;
		} else {
			courseHeader.style.marginTop = '0px';
		}
	};
	const init = () => {
		courseHeader = document.getElementById('course-header');
		bookCover = document.querySelector('.book-cover');
		if (bookCover) {
			setCourseHeaderOffset();

			window.addEventListener('resize', throttle(() => {
				setCourseHeaderOffset();
			}, 200));
		}
	};

	return {
		init,
	};
})();

export default CourseHeaderOffset;
