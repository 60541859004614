var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "col w-full border-b-1 border-grey-200 flex justify-center mb-rg vue-filter"
    },
    [
      _vm.windowWidth > 998
        ? _c(
            "div",
            { staticClass: "buttons" },
            _vm._l(_vm.tagArray, function(tag, i) {
              return _c(
                "button",
                {
                  key: "resource-button-" + i,
                  staticClass:
                    "btn btn--tab uppercase tracking-small text-small",
                  class: { active: _vm.filters.tag === _vm.handelize(tag) },
                  on: {
                    click: function($event) {
                      return _vm.updateFilterTag(tag)
                    }
                  }
                },
                [_vm._v("\n\t\t\t" + _vm._s(tag) + "\n\t\t")]
              )
            }),
            0
          )
        : _c(
            "div",
            {
              staticClass:
                "select flex justify-center items-center font-gilroy text-grey-500 tracking-small uppercase leading-standard text-small"
            },
            [
              _vm._v("\n\t\tFilter:\n\t\t"),
              _c("dropdown", {
                attrs: { options: _vm.tagArray, placeholder: "1", bg: "white" },
                on: { selected: _vm.updateFilterTag }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }