<template>
<div class="modal" :class="{ open: open }">
	<div class="modal__container">
		<button class="close" style="left:unset;" @click="handleClose">
			<svg width="15" height="15" viewbox="0 0 15 15">
				<path d="M1 1L15 15" stroke="black" />
				<path d="M15 1L1 15" stroke="black" />
			</svg>
		</button>
		<slot />
	</div>
</div>
</template>

<script>
import { stopScroll, resumeScroll } from 'utils/scrollControl.js';

export default {
	name: 'Modal',

	props: {
		open: {
			type: Boolean,
			defautl: false,
		},
		modalType: {
			type: String,
			default: '',
		},
	},
	emits: ['close'],
	data() {
		return {
			loading: false,
		};
	},

	watch: {
		open() {
			if (this.open) {
				stopScroll();
			} else {
				resumeScroll();
			}
		},
	},

	methods: {
		handleClose() {
			this.$emit('close');
		},
	},
};
</script>
