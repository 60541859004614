var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "card-carousel" } }, [
    _vm.windowWidth > 767
      ? _c(
          "div",
          {
            ref: "swiper",
            staticClass: "vue-card-carousel",
            class: [{ "fade-in": !_vm.loading }, { "fade-out": _vm.loading }],
            attrs: { type: "carousel" }
          },
          [
            _c(
              "Swiper",
              {
                staticClass: "vue-card-carousel",
                attrs: {
                  type: "carousel",
                  options: Object.assign({}, _vm.swiperOptions, {
                    loopedSlides: _vm.slides.length
                  })
                }
              },
              [
                _c("button", {
                  staticClass: "swiper-button-next",
                  attrs: { slot: "button-next" },
                  slot: "button-next"
                }),
                _vm._v(" "),
                _c("button", {
                  staticClass: "swiper-button-prev",
                  attrs: { slot: "button-prev" },
                  slot: "button-prev"
                }),
                _vm._v(" "),
                _vm._l(_vm.slides, function(slide, i) {
                  return _c(
                    "SwiperSlide",
                    { key: "slide-" + i, class: _vm.slideClass },
                    [
                      _c(
                        "div",
                        { staticClass: "card-wrapper" },
                        [_c("card", { attrs: { content: slide } })],
                        1
                      )
                    ]
                  )
                })
              ],
              2
            )
          ],
          1
        )
      : _c(
          "div",
          _vm._l(_vm.slides, function(slide, i) {
            return _c(
              "div",
              { key: i, staticClass: "card-wrapper px-sm mb-30" },
              [_c("card", { attrs: { content: slide } })],
              1
            )
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }