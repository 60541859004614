import { stopScroll, resumeScroll } from 'utils/scrollControl';

const navTrigger = (function () {
	let $trigger;
	let $nav;
	let state = 'closed';

	const handleAnimation = () => {
		$nav.classList.add('is-animating');
		setTimeout(() => {
			$nav.classList.remove('is-animating');
		}, 500);
	};
	const bindElements = () => {
		$nav = document.getElementById('main-nav');
		if ($nav !== undefined && $nav !== null) {
			$trigger = document.querySelectorAll('.main-nav-trigger');
		} else {
			console.warn('Cant find Nav');
		}
	};
	const handleClose = () => {
		handleAnimation();
		$nav.classList.remove('is-open');
		$trigger.forEach((trigger) => {
			trigger.setAttribute('aria-expanded', 'false');
			trigger.classList.remove('is-active');
		});

		state = 'closed';
	};
	const handleOpen = () => {
		handleAnimation();
		setTimeout(() => {
			$nav.classList.add('is-open');
			$trigger.forEach((trigger) => {
				trigger.setAttribute('aria-expanded', 'true');
				trigger.classList.add('is-active');
			});
			document.addEventListener('click', () => { handleClose(); }, { once: true });
			state = 'open';
		}, 50);
	};

	const toggleMenu = () => {
		if (state == 'closed') {
			handleOpen();
		} else {
			handleClose();
		}
	};
	const init = () => {
		bindElements();
		if ($nav !== undefined && $nav !== null) {
			$trigger.forEach((trigger) => {
				trigger.addEventListener('click', toggleMenu);
			});
			// navLinks.forEach(($link) => {
			// 	$link.addEventListener('click', toggleMenu);
			// });
		}
	};
	return {
		init,
	};
}());

export { navTrigger };
