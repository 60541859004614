import Player from "@vimeo/player";

const videoPlaceholder = (function() {
  const init = () => {
    let placeholderWraps = Array.from(
      document.querySelectorAll(".inline-video")
    );
    if (placeholderWraps != undefined) {
      placeholderWraps.forEach(video => {
        let placeholderVideo = video.querySelector(
          ".inline-video__placeholder"
        );
        let videoEmbed = video.querySelector("iframe");
        if (videoEmbed.src.includes("vimeo")) {
          let player = new Player(videoEmbed);
          let state = placeholderVideo.getAttribute("data-playing");

          placeholderVideo.addEventListener("click", e => {
            e.stopPropagation();
            // console.log(state)
            if (state == "false") {
              // console.log('click');
              placeholderVideo.classList.add("fade-out");
              placeholderVideo.classList.remove("fade-in");
              placeholderVideo.setAttribute("data-playing", "true");
              player.play();
            }
          });

          player.on("pause", () => {
            state = "false";
            placeholderVideo.classList.remove("fade-out");
            placeholderVideo.classList.add("fade-in");
            placeholderVideo.setAttribute("data-playing", "false");
          });
        }
      });
    }
  };

  return {
    init: init
  };
})();

export { videoPlaceholder };
