var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.appointments.length > 0
          ? _c("table", { staticClass: "w-full table--orders" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Date")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Type")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Start")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("End")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Canceled")])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.appointments, function(appointment) {
                  return _c("tr", { key: appointment.id }, [
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "underline",
                          attrs: {
                            href: appointment.confirmationPage,
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(appointment.date))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(appointment.type))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(appointment.time))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(appointment.endTime))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(appointment.canceled ? "Yes" : "No"))
                    ])
                  ])
                }),
                0
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.loading
            ? [
                _c("div", { staticClass: "lds-ripple" }, [
                  _c("div"),
                  _vm._v(" "),
                  _c("div")
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          (_vm.appointments.length = 0 && !_vm.loading)
            ? [
                _c("h3", { staticClass: "account-section__title h6" }, [
                  _vm._v(
                    "\n        You don't have any Coaching Sessions\n      "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "account-section__inner pt-15 pb-md" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn--primary mt-25",
                        attrs: { href: "/coaching" }
                      },
                      [_vm._v("Book now")]
                    )
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }