<template>
  <div id="product-checkout" class="container">
    <div class="row justify-center">
      <div
        class="col w-full md:w-6/12 lg:w-5/12 xl:w-4/12 mb-md md:mb-0 md:order-2"
      >
        <!-- Summary of order -->
        <v-order-summary />
      </div>

      <div class="col w-full md:w-6/12 xl:w-5/12 md:order-1">
        <div class="w-full lg:w-11/12">
          <div id="email-step" class="checkout-step">
            <div class="checkout-step__header">
              <h2 class="checkout-step__title h5 mb-25 ">1. Your Details</h2>
            </div>

            <div class="checkout-step__inner pb-25">
              <!-- Existing User -->
              <div v-if="isUser" class="existing-user">
                <p class="body-copy">{{ user.email }}</p>
              </div>

              <!-- New User Signup -->
              <v-checkout-new-user
                v-else
                :step="step"
                cra
                @edit="setStep"
                @next="nextStep"
                @signUp="updateUser"
              />
            </div>
            <p v-if="isUser" class="body-text">
              Not you? <a href="/logout" class="underline"> Logout </a>
            </p>
          </div>

          <div id="address-step">
            <!-- Address -->
            <v-address
              :customer="customer"
              :step="step"
              :cart="cart"
              :primary-shipping-address="primaryShipping"
              @updated="nextStep"
              @edit="setStep"
            />
          </div>
          <!-- Shipping -->
          <div id="shipping-step">
            <v-shipping-method
              :step="step"
              :updated-shipping="updatedShippingAddress"
              @updated="nextStep"
              @edit="setStep"
              @shipping="setShipping"
            />
          </div>

          <!-- Payment -->
          <div id="payment-step">
            <v-payment
              v-model:cart="updatedCart"
              :new-user="newUser"
              :redirect-url="redirectUrl"
              :cancel-url="cancelUrl"
              :customer="customer"
              :gateway="gateway"
              :stripe-pk="stripePk"
              :step="step"
              :shipping="updatedShippingAddress"
              :default-payment-option="defaultPaymentSource"
              :payment-namespace="paymentNamespace"
              @updated="nextStep"
              @edit="setStep"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getters } from 'vstore/store';

import VCheckoutNewUser from 'vstore/forms/checkout-new-user';
import VAddress from 'vstore/forms/checkout-address';
import VShippingMethod from 'vstore/forms/CartShipping';
import VPayment from 'vstore/forms/CartPayment';
import VOrderSummary from 'vcomponents/OrderSummary';

export default {
  name: 'Checkout',
  components: {
    VCheckoutNewUser,
    VAddress,
    VShippingMethod,
    VOrderSummary,
    VPayment,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    defaultPaymentSource: {
      type: Object,
      required: true,
    },
    gateway: {
      type: Object,
      required: true,
    },
    redirectUrl: {
      type: String,
      required: true,
    },
    cancelUrl: {
      type: String,
      required: true,
    },
    stripePk: {
      type: String,
      required: true,
    },
    paymentNamespace: {
      type: String,
      required: true,
    },
    primaryShipping: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      updatedShippingAddress: {},
      step: 1,
      updatedCart: {},
      updatedCsrf: {},
      loading: false,
      shippingMethod: null,
      newUser: {},
    };
  },
  computed: {
    cart() {
      return getters.cart();
    },
    lineItems() {
      return getters.lineItems();
    },
    isUser() {
      if (this.user !== null) {
        return this.user.email;
      }
      return false;
    },
  },
  mounted() {
    this.isUser ? this.setStep(2) : this.setStep(1);
    this.updatedCart = this.cart;
  },
  methods: {
    nextStep() {
      this.step += 1;
    },
    updateUser(value) {
      if (value.user) {
        this.newUser = value.user;
      }
    },
    setStep(value) {
      this.step = value;
    },
    setShipping(value) {
      this.shippingMethod = value;
    },
  },
};
</script>
