var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search" }, [
    _c(
      "form",
      {
        staticClass: "w-full flex items-center relative",
        attrs: { action: "/search" }
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.query,
              expression: "query"
            }
          ],
          staticClass:
            "mx-auto px-sm text-mob-h4 sm:text-h4 bg-transparent text-white xs:pl-0 xs:w-385",
          attrs: {
            type: "search",
            name: "q",
            "aria-label": "Search",
            placeholder: "I'm searching for...",
            autocomplete: "off"
          },
          domProps: { value: _vm.query },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.query = $event.target.value
              },
              _vm.throttleInput
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "absolute center-y w-30 md:w-auto right-0 lg:right-60  p-0 general-hover",
            attrs: { type: "submit" }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "47",
                  height: "8",
                  viewBox: "0 0 47 8",
                  fill: "none"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M46.3536 4.35355C46.5488 4.15829 46.5488 3.84171 46.3536 3.64645L43.1716 0.464466C42.9763 0.269204 42.6597 0.269204 42.4645 0.464466C42.2692 0.659728 42.2692 0.976311 42.4645 1.17157L45.2929 4L42.4645 6.82843C42.2692 7.02369 42.2692 7.34027 42.4645 7.53553C42.6597 7.7308 42.9763 7.7308 43.1716 7.53553L46.3536 4.35355ZM0 4.5H46V3.5H0V4.5Z",
                    stroke: "white"
                  }
                })
              ]
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "suggestions mx-auto max-w-385" },
      [
        _c(
          "SmoothReflow",
          {
            attrs: { transition: "height 0.25s ease-out", "self-update": true }
          },
          [
            _c(
              "transition-group",
              {
                ref: "transition",
                attrs: { name: "list-fade", tag: "div", appear: "" },
                on: { "before-leave": _vm.listLeave }
              },
              _vm._l(_vm.suggestions, function(suggestion, index) {
                return _c(
                  "div",
                  {
                    key: suggestion.handle + "-" + index,
                    staticClass: "suggestions__item block  py-5",
                    style: "--i: " + index
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "general-hover block",
                        attrs: { href: "/" + suggestion.url }
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "suggestions__title body-text" },
                          [_vm._v(_vm._s(suggestion.title))]
                        ),
                        _vm._v(" "),
                        suggestion.text
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "suggestions__abstract body-text  text-small"
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(suggestion.text) +
                                    "\n\t\t\t\t\t\t"
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }