var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "card",
      class: _vm.cardModifiers,
      style: { backgroundColor: _vm.content.color },
      attrs: { href: _vm.content.url }
    },
    [
      _c("div", { staticClass: "card__content" }, [
        _vm.content.type === "resources" ||
        _vm.content.type === "general" ||
        _vm.content.type === undefined
          ? _c("div", { staticClass: "card__image-container" }, [
              _c("img", {
                staticClass: "card__image lazyload",
                attrs: {
                  src: _vm.image.small,
                  alt: _vm.image.title,
                  "data-src": _vm.image.url
                }
              }),
              _vm._v(" "),
              _vm.content.icon && _vm.content.icon.url
                ? _c("img", {
                    staticClass: "card__icon",
                    attrs: {
                      src: _vm.content.icon.url,
                      alt: _vm.content.icon.title
                    }
                  })
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "card__text-container" }, [
          _c(
            "p",
            { staticClass: "card__type " },
            [
              _vm._l(_vm.content.categories, function(category, index) {
                return _c(
                  "span",
                  { key: index + "-" + category.id, staticClass: "small-caps" },
                  [
                    _vm._v(
                      _vm._s(category.title ? category.title : category) +
                        _vm._s(
                          index === _vm.content.categories.length - 1
                            ? ""
                            : ", "
                        )
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _vm.content.type === undefined
                ? _c("span", { staticClass: "small-caps" }, [
                    _vm._v(" Course ")
                  ])
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "card__title" }, [
            _vm._v(_vm._s(_vm.content.title))
          ]),
          _vm._v(" "),
          _vm.content.text
            ? _c(
                "p",
                {
                  staticClass: "card__text body-text text-center text-grey-500"
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.content.text) + "\n\t\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.price
            ? _c("div", { staticClass: "card__cost mt-30" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "card__price body-text text-center text-grey-500",
                    class: { "line-through": _vm.salePrice != 0 }
                  },
                  [_vm._v("\n\t\t\t\t\t$" + _vm._s(_vm.price) + "\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _vm.salePrice != 0
                  ? _c(
                      "span",
                      { staticClass: "body-text text-center text-red" },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t$" + _vm._s(_vm.salePrice) + "\n\t\t\t\t"
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm.content.type !== "resources" &&
      _vm.content.type !== "general" &&
      _vm.content.type !== undefined
        ? _c("div", { staticClass: "card__image-container" }, [
            _c("img", {
              staticClass: "card__image",
              attrs: { src: _vm.image.url, alt: _vm.image.title }
            }),
            _vm._v(" "),
            _vm.content.icon && _vm.content.icon.url
              ? _c("img", {
                  staticClass: "card__icon",
                  attrs: {
                    src: _vm.content.icon.url,
                    alt: _vm.content.icon.title
                  }
                })
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }