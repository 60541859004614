var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-wrap justify-start xs:justify-center mobile--border-hide row",
      attrs: { id: "resource-filter" }
    },
    [
      _c(
        "div",
        { staticClass: "col w-full xs:w-1/2 md:w-full z-4" },
        [
          _c("content-filter", {
            attrs: {
              tags: _vm.tags,
              stacked: true,
              selected: "" + _vm.filters.tag
            },
            on: { updated: _vm.updateFilterTag }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.ageFilter.length
        ? _c("div", { staticClass: "col w-full xs:w-1/2 md:w-full" }, [
            _c(
              "div",
              {
                staticClass:
                  "md:pt-lg md:pb-md md:px-md md:shadow-card mb-rg mx-auto"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      " w-full relative hidden md:flex justify-between items-center border-t-1 border-grey-500 h-50"
                  },
                  _vm._l(_vm.ageFilter, function(age) {
                    return _c(
                      "button",
                      {
                        key: age.handle,
                        staticClass: "age-button",
                        class: { active: _vm.filters.age === age.handle },
                        on: {
                          click: function($event) {
                            return _vm.toggleAge(age.handle)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(age.title) + "\n\t\t\t\t"
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "select flex md:hidden justify-start xs:justify-center items-center font-gilroy text-grey-500 tracking-small uppercase text-small"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "\n              xs:min-w-100\n              font-gilroy\n              text-grey-500\n              tracking-small\n              uppercase\n              text-small\n            "
                      },
                      [_vm._v("\n\t\t\t\t\tAge Group:\n\t\t\t\t")]
                    ),
                    _vm._v(" "),
                    _c("dropdown", {
                      staticClass: "vue-dropdown--narrow",
                      attrs: { options: _vm.ageDropdown },
                      on: { selected: _vm.toggleAge }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("SmoothReflow", { staticClass: "w-full" }, [
        _c("div", { staticClass: "col w-full " }, [
          _c(
            "div",
            { staticClass: "row relative" },
            _vm._l(_vm.allResources, function(card, i) {
              return _c(
                "div",
                {
                  key: card.title + "-" + i,
                  staticClass:
                    "col w-full xs:w-1/2 md:w-4/12 xl:w-3/12 pb-30 fade-in"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full transition-opacity duration-200 h-full"
                    },
                    [_c("card", { attrs: { content: card } })],
                    1
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          !_vm.loading && _vm.allResources.length < _vm.entryCount
            ? _c(
                "button",
                {
                  staticClass: "btn btn--outline block mx-auto mt-md",
                  on: { click: _vm.next }
                },
                [_vm._v("\n\t\t\t\tView More\n\t\t\t")]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.loading && !_vm.allResources.length
          ? _c("div", { staticClass: "col w-full text-center" }, [
              _c("p", { staticClass: "body-text" }, [_vm._v("No Results")])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }