<template>
<div
	id="resource-filter"
	class="flex flex-wrap justify-start xs:justify-center mobile--border-hide row"
>
	<div class="col w-full xs:w-1/2 md:w-full z-4">
		<content-filter
			:tags="tags"
			:stacked="true"
			:selected="`${filters.tag}`"
			@updated="updateFilterTag"
		/>
	</div>
	<!-- Age Selector -->
	<div v-if="ageFilter.length" class="col w-full xs:w-1/2 md:w-full">
		<div class="md:pt-lg md:pb-md md:px-md md:shadow-card mb-rg mx-auto">
			<div
				class=" w-full relative hidden md:flex justify-between items-center border-t-1 border-grey-500 h-50"
			>
				<button
					v-for="age in ageFilter"
					:key="age.handle"
					class="age-button"
					:class="{ active: filters.age === age.handle }"
					@click="toggleAge(age.handle)"
				>
					{{ age.title }}
				</button>
			</div>
			<div
				class="select flex md:hidden justify-start xs:justify-center items-center font-gilroy text-grey-500 tracking-small uppercase text-small"
			>
				<label
					class="
              xs:min-w-100
              font-gilroy
              text-grey-500
              tracking-small
              uppercase
              text-small
            "
				>
					Age Group:
				</label>
				<dropdown
					:options="ageDropdown"
					class="vue-dropdown--narrow"
					@selected="toggleAge"
				/>
			</div>
		</div>
	</div>
	<!-- Cards -->
	<SmoothReflow class="w-full">
		<div class="col w-full ">
			<div class="row relative">
				<div
					v-for="(card, i) in allResources"
					:key="`${card.title}-${i}`"
					class="col w-full xs:w-1/2 md:w-4/12 xl:w-3/12 pb-30 fade-in"
				>
					<div class="w-full transition-opacity duration-200 h-full">
						<card :content="card" />
					</div>
				</div>
			</div>
			<button
				v-if="!loading && allResources.length < entryCount"
				class="btn btn--outline block mx-auto mt-md"
				@click="next"
			>
				View More
			</button>
		</div>
		<div
			v-if="!loading && !allResources.length"
			class="col w-full text-center"
		>
			<p class="body-text">No Results</p>
		</div>
	</SmoothReflow>
</div>
</template>

<script>
// import { Dropdown } from "../modules/dropdown.js";
import axios from 'axios';
import { debounce } from 'utils/utilities';
import Card from 'vcomponents/Card';
import ContentFilter from 'vcomponents/ContentFilter';
import Dropdown from 'vcomponents/Dropdown';

export default {
	name: 'ResourceFilter',
	components: {
		Card,
		ContentFilter,
		Dropdown,
	},
	props: {
		tags: {
			type: Array,
			required: true,
		},
		ageFilter: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	emits: ['next'],
	data() {
		return {
			windowWidth: '',
			loading: false,
			offset: 0,
			entryCount: 0,
			filters: {
				tag: '',
				age: '',
			},

			shownResources: [],
			allResources: [],
		};
	},
	computed: {
		ageDropdown() {
			const array = JSON.parse(JSON.stringify(this.ageFilter));
			array.unshift({ handle: 'all', title: 'All' });
			return array;
		},
		increment() {
			if (this.windowWidth >= 1600) {
				return 8;
			}
			if (this.windowWidth >= 768) {
				return 6;
			}
			return 2;
		},
	},
	watch: {
		filters: {
			handler() {
				this.allResources = [];
				this.offset = 0;
				this.getArticles(this.ArticlesQuery());
			},
			deep: true,
		},
	},
	created() {
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('cat')) {
			this.filters.tag = urlParams.get('cat');
		} else {
			this.filters.tag = this.handelize(this.tags[0].handle);
		}
	},
	mounted() {
		this.windowWidth = window.innerWidth;
		window.addEventListener(
			'resize',
			debounce(() => {
				this.windowWidth = window.innerWidth;
			}, 200),
		);
	},
	methods: {
		setCategoryQuery() {
			const newQuery = [];
			if (this.filters.tag !== 'all') {
				newQuery.push(this.filters.tag);
			}
			if (this.filters.age !== 'all' && this.filters.age !== '') {
				newQuery.push(`${this.filters.age}`);
			}
			return newQuery;
		},
		toggleAge(value) {
			const age = value.handle ? value.handle : value;
			this.filters.age = age;
		},
		handelize(str) {
			return str
				.replace('& ', '')
				.replace(/\s/g, '-')
				.toLowerCase();
		},
		updateFilterTag(value) {
			this.filters.tag = this.handelize(value);
		},
		ArticlesQuery() {
			const tags = this.setCategoryQuery();
			const formattedTags = [];
			let entryCountParam = ' section: "resources" ';
			let params = `type: "resources", orderBy: "postDate DESC", limit: ${
				this.increment
			}, offset: ${this.offset} `;
			// loop through tags and format query;
			if (tags.length !== 0) {
				tags.forEach((item) => {
					formattedTags.push(`{slug: "${item}"}`);
				});
				const relatedTo = `, relatedToCategories: [${formattedTags}]`;
				params = params.concat(relatedTo);
				entryCountParam = entryCountParam.concat(relatedTo);
			} else {
				params = params.concat(', relatedToCategories: { slug: [] }');
			}

			return `query articles{
				entries( ${params}) {
					... on resources_resources_Entry {
						id
						type :typeHandle
						title
						url
						text: blurb
						categories: articleCategory {
				      title
							slug
				    }
						ageGroup: ageGroup{
							title
							slug
						}
						image {
							id
							url
							title
							path
						}
					}
				}
				total: entryCount( ${entryCountParam} )
			}`;
		},
		async getArticles(qObject) {
			this.loading = true;
			axios('/api', {
				method: 'post',
				data: {
					query: qObject,
				},
			})
				.then((response) => {
					// console.log(response.data);
					if (response.data.errors) {
						return false;
					}
					return response.data;
				})
				.then((response) => {
					// const arrayHolder = JSON.parse(JSON.stringify(this.allResources));
					this.allResources = this.allResources.concat(response.data.entries);
					this.entryCount = response.data.total;
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		next() {
			this.$emit('next');
			this.offset += this.increment;
			this.getArticles(this.ArticlesQuery());
		},
	},
};
</script>
