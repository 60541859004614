var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "cart-trigger" } }, [
    _c(
      "button",
      {
        staticClass: "mr-xs md:mr-xs nav__link",
        attrs: { href: "/account" },
        on: { click: _vm.openCart }
      },
      [
        _vm._v(
          "\n\t\tCart " +
            _vm._s(
              _vm.lineItems.length ? "(" + _vm.lineItems.length + ")" : ""
            ) +
            "\n\t"
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }