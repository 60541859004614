import { slideUp, slideDown } from 'utils/slide';

const notificationCookie = (() => {
  let $notificationItems;
  let notificationId;
  let timeout;

  const init = () => {
    $notificationItems = document.querySelectorAll('.js-notification');
    $notificationItems.forEach(($notificationItem) => {
      const $close = $notificationItem.querySelector('.js-close');
      timeout = $notificationItem.getAttribute('data-timeout');
      // console.log($notificationItems);
      if (checkNotificationStatus($notificationItem) == false) {
        let timeIn;
        if (
          $notificationItem.getAttribute('data-timein') != null &&
          $notificationItem.getAttribute('data-timein') != undefined
        ) {
          timeIn = $notificationItem.getAttribute('data-timein');
        } else {
          timeIn = 750;
        }
        setTimeout(() => {
          if ($notificationItem.classList.contains('js-notification--bar')) {
            slideDown($notificationItem);
            $close.addEventListener('click', () => {
              handleCloseNotification($notificationItem);
            });
          } else {
            $notificationItem.classList.add('active');

            $close.addEventListener('click', () => {
              handleCloseNotification($notificationItem);
            });
          }
        }, timeIn);
      } else {
        $notificationItem.remove();
      }
    });
  };

  const checkNotificationStatus = ($notificationItem) => {
    notificationId = $notificationItem.getAttribute('data-notification-id');
    const timeoutDays = timeout;
    const storage = window.sessionStorage;
    const originalCookie = storage.getItem(`notification-${notificationId}`);
    const now = Date.now();

    if (
      originalCookie != null &&
      originalCookie != undefined &&
      parseInt(originalCookie) + parseInt(timeoutDays) > now
    ) {
      return true;
    }
    return false;
  };

  const handleCloseNotification = ($notificationItem) => {
    if ($notificationItem.classList.contains('js-notification--bar')) {
      slideUp($notificationItem);
      setTimeout(() => {
        $notificationItem.remove();
      }, 300);
    }
    $notificationItem.classList.remove('active');
    notificationId = $notificationItem.getAttribute('data-notification-id');
    const timestamp = Date.now();
    const storage = window.sessionStorage;
    storage.setItem(`notification-${notificationId}`, timestamp.toString());
    setTimeout(() => {
      $notificationItem.remove();
    }, 300);
  };

  return {
    init,
    handleCloseNotification,
  };
})();

export { notificationCookie };
