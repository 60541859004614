import { throttle } from 'utils/utilities.js';

const stickyNav = (() => {
	let $nav;
	let animating = false;
	let navPos;

	const setSticky = () => {
		animating = true;

		$nav.classList.add('nav-sticky--hide', 'nav-sticky');

		setTimeout(() => {
			$nav.classList.add('nav-sticky--show');
			$nav.classList.remove('nav-sticky--hide');
			animating = false;
		}, 200);
	};

	const removeSticky = () => {
		animating = true;

		$nav.classList.add('nav-sticky--hide');

		setTimeout(() => {
			$nav.classList.remove('nav-sticky--hide', 'nav-sticky', 'nav-sticky--show');

			animating = false;
		}, 200);
	};

	const handleSticky = throttle(() => {
		if (window.scrollY >= window.innerHeight * 1.75 && $nav.matches('.nav-sticky') === false && !animating) {
			// console.log('Set Nav Sticky ');
			setSticky();
		} else if (window.scrollY <= window.innerHeight * 1.75 && $nav.matches('.nav-sticky') && !animating) {
			removeSticky();
		}
	}, 200, { leading: true });

	const alwaysSticky = throttle(() => {
		if (window.scrollY >= navPos && $nav.matches('.nav-sticky') === false && !animating) {
			$nav.classList.add('nav-sticky--show', 'nav-sticky');
		} else if (window.scrollY <= navPos && $nav.matches('.nav-sticky') && !animating) {
			$nav.classList.remove('nav-sticky--show', 'nav-sticky');
		}
	}, 200, { leading: true });

	const init = () => {
		$nav = document.getElementById('main-nav');

		if ($nav !== undefined && $nav !== null) {
			navPos = $nav.getBoundingClientRect().y;
			const sticky = $nav.getAttribute('data-sticky');
			if (sticky === 'always') {
				// console.log('always');
				// window.addEventListener('scroll', alwaysSticky);
			} else {
				window.addEventListener('scroll', handleSticky);
			}
		} else {
			console.warn('Sticky nav cant find nav');
		}
	};

	return {
		init,
	};
})();

export { stickyNav };
