import 'detect-autofill';
import { isElement } from 'utils/utilities';

const labelSlide = (() => {
	const init = (inputs) => {
		// if inputs is undefined, grab every input in the doc

		let inputArray;
		if (inputs === undefined) {
			inputArray = Array.from(document.querySelectorAll('.form-group--styled input'));
			const textareas = Array.from(document.querySelectorAll('.form-group--styled textarea'));
			inputArray = inputArray.concat(textareas);
		} else {
			inputArray = !Array.isArray(inputs) ? Array.from(inputs) : inputs;
		}
		inputArray.forEach((input) => {
			const parent = input.closest('.form-group');

			if (isElement(parent)) {
			// Default state of input and label on page load
				if (input.value !== '') {
					parent.classList.add('active');
				}
				input.addEventListener('focusin', () => {
					parent.classList.add('active');
				});
				input.addEventListener('blur', () => {
					if (input.value === '') {
						parent.classList.remove('active');
					}
				});
			}
		});
		document.addEventListener('onautocomplete', (e) => {
			// console.log(e.path[0].hasAttribute('autocompleted'), 'autocompleted');
			const autocompletedInput = e.path[0];
			const parent = autocompletedInput.closest('.form-group--styled');
			if (parent !== null) {
				parent.classList.add('active');
			}
		});
	};
	return {
		init,
	};
})();

export default labelSlide;
