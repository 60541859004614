<template>
<nav
	class="col w-full border-b-1 border-grey-200 flex justify-center mb-rg vue-filter"
>
	<div v-if="windowWidth > 998" class="buttons">
		<button
			v-for="(tag, i) in tagArray"
			:key="`resource-button-${i}`"
			class="btn btn--tab uppercase tracking-small text-small"
			:class="{ active: filters.tag === handelize(tag) }"
			@click="updateFilterTag(tag)"
		>
			{{ tag }}
		</button>
	</div>
	<div
		v-else
		class="select flex justify-center items-center font-gilroy text-grey-500 tracking-small uppercase leading-standard text-small"
	>
		Filter:
		<dropdown
			:options="tagArray"
			placeholder="1"
			bg="white"
			@selected="updateFilterTag"
		/>
	</div>
</nav>
</template>

<script>
import { throttle } from 'utils/utilities';
import Dropdown from 'vcomponents/Dropdown';

export default {
	name: 'ContentFilter',
	components: {
		Dropdown,
	},
	props: {
		bg: {
			type: String,
			default: 'transparent',
		},
		tags: {
			type: String,
			required: true,
		},
	},
	emits: ['updated'],
	data() {
		return {
			windowWidth: '',
			loading: false,

			filters: {
				tag: '',
			},
		};
	},
	computed: {
		tagArray() {
			return JSON.parse(this.tags);
		},
	},
	mounted() {
		this.windowWidth = window.innerWidth;
		window.addEventListener(
			'resize',
			throttle(() => {
				// console.log('resize');
				this.windowWidth = window.innerWidth;
			}, 200),
		);
		this.filters.tag = this.handelize(this.tagArray[0]);
	},
	methods: {
		handelize(str) {
			return str
				.replace('& ', '')
				.replace(/\s/g, '-')
				.toLowerCase();
		},
		updateFilterTag(selected) {
			this.filters.tag = this.handelize(selected);
			this.$emit('updated', selected);
		},
	},
};
</script>
