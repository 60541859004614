const SlideoutTriggers = (() => {
	const closeSlideout = (target) => {
		const backdrop = document.querySelector('.js-backdrop');
		document.querySelector('body').classList.remove('slideout-open');
		target.classList.remove('active');
		backdrop.classList.add('close');
	};

	const	openSlideout = (target, item) => {
		const backdrop = document.querySelector('.js-backdrop');
		// console.log(target, item);
		document.querySelector('body').classList.add('slideout-open');
		if (!target.classList.contains('active')) {
			target.classList.add('active');
			backdrop.classList.remove('close');
			backdrop.addEventListener('click', () => { closeSlideout(target, item); }, { once: true });
			const close = target.querySelector('.js-close');
			close.addEventListener('click', () => { closeSlideout(target, item); }, { once: true });
		}
	};

	const bindEvents = (item) => {
		const target = document.querySelector(item.getAttribute('data-target'));
		item.addEventListener('click', () => {
			openSlideout(target, item);
		});
	};

	const	init = () => {
		const triggers = document.querySelectorAll('.js-slideout-toggle');
		// console.log(triggers);
		triggers.forEach((item) => { bindEvents(item); });
	};
	return {
		init,
	};
})();

export default SlideoutTriggers;
