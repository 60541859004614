var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hero-slider-wrap relative", class: _vm.bg },
    [
      _c(
        "Swiper",
        {
          ref: "swiper",
          attrs: {
            type: "carousel",
            options: _vm.swiperOptions,
            id: "hero-slider"
          },
          on: { "slide-change-transition-start": _vm.updateActive }
        },
        _vm._l(_vm.slides, function(slide, i) {
          return _c("SwiperSlide", { key: i }, [
            _c(
              "section",
              {
                staticClass: "overflow-hidden relative flex flex-wrap h-full ",
                class: [_vm.bg, _vm.orientationClass]
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "col w-full sm:w-6/12 hero-slider__text-wrap"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-container max-w-500 xl:max-w-650 w-full"
                      },
                      [
                        slide.subheading
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "text-red uppercase mb-25 tracking-small text-small"
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(slide.subheading) +
                                    "\n\t\t\t\t\t"
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "h1",
                          {
                            staticClass:
                              "text-purple-dark mb-40 h3 xl:h1  md:pr-lg"
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(slide.heading) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        slide.text
                          ? _c("div", {
                              staticClass: "max-w-350 mb-40",
                              domProps: {
                                innerHTML: _vm._s(_vm.marked(slide.text))
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        slide.buttons.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "button-container flex flex-wrap md:flex-no-wrap mb-sm md:mb-0"
                              },
                              _vm._l(slide.buttons, function(button, i) {
                                return _c(
                                  "a",
                                  {
                                    key: i,
                                    staticClass: "btn mb-sm",
                                    class: _vm.btnClass(i),
                                    attrs: {
                                      href: button.url,
                                      "data-gallery": "hero-panel"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(button.text) +
                                        "\n\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-mobile-edge w-full sm:w-1/2 relative sm:absolute sm:h-full top-0 overflow-hidden sm:-mx-15 md:mx-0 flex flex-wrap",
                    class: [_vm.orientation === "right" ? "left-0" : "right-0"]
                  },
                  [
                    slide.image
                      ? _c("img", {
                          staticClass:
                            "w-fitFix h-fitFix object-cover object-center md:mb-0 sm:absolute top-0 left-0",
                          attrs: {
                            src: slide.image.url,
                            alt: slide.image.title
                          }
                        })
                      : _vm._e()
                  ]
                )
              ]
            )
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col w-full sm:w-6/12 flex items-center text-left justify-center absolute top-30 sm:top-unset sm:bottom-30 left-0 z-10"
        },
        [
          _c("div", { staticClass: " max-w-500 xl:max-w-650 w-full" }, [
            _c(
              "div",
              { staticClass: "swiper-pagination" },
              _vm._l(_vm.slides, function(slide, i) {
                return _c("span", {
                  key: i,
                  staticClass: "swiper-pagination-bullet",
                  class: { active: i === _vm.activeSlide },
                  on: {
                    click: function($event) {
                      return _vm.updateActive(i)
                    }
                  }
                })
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }