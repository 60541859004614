var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "shipping-address-form checkout-step",
      class: _vm.stepState
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "SmoothReflow",
        {
          staticClass: "reflow",
          attrs: {
            options: {
              transitionEvent: {
                selector: ".reflow-element",
                propertyName: "opacity"
              }
            }
          }
        },
        [
          _c("transition-group", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.valid,
                    expression: "!valid"
                  }
                ],
                key: "inner",
                staticClass: "checkout-step__inner reflow-element"
              },
              [
                _c(
                  "FormulateForm",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.step === 2,
                        expression: "step === 2"
                      }
                    ],
                    staticClass: "checkout-step__form form pt-25",
                    attrs: { id: "shipping-address" },
                    on: { submit: _vm.handleSubmit },
                    model: {
                      value: _vm.values,
                      callback: function($$v) {
                        _vm.values = $$v
                      },
                      expression: "values"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form__hidden" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            type: "hidden",
                            name: "sameAddress",
                            value: "1"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form__inner" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col w-full md:w-6/12" },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                type: "text",
                                name: "fullName",
                                validation: "^required",
                                "validation-name": "This field is required",
                                "input-class": "form-field form-field--cart",
                                label: "Full Name",
                                value: _vm.shippingAddress
                                  ? _vm.shippingAddress.fullName
                                  : _vm.cart.shippingAddress
                                  ? _vm.cart.shippingAddress.fullName
                                  : ""
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col w-full md:w-6/12" },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                type: "text",
                                name: "fields[addressPhone]",
                                "input-class": "form-field form-field--cart",
                                label: "Phone Number",
                                value: _vm.shippingAddress
                                  ? _vm.shippingAddress.addressPhone
                                  : _vm.cart.shippingAddress
                                  ? _vm.cart.shippingAddress.addressPhone
                                  : ""
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col w-full md:w-6/12" },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                type: "text",
                                name: "organization",
                                "input-class": "form-field form-field--cart",
                                label: "Company",
                                value: _vm.shippingAddress
                                  ? _vm.shippingAddress.organization
                                  : _vm.cart.shippingAddress
                                  ? _vm.cart.shippingAddress.organization
                                  : ""
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form__row" },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              type: "text",
                              name: "addressLine1",
                              validation: "^required",
                              "validation-name": "This field is required",
                              "input-class": "form-field form-field--cart",
                              label: "Street Address",
                              value: _vm.shippingAddress
                                ? _vm.shippingAddress.addressLine1
                                : _vm.cart.shippingAddress
                                ? _vm.cart.shippingAddress.addressLine1
                                : ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form__row" },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              type: "text",
                              name: "addressLine2",
                              "input-class": "form-field form-field--cart",
                              label: "Address Line 2 (optional)",
                              value: _vm.shippingAddress
                                ? _vm.shippingAddress.addressLine2
                                : _vm.cart.shippingAddress
                                ? _vm.cart.shippingAddress.addressLine2
                                : ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form__row" },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              type: "text",
                              name: "locality",
                              validation: "^required",
                              "validation-name": "This field is required",
                              "input-class": "form-field form-field--cart",
                              label: "Suburb / City",
                              value: _vm.shippingAddress
                                ? _vm.shippingAddress.locality
                                : _vm.cart.shippingAddress
                                ? _vm.cart.shippingAddress.locality
                                : ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form__row" },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              type: "text",
                              name: "postalCode",
                              validation: "^required",
                              "validation-name": "This field is required",
                              "input-class": "form-field form-field--cart",
                              label: "Postcode",
                              value: _vm.shippingAddress
                                ? _vm.shippingAddress.postalCode
                                : _vm.cart.shippingAddress
                                ? _vm.cart.shippingAddress.postalCode
                                : ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form__row" }, [
                        _c("div", { staticClass: "form__submit-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn--primary mt-15",
                              class: { " loading ": _vm.loading },
                              attrs: { disabled: _vm.loading, type: "submit" }
                            },
                            [
                              _vm._v(
                                "\n                    Save & continue\n                  "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.valid,
                    expression: "valid"
                  }
                ],
                key: "summary",
                staticClass: "checkout-step__summary reflow-element mt-25"
              },
              [
                _c(
                  "div",
                  { staticClass: "address" },
                  _vm._l(Object.keys(_vm.shippingAddressSummary), function(
                    key,
                    index
                  ) {
                    return _c(
                      "p",
                      { key: "add-" + index, staticClass: "address__row" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.shippingAddressSummary[key]) +
                            "\n            "
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "underline",
                    attrs: { type: "button", value: "Edit" },
                    on: { click: _vm.stepBack }
                  },
                  [_vm._v("\n            Edit\n          ")]
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "checkout-step__header" }, [
      _c("h2", { staticClass: "checkout-step__title h5" }, [
        _vm._v("\n        2. Shipping Address\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }