<template>
  <div class="create-new-user">
    <SmoothReflow
      :options="{
        transitionEvent: {
          selector: '.reflow-element',
          propertyName: 'opacity',
        },
      }"
      class="reflow"
    >
      <transition-group name="fade">
        <div
          v-show="!valid && step == 1"
          key="form"
          class="email-step formulate-form"
        >
          <p class="mb-25">
            Already have an account?
            <a id="show-login" href="/login" class="underline">Login</a>
          </p>
          <FormulateForm
            id="new-user-form"
            v-model="values"
            classes="checkout-step__form form"
            @submit="handleSubmit"
          >
            <p class="mb-25">
              You must create an account with Parenting Place to start.
            </p>
            <div class="row ">
              <div class="col w-6/12">
                <FormulateInput
                  type="text"
                  name="firstName"
                  validation="^required"
                  validation-name="This field is required"
                  input-class="form-field"
                  label="First Name"
                />
              </div>
              <div class="col w-6/12">
                <FormulateInput
                  type="text"
                  name="lastName"
                  label="Last Name"
                  validation="^required"
                  validation-name="This field is required"
                  input-class="form-field"
                />
              </div>
            </div>
            <FormulateInput
              type="email"
              name="email"
              label="Email"
              validation="^required|email"
              input-class="form-field"
            />
            <FormulateInput
              type="password"
              name="password"
              label="Password"
              validation="^required|min:6,length"
              input-class="form-field"
            />
            <button
              class="btn btn--primary mt-25"
              :class="{ ' loading ': loading }"
              :disabled="loading"
              type="submit"
            >
              Save &amp; continue
            </button>
          </FormulateForm>
          <p
            v-for="(problem, key) in error"
            :key="`${problem}-${key}`"
            class="text-red pt-15"
          >
            <span v-if="key !== 'unverifiedEmail' && key !== 'username'">
              {{
                problem
                  .toString()
                  .replace('[', '')
                  .replace(']', '')
              }}
              <span v-if="key == 'email'"
                >If that's you,
                <a href="/login" class="underline"> login here </a></span
              >
              <br />
            </span>
          </p>
        </div>
        <div v-if="valid" key="summary" class="existing-user">
          <p class="body-copy">{{ values.email }}</p>
          <button
            class="underline"
            type="button"
            value="Edit"
            @click="stepBack"
          >
            Edit
          </button>
        </div>
      </transition-group>
    </SmoothReflow>
  </div>
</template>

<script>
import { getters, actions } from 'vstore/store';
import axios from 'axios';

export default {
  name: 'NewUser',
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['signUp', 'edit', 'next'],
  data() {
    return {
      showPassword: false,
      firstName: '',
      lastname: '',
      email: '',
      password: '',
      loading: false,
      values: {},
      valid: false,
      error: '',
    };
  },
  computed: {
    csrf() {
      return {
        token: getters.csrfToken(),
      };
    },
  },
  mounted() {
    const thisForm = document.getElementById('new-user-form');
    const inputs = thisForm.querySelectorAll('input');

    inputs.forEach((input) => {
      const parent = input.closest('.form-group');

      input.addEventListener('focus', () => {
        if (parent != null) {
          parent.classList.add('active');
        }
      });
      input.addEventListener('blur', () => {
        if (input.value === '' && parent != null) {
          parent.classList.remove('active');
        }
      });

      if (input.value !== '') {
        if (parent != null) {
          parent.classList.add('active');
        }
      }
    });
  },
  methods: {
    // submit this form and save new user
    handleSubmit() {
      this.loading = true;
      axios
        .get(`/actions/existinguser/existinguser?email=${this.values.email}`, {
          headers: {
            Accept: 'application/json',
            'CRAFT-CSRF-TOKEN': this.csrf.token,
          },
        })
        .then((response) => response.data)
        .then((data) => {
          if (data.errors) {
            this.error = data.errors;
          } else if (data.userExists) {
            this.error = {
              email: `Sorry but the email ${
                this.values.email
              } is already in use.`,
            };
          } else {
            this.valid = true;
            this.error = '';
            this.updateCartEmail();
            this.$emit('next');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    stepBack() {
      /// /
      // this is step 1
      this.$emit('edit', 1);
      this.valid = false;
    },
    updateCartEmail() {
      const data = new FormData();
      data.append('action', 'commerce/cart/update-cart');
      data.append('CRAFT_CSRF_TOKEN', this.csrf.token);
      data.append('email', this.values.email);

      axios
        .post('/', data, {
          headers: { Accept: 'application/json' },
        })
        .then(() => {
          if (data.errors) {
            this.error = data.errors;
          } else {
            this.valid = true;
            actions.fetchCart();
            this.$emit('signUp', { user: this.values });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
