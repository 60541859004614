<template>
<div id="cart-trigger">
	<button href="/account" class="mr-xs md:mr-xs nav__link" @click="openCart">
		Cart {{ lineItems.length ? `(${lineItems.length})` : '' }}
	</button>
</div>
</template>

<script>
import { mutations, getters, actions } from 'vstore/store';
import Backdrop from 'vcomponents/Backdrop';

export default {
	name: 'CartTrigger',
	components: {
		Backdrop,
	},
	props: ['check'],
	computed: {
		open() {
			return getters.cartOpen();
		},
		cart() {
			return getters.cart();
		},
		lineItems() {
			return getters.lineItems();
		},
	},

	watch: {
		open() {
			actions.fetchCart();
			actions.fetchLineItems();
		},
	},

	methods: {
		openCart() {
			mutations.setCartOpen(true);
		},
	},
};
</script>
