<template>
<div class="hero-slider-wrap relative" :class="bg" >
<Swiper ref="swiper" type="carousel" :options="swiperOptions" id="hero-slider" @slide-change-transition-start="updateActive" >
	<SwiperSlide v-for="(slide, i) in slides" :key="i" >
		<section
			class="overflow-hidden relative flex flex-wrap h-full "
			:class="[bg, orientationClass]"
		>
			<div
				class="col w-full sm:w-6/12 hero-slider__text-wrap"
			>
				<div class="text-container max-w-500 xl:max-w-650 w-full">
					<p
						v-if="slide.subheading"
						class="text-red uppercase mb-25 tracking-small text-small"
					>
						{{ slide.subheading }}
					</p>

					<h1 class="text-purple-dark mb-40 h3 xl:h1  md:pr-lg">
						{{ slide.heading }}
					</h1>

					<div
						v-if="slide.text"
						class="max-w-350 mb-40"
						v-html="marked(slide.text)"
					/>

					<div
						v-if="slide.buttons.length"
						class="button-container flex flex-wrap md:flex-no-wrap mb-sm md:mb-0"
					>
						<a
							v-for="(button, i) in slide.buttons"
							:key="i"
							:href="button.url"
							class="btn mb-sm"
							:class="btnClass(i)"
							data-gallery="hero-panel"
						>
							{{ button.text }}
						</a>
					</div>
				</div>
			</div>

			<div
				class="w-mobile-edge w-full sm:w-1/2 relative sm:absolute sm:h-full top-0 overflow-hidden sm:-mx-15 md:mx-0 flex flex-wrap"
				:class="[orientation === 'right' ? 'left-0' : 'right-0']"
			>
				<img
					v-if="slide.image"
					:src="slide.image.url"
					:alt="slide.image.title"
					class="w-fitFix h-fitFix object-cover object-center md:mb-0 sm:absolute top-0 left-0"
				>
			</div>
		</section>
	</SwiperSlide>
</Swiper>
	<div class="col w-full sm:w-6/12 flex items-center text-left justify-center absolute top-30 sm:top-unset sm:bottom-30 left-0 z-10">
		<div class=" max-w-500 xl:max-w-650 w-full">
			<div class="swiper-pagination">
				<span v-for="(slide, i) in slides" :key="i" class="swiper-pagination-bullet" :class="{'active': i === activeSlide}" @click="updateActive(i)" />
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { Swiper as SwiperClass, Autoplay, EffectFade, Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import { actions } from 'vstore/store';

SwiperClass.use([Autoplay, EffectFade, Pagination]);

export default {
	name: 'HeroSlider',
	components: {
		Swiper,
		SwiperSlide,
	},
	props: {
		slides: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			activeSlide: 0,
			orientation: 'left',
			bg: 'bg-bg',
			swiper: undefined,
			swiperOptions: {
				effect: 'fade',
				fadeEffect: { crossFade: true },
				centeredSlides: true,
				allowTouchMove: false,
				loop: false,
				loopedSlides: this.slides.length,
				slidesPerView: 1,
				spaceBetween: 0,
				autoplay:{
					delay: 8000
				}
			},
		};
	},
	computed: {
		orientationClass() {
			if (this.orientation === 'right') {
				return 'justify-start sm:justify-end';
			}
			return "justify-start";
		},
	},
	mounted(){
			this.swiper = this.$refs.swiper.swiperInstance;
	},
	methods: {
		marked(val, options) {
			return actions.marked(val, options);
		},
		btnClass(i) {
			if (i === 0) {
				return 'btn--primary mr-xs';
			}
			return 'btn--outline-red';
		},
		test(e){
			console.log(e)
		},
		updateActive(i){
			if(typeof i != Object){
				console.log(i)
				this.swiper.slideTo(i);
			}
			this.activeSlide = this.$refs.swiper.swiperInstance.activeIndex;
		},
	},
};
</script>

<style lang="scss">
.hero-slider-wrap{
	.swiper-slide{
		height: auto;
	}
	.swiper-pagination{
		width:auto;
	}
	.swiper-pagination-bullet{
		@apply bg-transparent border-1 border-red rounded-full cursor-pointer;
		opacity: 1;
		width:11px;
		height: 11px;
		margin-right: 5px;
		&.active{
			@apply bg-red;
		}
	} 
}
.hero-slider__text-wrap{
	@apply flex flex-wrap flex-col items-center text-left justify-center py-rg;

	@screen sm{
		@apply flex-row;
	}
	@screen md{
		@apply min-h-screen-w-nav py-lg;
	}
}
</style>