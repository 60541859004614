<template>
<div id="FooterSponsors">
	<swiper
		ref="swiper"
		type="carousel"
		:options="swiperOptions"
	>
		<swiper-slide
			v-for="(slide, i) in slidesFormatted"
			:key="i"
			:class="slideClass"
		>
			<img :src="slide.src" :alt="slide.title">
		</swiper-slide>
	</swiper>
</div>
</template>

<script>

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { Swiper as SwiperClass, Autoplay } from 'swiper/core';

// import 'vue-glide-js/dist/vue-glide.css';
SwiperClass.use([Autoplay]);

export default {
	name: 'FooterSponsors',
	components: {
		// [Glide.name]: Glide,
		// [GlideSlide.name]: GlideSlide,
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	props: {
		slides: {
			type: String,
			required: true,
		},
	},
	emits: [],
	data() {
		return {
			windowWidth: '',
			activeSlide: '',

			swiperOptions: {
				centeredSlides: true,
				loop: true,
				loopedSlides: this.slides.length,
				slidesPerView: 1,
				spaceBetween: 0,
				autoplay: {
					delay: 2000,
				},
			},
		};
	},
	computed: {
	// parsedOptions() {
	//	return JSON.parse(this.glideOptions);
	// },

		// breakpoints() {
		// 	return this.parsedOptions.breakpoints;
		// },
		slidesFormatted() {
			return JSON.parse(this.slides);
		},
		isSlideGroup() {
			return this.slidesFormatted[0] instanceof Array;
		},
		slideClass() {
			if (this.windowWidth >= 998) {
				return 'flex flex-nowrap';
			}
			return '';
		},
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.getWindowWidth);
			// window.addEventListener('resize', this.getWindowHeight);

			// Init
			this.getWindowWidth();
			// this.getWindowHeight();
		});
	},

	methods: {
		getWindowWidth() {
			this.windowWidth = window.innerWidth;
		},

	},
};
</script>
