var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlideOut",
    {
      attrs: { open: _vm.slideOutOpen, "wrap-class": "bg-bodyColor" },
      on: {
        close: function($event) {
          _vm.slideOutOpen = false
        }
      }
    },
    [
      _c("div", { staticClass: "max-w-500" }, [
        _c("h2", { staticClass: "mb-30" }, [_vm._v("How it works")]),
        _vm._v(" "),
        _vm.text.length
          ? _c("div", {
              staticClass: "markdown markdown--reduced-spacing",
              domProps: { innerHTML: _vm._s(_vm.marked(_vm.text)) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bg-white shadow-standard mt-40 p-sm md:p-40" },
          _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "flex",
                class: { "mb-40": index < _vm.list.length - 1 }
              },
              [
                _c("span", { staticClass: "h2 pr-30 xs:min-w-100" }, [
                  _vm._v(" " + _vm._s(_vm.getNumber(index)) + ". ")
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "pr-sm markdown markdown--no-spacing",
                  domProps: { innerHTML: _vm._s(_vm.marked(item.listItem)) }
                })
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }