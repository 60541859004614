var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    { attrs: { open: _vm.open }, on: { close: _vm.handleClose } },
    [
      _c("h1", { staticClass: "text-mob-h3 md:text-h2 mb-sm" }, [
        _vm._v(
          "\n\t\t" + _vm._s(_vm.childExists ? "Update" : "Add a child") + "\n\t"
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "body-text mb-md text-grey-500" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(_vm.childExists ? "Update your" : "Add a ") +
            " child to receive personalised\n\t\tcontent recommendations\n\t"
        )
      ]),
      _vm._v(" "),
      _c(
        "FormulateForm",
        {
          ref: "signupForm",
          on: {
            submit: function($event) {
              return _vm.saveChild()
            }
          }
        },
        [
          _vm._l(_vm.children, function(child, index) {
            return _c(
              "div",
              {
                key: child.name.replace(" ", "-") + "-" + index,
                attrs: { id: child.name.replace(" ", "-") + "-" + index }
              },
              [
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "fields[userChildren][" + index + "][type]",
                    value: "child"
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "fields[userChildren][" + index + "][enabled]",
                    value: "1"
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name:
                      "fields[userChildren][" + index + "][fields][childName]"
                  },
                  domProps: { value: child.name }
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name:
                      "fields[userChildren][" + index + "][fields][dateOfBirth]"
                  },
                  domProps: { value: child.dateOfBirth.date }
                }),
                _vm._v(" "),
                index !== _vm.activeId
                  ? _c(
                      "div",
                      _vm._l(child.childTags, function(tag) {
                        return _c("input", {
                          key: tag.id + "-" + tag.handle,
                          attrs: {
                            type: "hidden",
                            name:
                              "fields[userChildren][" +
                              index +
                              "][fields][childTags][]"
                          },
                          domProps: { value: tag.id }
                        })
                      }),
                      0
                    )
                  : _vm._e()
              ]
            )
          }),
          _vm._v(" "),
          !_vm.remove
            ? _c("input", {
                attrs: {
                  type: "hidden",
                  name: _vm.newFieldNamePrefix + "[enabled]",
                  value: "1"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: _vm.newFieldNamePrefix + "[type]" },
            domProps: { value: _vm.remove ? "null" : "child" }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "step-1" },
            [
              _c("FormulateInput", {
                attrs: {
                  type: "text",
                  name: _vm.newFieldNamePrefix + "[fields][childName]",
                  label: "Name",
                  validation: "required",
                  "validation-name": "Name"
                },
                model: {
                  value: _vm.childName,
                  callback: function($$v) {
                    _vm.childName = $$v
                  },
                  expression: "childName"
                }
              }),
              _vm._v(" "),
              _c("FormulateInput", {
                attrs: {
                  type: "date",
                  name: _vm.newFieldNamePrefix + "[fields][dateOfBirth]",
                  label: "Date of Birth",
                  "label-class": "label--small",
                  "outer-class": "form-group--styled mt-sm",
                  validation: "required",
                  "validation-name": "Date of Birth"
                },
                model: {
                  value: _vm.childAge,
                  callback: function($$v) {
                    _vm.childAge = $$v
                  },
                  expression: "childAge"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "step-2 " },
            _vm._l(_vm.categories, function(cat) {
              return _c(
                "button",
                {
                  key: cat.id,
                  staticClass: "btn btn--pill mt-15 mx-5",
                  class: { active: _vm.childTags.includes(cat.id) },
                  attrs: { role: "button", type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.toggleTag(cat.id)
                    }
                  }
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(cat.title) + "\n\t\t\t")]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn--outline mt-md",
              class: { loading: _vm.loading },
              attrs: { type: "submit" }
            },
            [
              _vm._v(
                "\n\t\t\t" + _vm._s(_vm.remove ? "Remove" : "Save") + "\n\t\t"
              )
            ]
          ),
          _vm._v(" "),
          _vm.childExists
            ? _c(
                "button",
                {
                  staticClass: "btn btn--outline mt-md",
                  class: { loading: _vm.loading && _vm.remove },
                  attrs: { type: "button", disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.removeChild(_vm.editableIndex)
                    }
                  }
                },
                [_vm._v("\n\t\t\tRemove\n\t\t")]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }