import '../scss/tailwind/tailwind-base.scss';
import '../scss/custom.scss';
import '../scss/tailwind/tailwind-utilities.scss';

import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';

import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';

import GLightbox from 'glightbox';

import lazySizes from 'lazysizes';
// other imports ...
import { actions, mutations } from 'vstore/store';

import { stickyNav } from 'modules/nav/sticky-nav';
import { navTrigger } from 'modules/nav/navTrigger';
import MainNavDropdowns from 'modules/nav/navDropdowns';
import labelSlide from 'modules/label-slide';
import SlideoutTriggers from 'modules/slideoutTriggers';
import AddStripeCard from 'modules/addStripeCard';
import CourseHeaderOffset from 'modules/courseHeaderOffset';
import SearchBar from 'modules/searchBarReveal';
import { notificationCookie } from 'modules/notificationCookie';

import axios from 'axios';
import SmoothReflow from 'vcomponents/SmoothReflow';
import ContentFilter from 'vcomponents/plainFilter';
import { videoPlaceholder } from 'modules/videoPlaceholder';
// Vue
import AcuityAppointments from 'vcomponents/AcuityAppointments';
import AddToCartCourse from 'vmodules/AddToCartCourse';
import AddToCartEvent from 'vmodules/AddToCartEvent';
import AddToCartSimple from 'vmodules/AddToCartSimple';
import CardCarousel from 'vmodules/CardCarousel';
import Cart from 'vmodules/Cart';
import CartTrigger from 'vmodules/CartTrigger';
import Checkout from 'vmodules/Checkout';
import ChildModalWrap from 'vmodules/ChildModalWrap';
import CourseVenueSelector from 'vmodules/CourseVenueSelector';
import CraftSiteSearch from 'vmodules/CraftSiteSearch';
import DonationForm from 'vcomponents/DonationForm';
import HeroSlider from 'vmodules/HeroSlider';
import HowItWorks from 'vmodules/HowItWorks';
import HubspotNewsletterSignup from 'vforms/HubspotNewsletterSignup';
import FooterSponsors from 'vmodules/FooterSponsors';
import MyFamily from 'vmodules/MyFamily';
import MyFamilyMenu from 'vmodules/MyFamilyMenu';
import NewsletterSlideout from 'vmodules/NewsletterSlideout';
import RelatedCarousel from 'vmodules/RelatedCardCarousel';
import ResourceFilter from 'vmodules/ResourceFilter';
import StoreIndex from 'vmodules/StoreIndex';
import TeamFilter from 'vmodules/TeamFilter';
import TestimonialsSlider from 'vmodules/TestimonialsSlider';
import { resumeScroll } from 'utils/scrollControl';

const lazySizesConfig = lazySizes.cfg;
lazySizesConfig.init = false;
lazySizesConfig.throttleDelay = 250;
lazySizesConfig.loadMode = 3;

window.addEventListener('load', lazySizes.init);

window.swup = new Swup({
  linkSelector:
    'a:not([data-no-swup]):not([target="_blank"]):not([href*="mailto:"]):not([href*="tel:"]):not([class*="glightbox"]):not([href*="addevent"]):not([href*="bamboohr"])',
  animationSelector: '[class*="swup-transition-"]',
  animateHistoryBrowsing: true,
  plugins: [
    new SwupScrollPlugin({
      doScrollingRightAway: false,
      animateScroll: true,
    }),
    new SwupScriptsPlugin({
      optin: true,
    }),
  ],
});

window.swup.cache.empty();
//For bamboo hr, get data
const getVacancies = () => {
  const bambooHR = document.querySelector('#BambooHR');
  if (bambooHR) {
    axios
      .get('https://parentingplace.bamboohr.com/jobs/embed2.php?version=2')
      .then((response) => {
        document.querySelector('#BambooHR').innerHTML = response.data.replace(
          /<style.*?<\/style>/g,
          ''
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }
};
// initialise lazy sizes on load
// open external links in new tab
function setExternalLinks() {
  document
    .querySelectorAll(
      'a:not([data-no-swup]):not([target="_blank"]):not([class*="glightbox"]):not([href*="addevent"]):not([class^="#"])'
    )
    .forEach((link) => {
      const href = link.getAttribute('href');
      const containsHost = href.search(`${window.location.hostname}`);
      const NotLocal = href.charAt(0) !== '/' && href.charAt(0) !== '#';
      const PdfLink = href.includes('.pdf');
      if (PdfLink) {
        console.log(link);
      }
      if ((containsHost === -1 && NotLocal) || PdfLink) {
        link.setAttribute('target', '_blank');
      }
    });
}

const main = () => {
  // run if the user passes the captcha
  window.recaptcha_success = function(response) {
    mutations.setGScore(response.score);
  };
  // run if the user fails the captcha
  window.recaptcha_failure = function(response) {
    mutations.setGScore(response.score);
  };
  videoPlaceholder.init();
  labelSlide.init();
  stickyNav.init();
  notificationCookie.init();
  navTrigger.init();
  CourseHeaderOffset.init();
  SlideoutTriggers.init();
  SearchBar.init();

  const navDropdowns = new MainNavDropdowns();

  if (document.querySelector('.add-card-form')) {
    const addStripeCardfrom = new AddStripeCard();
  }

  const lightbox = GLightbox({
    selector: '.glightbox',
    autoplayVideos: true,
    width: 'auto',
    openEffect: 'gfade',
    closeEffect: 'gfade',
    cssEfects: {
      gfade: { in: 'gfadeIn', out: 'gfadeOut' },
    },
    plyr: {
      css: 'https://cdn.plyr.io/3.6.4/plyr.css', // default value
      js: 'https://plyr-js.netlify.app/plyr.polyfilled.min.js', // fix
      config: {
        ratio: '16:9', // or '4:3'
        muted: false,
        hideControls: true,
        youtube: {
          noCookie: true,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
        },
        vimeo: {
          byline: false,
          portrait: false,
          title: false,
          speed: true,
          transparent: false,
        },
      },
    },
  });
};

const initVue = () => {
  Vue.use(VueFormulate, {
    classes: {
      outer: (context, classes) =>
        classes.concat([
          'form-group form-group--formulate',
          context.type !== 'radio' && context.type !== 'checkbox'
            ? 'form-group--styled'
            : `form-group--${context.type}`,
        ]),
    },
  });

  Vue.component('SmoothReflow', SmoothReflow);

  class InitVueComponent {
    constructor(selector, component) {
      this.selector = selector;
      this.components = component;
      this.instance = undefined;
      this.initialize();
    }

    initialize() {
      const component = this.components;
      const vueEl = document.querySelector(this.selector);
      if (vueEl) {
        this.instance = new Vue({
          el: this.selector,
          components: component,
          mounted() {
            actions.csrfSet();
            main();
            getVacancies();
            // injectScripts();
            lazySizes.init();
            window.swup.on('willReplaceContent', () => {
              this.$destroy();
            });
            setExternalLinks();
          },
        });
      }
    }
  }

  const VueContainer = new InitVueComponent('#vue-container', {
    AcuityAppointments,
    AddToCartCourse,
    AddToCartEvent,
    AddToCartSimple,
    CardCarousel,
    Cart,
    CartTrigger,
    Checkout,
    ChildModalWrap,
    CourseVenueSelector,
    CraftSiteSearch,
    ContentFilter,
    DonationForm,
    FooterSponsors,
    HeroSlider,
    HowItWorks,
    HubspotNewsletterSignup,
    MyFamily,
    MyFamilyMenu,
    NewsletterSlideout,
    RelatedCarousel,
    ResourceFilter,
    StoreIndex,
    TeamFilter,
    TestimonialsSlider,
  });
};

initVue();
window.swup.on('animationOutStart', () => {
  resumeScroll();
});
window.swup.on('animationInStart', () => {
  lazySizes.init();
  initVue();
});
