<template>
  <div class="hubspot-form w-full">
    <SmoothReflow class="reflow w-full">
      <transition-group name="fade" class="relative w-full flex justify-center">
        <FormulateForm v-show="!success" key="form" ref="form" class="w-full md:w-7/12 flex justify-center flex-wrap"
          method="post" @submit="handleSubmit">
          <input type="hidden" name="action" value="donation/hubspot/add-contact-campaign" />
          <input type="hidden" name="campaign" :value="campaignTag" />
          <div class="row">
            <div class="col w-full md:w-1/2">
              <FormulateInput type="text" name="firstname" label="First Name"
                wrapper-class="formulate-input-wrapper form-group--white" validation="required" />
            </div>
            <div class="col w-full md:w-1/2">
              <FormulateInput type="text" name="Last Name" label="Last Name"
                wrapper-class="formulate-input-wrapper form-group--white" validation="required" />
            </div>
            <div class="col w-full md:w-1/2">
              <FormulateInput type="text" name="phone" label="Phone"
                wrapper-class="formulate-input-wrapper form-group--white" />
            </div>
            <div class="col w-full md:w-1/2">
              <FormulateInput name="email" type="email" label="Email" validation="required|email"
                validation-name="Email" />
            </div>
          </div>

          <button class="btn btn--primary mt-sm" :class="{ loading: loading }" :disabled="loading">
            {{ buttonText }}
          </button>
        </FormulateForm>
        <div v-show="success" key="success" class="success tex-white">
          {{ successMessage }}
        </div>
      </transition-group>
    </SmoothReflow>
    <SmoothReflow>
      <div v-if="error !== ''" ref="error-container" class="error pt-25 reflow-element text-center">
        <p class="body-copy text-red">{{ error }}</p>
      </div>
    </SmoothReflow>
  </div>
</template>
<script>
import axios from 'axios';
import setLabelPositions from 'utils/setLabelPosition';

export default {
  name: 'HubspotNewsletter',
  props: {
    buttonText: {
      type: String,
      default: 'Sign Up',
    },
    campaignTag: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      default: 'Thank you for signing up.',
    },
  },
  emits: ['success'],
  data() {
    return {
      success: false,
      loading: false,
      error: '',
    };
  },
  mounted() {
    console.log(this.$refs);
    setLabelPositions(this.$el);
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      const data = new FormData(this.$refs.form.$el);
      data.append(window.csrfName, window.csrfToken);
      axios
        .post('hubspot/add-contact-campaign', data)
        .then((response) => {
          this.loading = false;
          const respData = response.data;
          if (respData.errors) {
            this.error = respData.errors;
          } else {
            this.success = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
        });
    },
  },
};
</script>
