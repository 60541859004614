<template>
<section class="w-full">
	<button
		class="body-text block mb-xs hover:text-red transition-color duration-200 "
		:class="{ 'text-red': visible }"
		@click="handleLink"
	>
		My Family
	</button>
	<SmoothReflow>
		<div v-show="visible" class="mb-sm">
			<ul class="w-full mb-xs">
				<li
					v-for="(child, index) in children"
					:key="`${child.name.replace(' ', '-')}-${index}`"
					class="w-full ml-15 py-5 pr-sm flex justify-between cursor-pointer"
					:class="{ 'text-red': isChild(child) }"
				>
					<span @click="updateSelectedChild(child)">
						{{ child.name }}
					</span>
					<button @click="openEditChild(index)">
						(Edit)
					</button>
				</li>
			</ul>
			<button class="px-xs" @click="openAddChild">
				<span>+</span>
				Add
				{{
					children !== undefined && children.length > 0 ? 'another' : 'a'
				}}
				child
			</button>
		</div>
	</SmoothReflow>
</section>
</template>

<script>
import { actions, mutations, getters } from 'vstore/store';
import { stopScroll, resumeScroll } from 'utils/scrollControl.js';

export default {
	name: 'MyFamilyMenu',

	props: {
		categories: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			loading: true,
		};
	},

	computed: {
		activeChild() {
			return getters.activeChild();
		},
		children() {
			return getters.childrenData();
		},
		visible() {
			return window.location.href.includes('my-family');
		},
	},

	mounted() {
		actions.fetchChildrenData().then(() => {
			this.updateSelectedChild(this.children[0]);
		});
	},
	methods: {
		handleClose() {
			this.open = false;
			mutations.childModalOpen(false);
			resumeScroll();
		},
		handleLink(event) {
			event.preventDefault();
			if (!window.location.href.includes('my-family')) {
				swup.loadPage({ url: '/account/my-family' });
			}
		},
		isChild(child) {
			return this.activeChild === child;
		},
		openAddChild() {
			if (window.scrollY !== 0) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setTimeout(() => {
					mutations.setActiveChildId(this.children.length + 1);
					mutations.setChildModalOpen(true);
					stopScroll();
				}, 500);
			} else {
				mutations.setActiveChildId(this.children.length + 1);
				mutations.setChildModalOpen(true);
				stopScroll();
			}
		},
		openEditChild(id) {
			if (window.scrollY !== 0) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setTimeout(() => {
					mutations.setActiveChildId(id);
					mutations.setChildModalOpen(true);
					stopScroll();
				}, 500);
			} else {
				mutations.setActiveChildId(id);
				mutations.setChildModalOpen(true);
				stopScroll();
			}
		},

		updateSelectedChild(child) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			mutations.setActiveChild(child);
		},
	},
};
</script>
