var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "product-checkout" } },
    [
      _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          {
            staticClass:
              "col w-full md:w-6/12 lg:w-5/12 xl:w-4/12 mb-md md:mb-0 md:order-2"
          },
          [_c("v-order-summary")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col w-full md:w-6/12 xl:w-5/12 md:order-1" },
          [
            _c("div", { staticClass: "w-full lg:w-11/12" }, [
              _c(
                "div",
                { staticClass: "checkout-step", attrs: { id: "email-step" } },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "checkout-step__inner pb-25" },
                    [
                      _vm.isUser
                        ? _c("div", { staticClass: "existing-user" }, [
                            _c("p", { staticClass: "body-copy" }, [
                              _vm._v(_vm._s(_vm.user.email))
                            ])
                          ])
                        : _c("v-checkout-new-user", {
                            attrs: { step: _vm.step, cra: "" },
                            on: {
                              edit: _vm.setStep,
                              next: _vm.nextStep,
                              signUp: _vm.updateUser
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isUser
                    ? _c("p", { staticClass: "body-text" }, [
                        _vm._v("\n            Not you? "),
                        _c(
                          "a",
                          {
                            staticClass: "underline",
                            attrs: { href: "/logout" }
                          },
                          [_vm._v(" Logout ")]
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { id: "address-step" } },
                [
                  _c("v-address", {
                    attrs: {
                      customer: _vm.customer,
                      step: _vm.step,
                      cart: _vm.cart,
                      "primary-shipping-address": _vm.primaryShipping
                    },
                    on: { updated: _vm.nextStep, edit: _vm.setStep }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { id: "shipping-step" } },
                [
                  _c("v-shipping-method", {
                    attrs: {
                      step: _vm.step,
                      "updated-shipping": _vm.updatedShippingAddress
                    },
                    on: {
                      updated: _vm.nextStep,
                      edit: _vm.setStep,
                      shipping: _vm.setShipping
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { id: "payment-step" } },
                [
                  _c("v-payment", {
                    attrs: {
                      "new-user": _vm.newUser,
                      "redirect-url": _vm.redirectUrl,
                      "cancel-url": _vm.cancelUrl,
                      customer: _vm.customer,
                      gateway: _vm.gateway,
                      "stripe-pk": _vm.stripePk,
                      step: _vm.step,
                      shipping: _vm.updatedShippingAddress,
                      "default-payment-option": _vm.defaultPaymentSource,
                      "payment-namespace": _vm.paymentNamespace
                    },
                    on: { updated: _vm.nextStep, edit: _vm.setStep },
                    model: {
                      value: _vm.updatedCart,
                      callback: function($$v) {
                        _vm.updatedCart = $$v
                      },
                      expression: "updatedCart"
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "checkout-step__header" }, [
      _c("h2", { staticClass: "checkout-step__title h5 mb-25 " }, [
        _vm._v("1. Your Details")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }