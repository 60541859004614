var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "w-full" }, [
    _c(
      "div",
      { staticClass: "shadow-standard px-30 py-40" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col w-full" }, [
            _c("h2", { staticClass: "h3 mb-30" }, [
              _vm._v(
                "\n\t\t\t\t\tRecommendations for\n\t\t\t\t\t" +
                  _vm._s(_vm.activeChild ? _vm.activeChild.name : "...") +
                  "\n\t\t\t\t"
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("SmoothReflow", [
          _vm.recommendedArticles.length
            ? _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.recommendedArticles, function(article, index) {
                  return _c(
                    "div",
                    {
                      key: article.id + "-" + index,
                      staticClass: "col w-full md:w-1/2 mb-30 fade-in"
                    },
                    [_c("card", { attrs: { content: article } })],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        (!_vm.recommendedArticles.length && !_vm.loading) || !_vm.children
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col w-full " }, [
                _vm.children && _vm.children.length > 0
                  ? _c("h4", { staticClass: "h5 fade-in" }, [
                      _vm._v(
                        "\n\t\t\t\t\tAdd some tags to\n\t\t\t\t\t" +
                          _vm._s(_vm.activeChild ? _vm.activeChild.name : "") +
                          " so we can give\n\t\t\t\t\tyou some recommendations!\n\t\t\t\t"
                      )
                    ])
                  : _c("h4", { staticClass: "h5 fade-in" }, [
                      _vm._v(
                        "Tell us about your child so we can reccomend you some helpful content"
                      )
                    ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.recommendedArticles.length &&
        _vm.recommendedArticles.length < _vm.entryCount
          ? _c("div", { staticClass: "row justify-center" }, [
              _c(
                "button",
                {
                  staticClass: "mt-40 btn btn--primary",
                  on: { click: _vm.getLatestReccomendations }
                },
                [_vm._v("View More")]
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }