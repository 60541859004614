import { slideDown, slideUp } from 'utils/slide';

export default class MainNavDropdowns {
	constructor() {
		this.links = document.querySelectorAll('.nav__dropdown-toggle');

		this.links.forEach((item) => {
			MainNavDropdowns.bindEvents(item);
		});
	}

	static closeDropdown(target, item) {
		item.setAttribute('aria-expanded', 'false');
		slideUp(target, { duration: 250 });
		target.classList.remove('open');
	}

	static openDropdown(target, item) {
		if (window.innerWidth >= 998) {
			if (item.getAttribute('aria-expanded') === 'false') {
				item.setAttribute('aria-expanded', 'true');

				slideDown(target, { duration: 250 });
				document.querySelector('header').addEventListener(
					'mouseleave', () => {
						MainNavDropdowns.closeDropdown(target, item);
					},
					{ once: true },
				);
				target.classList.add('open');
			}

			target.querySelectorAll('.dropown__link').forEach((link) => {
				link.addEventListener('mouseenter', () => {
					MainNavDropdowns.handleHover(link);
				});
				link.addEventListener('mouseleave', () => {
					MainNavDropdowns.handleMouseOut(link);
				});
			});
		}
	}

	static openMobile(target, item) {
		if (item.getAttribute('aria-expanded') === 'false') {
			item.setAttribute('aria-expanded', 'true');
			if (window.innerWidth <= 997) {
				slideDown(target, { duration: 250 });
				target.classList.add('open');
			}
			document.addEventListener('click', () => {
				MainNavDropdowns.closeDropdown(target, item);
			}, { once: true });
		}
	}

	static handleHover(link) {
		const target = link.getAttribute('data-link');
		const message = document.getElementById(target);
		message.classList.remove('opacity-0');
		message.classList.add('opacity-100');
	}

	static handleMouseOut(link) {
		const target = link.getAttribute('data-link');
		const message = document.getElementById(target);
		message.classList.add('opacity-0');
		message.classList.remove('opacity-100');
	}

	static bindEvents(item) {
		const target = document.querySelector(item.getAttribute('data-target'));
		const mobileDropdown = item.parentNode.querySelector('.dropdown--mobile');
		item.addEventListener('mouseenter', () => {
			MainNavDropdowns.openDropdown(target, item);
		});
		item.addEventListener('click', (event) => {
			event.preventDefault();
			event.stopPropagation();
			console.log(mobileDropdown);
			MainNavDropdowns.openMobile(mobileDropdown, item);
		});
	}
}
