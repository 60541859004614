<template>
  <section>
    <transition name="fade">
      <table v-if="appointments.length > 0" class="w-full table--orders">
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Start</th>
            <th>End</th>
            <th>Canceled</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="appointment in appointments"
            :key="appointment.id"
            class=""
          >
            <td>
              <a
                :href="appointment.confirmationPage"
                class="underline"
                target="_blank"
                >{{ appointment.date }}</a
              >
            </td>
            <td>{{ appointment.type }}</td>
            <td>{{ appointment.time }}</td>
            <td>{{ appointment.endTime }}</td>
            <td>{{ appointment.canceled ? 'Yes' : 'No' }}</td>
          </tr>
        </tbody>
      </table>
    </transition>
    <transition name="fade">
      <template v-if="loading">
        <div class="lds-ripple">
          <div />
          <div />
        </div>
      </template>
    </transition>
    <transition name="fade">
      <template v-if="(appointments.length = 0 && !loading)">
        <h3 class="account-section__title h6">
          You don't have any Coaching Sessions
        </h3>
        <div class="account-section__inner pt-15 pb-md">
          <a href="/coaching" class="btn btn--primary mt-25">Book now</a>
        </div>
      </template>
    </transition>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AcuityAppointments',

  props: {
    email: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      appointments: [],
    };
  },

  created() {
    this.getAppointments();
  },

  methods: {
    getAppointments() {
      axios
        .get(`/actions/acuity/acuity/get-appointements?email=${this.email}`)
        .then((response) => {
          // console.log(response);
          this.appointments = JSON.parse(JSON.stringify(response.data));
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
  },
};
</script>
