<template>
<div id="course-venue-selector">
	<div
		v-if="collapse"
		class="select flex flex-wrap items-center font-diatype text-grey-500 uppercase text-small mb-sm"
	>
		<label class="font-diatype text-grey-500 uppercase text-small">
			Location:
		</label>
		<Dropdown
			:value="activeVenue"
			placeholder="1"
			:options="dropdownOptions"
			@selected="updateActiveVenue"
		/>
	</div>
	<SmoothReflow>
		<slot />
	</SmoothReflow>
</div>
</template>

<script>
import { getters, mutations } from 'vstore/store';
import { getSiblings } from 'utils/utilities';
import Dropdown from 'vcomponents/Dropdown';

export default {
	name: 'CourseVenueSelector',
	components: {
		Dropdown,
	},
	data() {
		return {
			courseElements: [],
		};
	},
	computed: {
		activeVenue() {
			return getters.selectedLocation();
		},
		numberOfCourses() {
			return this.courseElements.length;
		},
		collapse() {
			if (this.numberOfCourses > 1) {
				return true;
			}
			return false;
		},
		dropdownOptions() {
			const titleArray = [];
			this.courseElements.forEach((venue) => {
				const handle = venue.getAttribute('id');
				const title = venue.querySelector('.course-venue__title').innerHTML;
				titleArray.push({ handle, title });
			});
			return titleArray;
		},
	},
	mounted() {
		this.courseElements = this.$el.querySelectorAll('.course-venue');
		this.updateActiveVenue(this.dropdownOptions[0]);
	},
	methods: {
		updateActiveVenue(newValue) {
			mutations.setSelectedLocation(newValue.handle);

			const activeVenueEl = document.getElementById(newValue.handle);

			getSiblings(activeVenueEl).forEach((sibling) => {
				sibling.classList.add('fade-out');
				setTimeout(() => {
					sibling.classList.add('hidden');
					activeVenueEl.classList.remove('fade-out');
					activeVenueEl.classList.add('fade-in');
					activeVenueEl.classList.remove('hidden');
				}, 200);
			});
		},
	},
};
</script>
