<template>
<div class="order-summary md:sticky md:top-80">
	<div class="order-summary__section">
		<h2 class="order-summary__title h5 mb-15">Order Summary</h2>
	</div>
	<transition name="fade">
		<div v-if="lineItems && lineItems.length">
			<div class="w-full border-t-1">
				<div
					v-for="(item, index) in lineItems"
					:key="`item-${index}`"
					class="flex flex-no-wrap py-xs mb-15 border-b-1 relative"
				>
					<div class="w-3/12 ">
						<img :src="item.image">
					</div>
					<div class="w-5/12 flex flex-col justify-between text-left">
						<p class="body-copy w-10/12">
							{{ item.title }}
						</p>
						<!-- <button class="underline body-text text-left text-sm" @click="removeLineItem(item.id, item.qty, item.type)">Remove</button> -->
					</div>
					<div class="w-2/12 text-right">
						<p class="body-copy">Qty: {{ item.qty }}</p>
					</div>
					<div class="w-2/12">
						<p class="body-copy text-right">
							{{ item.salePrice ? item.salePrice : item.variantPrice }}
						</p>
					</div>
				</div>
			</div>
			<div class="order-summary__section">
				<div class="flex flex-wrap py-4">
					<div class="offset-3/12 w-9/12 flex justify-between mb-xs">
						<p class="body-copy">Subtotal:</p>
						<p class="body-copy">{{ formatMoney(cart.itemSubtotal) }}</p>
					</div>
					<div
						v-if="cart.storedTotalDiscount != 0 || cart.totalDiscount != 0"
						class="offset-3/12 w-9/12 flex justify-between mb-xs"
					>
						<p class="body-copy">Discount:</p>
						<p class="body-copy">{{ cart.totalDiscountAsCurrency }}</p>
					</div>

					<div
						v-if="cart.totalSaleAmount"
						class="offset-3/12 w-9/12 flex justify-between mb-xs"
					>
						<p class="body-copy">Sale (incl):</p>
						<p class="body-copy">- {{ formatMoney(cart.totalSaleAmount) }}</p>
					</div>

					<div
						v-if="cart.totalShippingCost"
						class="offset-3/12 w-9/12 flex justify-between mb-xs"
					>
						<p class="body-copy">Total Shipping:</p>
						<p class="body-copy">{{ cart.totalShippingCostAsCurrency }}</p>
					</div>
					<div class="offset-3/12 w-9/12 flex justify-between mb-xs">
						<p class="body-copy">Total</p>
						<p class="body-copy">{{ formatMoney(cart.total) }}</p>
					</div>
				</div>
				<div class="w-1/2 md:w-3/12 text-right">
					<!-- {# Subtotal #} -->
					<div class="pt-md">
						<!-- {# Shipping #} -->

						<!-- {# GST #} -->
						<!-- <span v-for="(adj, index) in cart.orderAdjustments" :key="`adj-${index}`">
							<p v-if="adj.type === 'tax'" class="body-copy">{{ formatMoney(adj.amount) }}</p>
						</span -->

						<!-- {# Total #} -->
					</div>
				</div>
			</div>
		</div>
	</transition>
</div>
</template>

<script>
import { getters, actions } from 'vstore/store';

export default {
	name: 'OrderSummary',
	data() {
		return {
			valid: false,
			values: {},
		};
	},

	computed: {
		cart() {
			return getters.cart();
		},
		lineItems() {
			return getters.lineItems();
		},
	},

	watch: {},

	methods: {
		formatMoney(val) {
			return actions.formatMoney(val);
		},
		capitalize(s) {
			if (typeof s !== 'string') return '';
			return s.charAt(0).toUpperCase() + s.slice(1);
		},
	},
};
</script>
