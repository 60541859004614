<template>
<div id="card-carousel">
	<Swiper
		v-if="windowWidth > 767"
		ref="swiper"
		type="carousel"
		class="vue-card-carousel"
		:options="swiperOptions"
	>
		<button slot="button-next" class="swiper-button-next">
			<svg
				width="45"
				height="22"
				viewBox="0 0 45 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M44.5655 12.0562C45.1448 11.4729 45.1448 10.5271 44.5655 9.94376L35.1222 0.437512C34.5426 -0.145837 33.603 -0.145837 33.0237 0.437512C32.4441 1.02086 32.4441 1.96666 33.0237 2.55L41.4176 11L33.0237 19.45C32.4441 20.0333 32.4441 20.9791 33.0237 21.5625C33.603 22.1458 34.5426 22.1458 35.1222 21.5625L44.5655 12.0562ZM0.5 12.4938H43.5161V9.50624H0.5V12.4938Z"
					fill="#E63331"
				/>
			</svg>
		</button>
		<button slot="button-prev" class="swiper-button-prev">
			<svg
				width="44"
				height="22"
				viewBox="0 0 44 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0.434479 9.94376C-0.144824 10.5271 -0.144824 11.4729 0.434479 12.0562L9.87782 21.5625C10.4574 22.1458 11.397 22.1458 11.9763 21.5625C12.5559 20.9791 12.5559 20.0333 11.9763 19.45L3.58237 11L11.9763 2.55001C12.5559 1.96666 12.5559 1.02087 11.9763 0.437531C11.397 -0.145843 10.4574 -0.145843 9.87782 0.437531L0.434479 9.94376ZM44.5 9.50624L1.48387 9.50624V12.4938L44.5 12.4938V9.50624Z"
					fill="#E63331"
				/>
			</svg>
		</button>
		<SwiperSlide v-for="(slide, i) in slides" :key="i" :class="slideClass">
			<div class="card-wrapper mb-30 sm:mb-0">
				<card :content="slide" />
				<p
					v-if="slide.caption"
					class="body-text text-grey-500 text-center block mt-sm"
				>
					{{ slide.caption }}
					<br>
					<br>
					<a
						:href="slide.url"
						class="underline u-general-hover"
					>View more
					</a>
				</p>
			</div>
		</SwiperSlide>
	</Swiper>
	<div v-else>
		<div
			v-for="(slide, i) in slides"
			:key="i"
			class="card-wrapper px-30 mb-30"
		>
			<Card :content="slide" />
		</div>
	</div>
</div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { Swiper as SwiperClass, Navigation, Autoplay } from 'swiper/core';
import Card from 'vcomponents/Card';

SwiperClass.use([Autoplay, Navigation]);

export default {
	name: 'CardCarousel',
	components: {
		Card,
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	props: {
		slides: {
			type: Array,
			required: true,
		},
	},
	emits: [],
	data() {
		return {
			windowWidth: '',
			activeSlide: '',

			swiperOptions: {
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				centeredSlides: true,
				loop: true,
				loopedSlides: this.slides.length,
				slidesPerView: 'auto',
				spaceBetween: 30,
				// Responsive breakpoints
				autoplay: {
					delay: 5000,
				},
				breakpoints: {
					768: {
						slidesPerView: 2,
						spaceBetween: 30,
					},
					998: {
						slidesPerView: 4,
					},
					1200: {
						slidesPerView: 4,
					},
					1468: {
						slidesPerView: 5,
					},
				},
			},
		};
	},
	computed: {
		isSlideGroup() {
			return this.slides[0] instanceof Array;
		},
		slideClass() {
			if (this.windowWidth >= 998) {
				return 'flex flex-nowrap';
			}
			return '';
		},
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.getWindowWidth);
			// Init
			this.getWindowWidth();
			// this.getWindowHeight();
		});
	},

	methods: {
		getWindowWidth() {
			this.windowWidth = window.innerWidth;
		},
	},
};
</script>
