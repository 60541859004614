<template>
  <div class="shipping-address-form checkout-step" :class="stepState">
    <div class="checkout-step__header">
      <h2 class="checkout-step__title h5">
        2. Shipping Address
      </h2>
    </div>

    <SmoothReflow
      :options="{
        transitionEvent: {
          selector: '.reflow-element',
          propertyName: 'opacity',
        },
      }"
      class="reflow"
    >
      <transition-group name="fade">
        <!-- Form -->
        <div
          v-show="!valid"
          key="inner"
          class="checkout-step__inner reflow-element"
        >
          <FormulateForm
            v-show="step === 2"
            id="shipping-address"
            v-model="values"
            class="checkout-step__form form pt-25"
            @submit="handleSubmit"
          >
            <div class="form__hidden">
              <FormulateInput type="hidden" name="sameAddress" value="1" />
            </div>

            <div class="form__inner">
              <div class="row">
                <div class="col w-full md:w-6/12">
                  <FormulateInput
                    type="text"
                    name="fullName"
                    validation="^required"
                    validation-name="This field is required"
                    input-class="form-field form-field--cart"
                    label="Full Name"
                    :value="
                      shippingAddress
                        ? shippingAddress.fullName
                        : cart.shippingAddress
                        ? cart.shippingAddress.fullName
                        : ''
                    "
                  />
                </div>
              </div>
              <div class="row">
                <div class="col w-full md:w-6/12">
                  <FormulateInput
                    type="text"
                    name="fields[addressPhone]"
                    input-class="form-field form-field--cart"
                    label="Phone Number"
                    :value="
                      shippingAddress
                        ? shippingAddress.addressPhone
                        : cart.shippingAddress
                        ? cart.shippingAddress.addressPhone
                        : ''
                    "
                  />
                </div>
                <div class="col w-full md:w-6/12">
                  <FormulateInput
                    type="text"
                    name="organization"
                    input-class="form-field form-field--cart"
                    label="Company"
                    :value="
                      shippingAddress
                        ? shippingAddress.organization
                        : cart.shippingAddress
                        ? cart.shippingAddress.organization
                        : ''
                    "
                  />
                </div>
              </div>
              <div class="form__row">
                <FormulateInput
                  type="text"
                  name="addressLine1"
                  validation="^required"
                  validation-name="This field is required"
                  input-class="form-field form-field--cart"
                  label="Street Address"
                  :value="
                    shippingAddress
                      ? shippingAddress.addressLine1
                      : cart.shippingAddress
                      ? cart.shippingAddress.addressLine1
                      : ''
                  "
                />
              </div>
              <div class="form__row">
                <FormulateInput
                  type="text"
                  name="addressLine2"
                  input-class="form-field form-field--cart"
                  label="Address Line 2 (optional)"
                  :value="
                    shippingAddress
                      ? shippingAddress.addressLine2
                      : cart.shippingAddress
                      ? cart.shippingAddress.addressLine2
                      : ''
                  "
                />
              </div>
              <div class="form__row">
                <FormulateInput
                  type="text"
                  name="locality"
                  validation="^required"
                  validation-name="This field is required"
                  input-class="form-field form-field--cart"
                  label="Suburb / City"
                  :value="
                    shippingAddress
                      ? shippingAddress.locality
                      : cart.shippingAddress
                      ? cart.shippingAddress.locality
                      : ''
                  "
                />
              </div>
              <div class="form__row">
                <FormulateInput
                  type="text"
                  name="postalCode"
                  validation="^required"
                  validation-name="This field is required"
                  input-class="form-field form-field--cart"
                  label="Postcode"
                  :value="
                    shippingAddress
                      ? shippingAddress.postalCode
                      : cart.shippingAddress
                      ? cart.shippingAddress.postalCode
                      : ''
                  "
                />
              </div>
              <!-- 	<div class="form__row">
							<FormulateInput
								name="deliveryInstructions"
								type="textarea"
								placeholder="Delivery Instructions"
								input-class="form-field form-field--cart border p-2 body-copy"
								validation="max:250,length"
								rows="4"
							/>
						</div> -->
              <div class="form__row">
                <div class="form__submit-wrapper">
                  <button
                    class="btn btn--primary mt-15"
                    :class="{ ' loading ': loading }"
                    :disabled="loading"
                    type="submit"
                  >
                    Save &amp; continue
                  </button>
                </div>
              </div>
            </div>
          </FormulateForm>
        </div>
        <!-- Summary -->
        <div
          v-show="valid"
          key="summary"
          class="checkout-step__summary reflow-element mt-25"
        >
          <div class="address">
            <p
              v-for="(key, index) in Object.keys(shippingAddressSummary)"
              :key="`add-${index}`"
              class="address__row"
            >
              {{ shippingAddressSummary[key] }}
            </p>
          </div>
          <button
            class="underline"
            type="button"
            value="Edit"
            @click="stepBack"
          >
            Edit
          </button>
        </div>
      </transition-group>
    </SmoothReflow>
  </div>
</template>

<script>
import labelSlide from 'modules/label-slide';
import axios from 'axios';

export default {
  name: 'CheckoutAddress',
  props: {
    cart: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    primaryShippingAddress: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['edit', 'updated'],
  data() {
    return {
      loading: false,
      showPassword: false,
      valid: false,
      values: {
        countryCode: 'NZ',
      },
      storedValues: {},
      addressUpdated: 0,
    };
  },

  computed: {
    shippingAddress() {
      return this.primaryShippingAddress;
    },
    shippingAddressSummary() {
      return {
        name: `${this.storedValues.fullName}`,
        phone: this.storedValues.addressPhone,
        company: this.storedValues.organization
          ? this.storedValues.organization
          : null,
        addressLine1: this.storedValues.addressLine1,
        addressLine2: this.storedValues.addressLine2
          ? this.storedValues.addressLine2
          : '',
        city: this.storedValues.locality,
        postal: `${this.storedValues.postalCode} New Zealand`,
        deliveryInstructions: this.storedValues.deliveryInstructions
          ? this.storedValues.deliveryInstructions
          : null,
      };
    },
    stepState() {
      if (this.step === 2) {
        return 'checkout-step--is-editable';
      }
      if (this.step > 2 && this.valid === true) {
        return 'checkout-step--is-complete';
      }
      return '';
    },
  },
  watch: {
    // because we are by default, using the primary shipping address of the client we want to check for updates so we can create a new  address if they make changes, or just use the same address.id if they don't make changes.
    // the addressUpdated Boolean will be checked before sending to add the address.id to the data.
    values: {
      // immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        console.log('changed', newVal);
        this.addressUpdated++;
      },
    },
  },

  mounted() {
    labelSlide.init(this.$el.querySelectorAll('.form-grouo--styled input'));
  },
  methods: {
    // submit this form and save new user
    handleSubmit() {
      this.loading = true;
      this.storedValues = this.values;
      const data = new FormData();
      data.append('action', 'commerce/cart/update-cart');
      data.append(window.csrfName, window.csrfToken);
      data.append('couponCode', '');
      Object.keys(this.values).forEach((key) => {
        if (this.values[key] !== false) {
          data.append(`shippingAddress[${key}]`, this.values[key]);
        }
      });
      if (this.addressUpdated < 5 && this.primaryShippingAddress !== null) {
        // console.log(this.primaryShippingAddress.id);
        this.storedValues.id = this.primaryShippingAddress.id;
        data.append('shippingAddress[id]', this.primaryShippingAddress.id);
      }

      // console.log( data.shippingAddress );

      axios
        .post('/', data, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => response.data)
        .then((data) => {
          // console.log('Success:', data);
          this.$emit('updated', {
            data: data.cart,
            shipping: this.storedValues,
          });

          this.loading = false;
          this.valid = true;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Error:', error);
        });
    },
    toggleAnimation() {
      this.animating ? (this.animating = false) : (this.animating = true);
    },
    stepBack() {
      // this is step 2
      this.$emit('edit', 2);
      this.valid = false;
    },
  },
};
</script>
