<template>
	<div class="manage-children-wrap">
		<AddChildModal v-if="childModalOpen" :open="childModalOpen" :children="children" :categories="categories"
			:active-id="activeId" @submit="updateChildren" @close="handleClose()" />

		<backdrop v-if="childModalOpen" :open="childModalOpen" @close="handleClose()" />
	</div>
</template>

<script>
import { actions, mutations, getters } from 'vstore/store';
import { resumeScroll } from 'utils/scrollControl.js';

import Backdrop from 'vcomponents/Backdrop';
import AddChildModal from 'vmodules/account/AddChildModal';

export default {
	name: 'ChildrenModalWrap',

	components: { AddChildModal, Backdrop },
	props: {
		categories: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			loading: true,
		};
	},

	computed: {
		activeId() {
			return getters.activeChildId();
		},
		activeChild() {
			return getters.activeChild();
		},
		children() {
			return getters.childrenData();
		},
		childModalOpen() {
			return getters.childModalOpen();
		},
	},

	methods: {
		handleClose() {
			mutations.setChildModalOpen(false);
			resumeScroll();
		},
		updateChildren(newActive) {
			actions.fetchChildrenData().then(() => {
				this.$nextTick(() => {
					const newActiveId = newActive - 1;
					const newChild = this.children[newActiveId] !== undefined
						? this.children[newActiveId]
						: this.children[0];
					this.updateSelectedChild(newChild);
					this.handleClose();
				});
			});
		},
		updateSelectedChild(child) {
			mutations.setActiveChild(child);
		},
	},
};
</script>
