class AddStripeCard {
	constructor() {
		this.form = document.querySelector('.add-card-form');
		this.pk = this.form.getAttribute('data-pk');
		this.cardNumber = '';
		this.cardCvc = '';
		this.cardExpiry = '';
		this.stripe = window.Stripe(this.pk);
		this.email = this.form.querySelector('[name="cardEmail"]').value;

		this.loading = false;
		this.errorContainer = this.form.querySelector('.errors');
		this.error = '';
		this.initStripe();
		this.bindEvents();
	}

	bindEvents() {
		this.form.addEventListener('submit', this.handleSubmit.bind(this));
	}

	async handleSubmit(event) {
		event.preventDefault();
		const self = this;
		this.loading = true;

		const data = new FormData(this.form);
		// check stripe payment, and create payment method id
		const stripeSuccess = await self.handleStripeSubmit(data);

		if (stripeSuccess != false) {
			this.form.querySelector('[name="paymentMethodId"]').value = stripeSuccess;

			// document.getElementById('sub-payment-form').submit()

			this.form.submit();
		} else {
			this.loading = false;
		}
	}

	async handleStripeSubmit(data) {
		const self = this;
		// set payment data

		const paymentData = {
			billing_details: {

				email: this.email,
			},
		};

		const stripeId = await this.stripe.createPaymentMethod({ type: 'card', card: this.cardNumber }).then((result) => {
			if (result.error) {
				// Show the user any errors
				const errorElement = document.getElementById('card-errors');
				errorElement.textContent = result.error.message;
				// console.log('TEST');
				return false;
			}
			// Insert the token ID int,o the form so it gets submitted to the server
			self.paymentMethodId = result.paymentMethod.id;
			return result.paymentMethod.id;
		});
		return stripeId;
	}

	initStripe() {
		const elements = this.stripe.elements();
		const style = {
			base: {
				color: '#000',
				fontSmoothing: 'antialiased',
				fontSize: '15px',
				padding: '0 0 5px 0',
				borderBottom: '1px',
				'::placeholder': {
					color: '#000',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		};

		this.cardNumber = elements.create('cardNumber', { style, placeholder: '0000 0000 0000 0000' });
		this.cardNumber.mount('#number-element');
		this.cardNumber.addEventListener('change', (event) => {
			this.setError(event);
		});

		this.cardExpiry = elements.create('cardExpiry', { style });
		this.cardExpiry.mount('#expiry-element');
		this.cardExpiry.addEventListener('change', (event) => {
			this.setError(event);
		});

		this.cardCvc = elements.create('cardCvc', { style });
		this.cardCvc.mount('#cvc-element');
		this.cardCvc.addEventListener('change', (event) => {
			this.setError(event);
		});
	}

	setError(event) {
		if (event.error) {
			this.error = event.error.message;
		} else {
			this.error = '';
		}
	}
}

export default AddStripeCard;
