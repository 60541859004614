var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-add-to-cart-wrap" }, [
    _vm.cartError
      ? _c(
          "div",
          { staticClass: "error" },
          _vm._l(_vm.cartError, function(error) {
            return _c("p", { staticClass: "mb-xs" }, [_vm._v(_vm._s(error))])
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        ref: "button",
        class: [_vm.btnClass, { loading: _vm.loading }],
        attrs: { disabled: _vm.loading },
        on: { click: _vm.addToCart }
      },
      [_vm._t("default", [_vm._v(" add to cart")])],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }