const setLabelPositions = (el) => {
	if (el) {
		const inputs = el.querySelectorAll('input, textarea');

		inputs.forEach((input) => {
			const parent = input.closest('.form-group');

			input.addEventListener('focus', () => {
				if (parent != null) {
					parent.classList.add('active');
				}
			});
			input.addEventListener('blur', () => {
				if (input.value === '' && parent != null) {
					parent.classList.remove('active');
				}
			});
			if (input.value !== '') {
				if (parent != null) {
					parent.classList.add('active');
				}
			}
		});
	}
};

export default setLabelPositions;
