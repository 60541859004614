<template>
<div class="vue-payment-form-slideout">
	<button
		v-show="showButton"
		:id="formHandle"
		class="btn"
		:style="buttonColor.length ? `color:${buttonColor}` : ''"
		@click="handleOpen"
	>
		{{ buttonText }}
	</button>

	<SlideOut :open="open" :wrap-class="bgColor" @close="handleClose()">
		<transition name="fade">
			<div v-if="success" ref="thanks" class="thanks w-full ">
				<h1 class="h2 w-full mb-md text-white">
					{{ marked(successMessage, {inline: true}) }}
				</h1>
				<div v-if="successPrompt" class="markdown text-white" v-html="marked(successPrompt)" />
				<a v-if="successButton && successButton.length" :href="successButton[0].buttonLink" class="btn btn--outline-white">
					{{ successButton[0].buttonText }}</a>
				<a v-else href="/" class="btn btn--outline-white">
					Return to homepage
				</a>
				<img
					v-if="successImage.length"
					:src="successImage"
					alt="logo"
					class="mt-lg max-w-150"
				>
			</div>
			<div
				v-else
				ref="payment-section"
				key="form"
				class="payment-form w-full pb-lg"
			>
				<h1 class="h2 w-full mb-30 text-white" @click="success = true">
					{{ title }}
				</h1>
				<div v-if="formText.length" class="markdown markdown--reduced-spacing mb-30" v-html="marked(formText)" />

				<button
					v-if="other && other !== 'null'"
					type="button"
					class="underline self-start w-full ml-0 pl-0 mb-30 text-left"
					@click="openOther"
				>
					{{ other === "oneOff" ? 'Would you like to make a one-off donation instead?' : 'Would you like to give monthly instead?' }}
				</button>
				<FormulateForm
					:id="`enupal-stripe-${formId}`"
					ref="donationForm"
					v-model="formValues"
					class="w-full"
					@submit="handleSubmit"
				>
					<input type="hidden" :name="csrf.name" :value="csrf.token">
					<input
						type="hidden"
						aria-hidden="true"
						name="action"
						value="enupal-stripe/stripe/save-order"
					>
					<input type="hidden" name="token" value>
					<input type="hidden" name="formId" :value="formId">
					<input type="hidden" name="quantity" value="1">

					<div class="form-row">
						<div class="w-full md:w-1/2">
							<FormulateInput
								v-model="formValues.firstName"
								name="firstName"
								label="First Name"
								validation="required"
								wrapper-class="form-group--styled form-group--white"
							/>
						</div>
						<div class="w-full md:w-1/2">
							<FormulateInput
								v-model="formValues.lastName"
								name="lastName"
								label="Last Name"
								validation="required"
								wrapper-class="form-group--styled form-group--white"
							/>
						</div>
					</div>
					<FormulateInput
						v-model="formValues.email"
						name="email"
						:value="currentUser"
						label="Email Address"
						validation="required"
						wrapper-class="form-group--styled form-group--white"
					/>

					<FormulateInput
						v-model="formValues.phone"
						name="phone"
						label="Phone"
						validation="required"
						wrapper-class="form-group--styled form-group--white"
					/>

					<div class="form-row">
						<div class="w-full md:w-1/2">
							<FormulateInput
								v-model="formValues.addressLine1"
								name="addressLine1"
								label="Address line 1"
								validation="required"
								wrapper-class="form-group--styled form-group--white"
							/>
						</div>
						<div class="w-full md:w-1/2">
							<FormulateInput
								v-model="formValues.addressLine2"
								name="addressLine2"
								label="Address line 2"
								wrapper-class="form-group--styled form-group--white"
							/>
						</div>
						<div class="w-full md:w-1/2">
							<FormulateInput
								v-model="formValues.city"
								name="city"
								label="City"
								validation="required"
								wrapper-class="form-group--styled form-group--white"
							/>
						</div>
						<div class="w-full md:w-1/2">
							<FormulateInput
								v-model="formValues.postCode"
								name="postCode"
								label="Post Code"
								validation="required"
								wrapper-class="form-group--styled form-group--white"
							/>
						</div>
					</div>
					<div class="flex justify-start flex-wrap my-10 ">
						<button
							v-for="(amount, index) in amounts"
							:key="`${amount}-${index}`"
							class="donation-circle"
							:class="{ 'active': formValues.amount === amount }"
							:value="amount"
							@click="toggleAmount"
						>
							${{ amount }}
						</button>

						<button class="donation-circle" value="0" @click="toggleAmount">
							Other
						</button>
					</div>

					<FormulateInput
						v-model="formValues.amount"
						name="amount"
						label="Donation Amount"
						type="number"
						validation="required|min:5"
						wrapper-class="form-group--styled form-group--white"
					/>

					<div ref="stripeElements" class="w-full mb-sm pt-40">
						<div class=" flex flex-nowrap">
							<div class="w-full">
								<!-- {# Stripe’s JavaScript will insert Stripe Elements here #} -->
								<p class="body-text transform scale-80 origin-top-left">Card Number</p>
								<div :id="`${formId}-number-element`" ref="stripeNumber" class="stripe-input border-white" />
							</div>
							<div class="flex-shrink-0 stripe-expiry">
								<!-- {# Stripe’s JavaScript will insert Stripe Elements here #} -->
								<p class="body-text transform scale-80 origin-top-left">Exp Date</p>
								<div :id="`${formId}-expiry-element`" ref="stripeExpiry" class="stripe-input border-white" />
							</div>
							<div class="flex-shrink-0 stripe-cvc">
								<!-- {# Stripe’s JavaScript will insert Stripe Elements here #} -->
								<p class="body-text transform scale-80 origin-top-left">CVC</p>
								<div :id="`${formId}-cvc-element`" ref="stripeCvc" class="stripe-input border-white" />
							</div>
						</div>
						<div class="card-errors" />
					</div>
					<div class="mb-40">
						<FormulateInput
						v-model="formValues.newsletter"
						name="newsletter"
						type="checkbox"
						label="Sign up to the newsletter"
						wrapper-class="form-group--styled form-group--white checkbox"
					/>
					</div>


					<p v-if="this.error.length" class="py-xs"> {{ this.error }} </p>

					<button
						type="submit"
						class="btn btn--outline-white self-start"
						:class="{'loading' : loading}"
						:disabled="loading"
					>
						{{ formSubmit }}
					</button>
					<div class="recaptcha-blurb pt-sm">
						<p>
							This site is protected by reCAPTCHA and the Google
							<a href="https://policies.google.com/privacy">Privacy Policy</a>
							and
							<a href="https://policies.google.com/terms">Terms of Service</a>
							apply.
						</p>
					</div>
				</formulateform>
			</div>
			</FormulateForm>
		</transition>
	</SlideOut>
</div>
</template>

<script>
import axios from 'axios';
import setLabelPositions from 'utils/setLabelPosition';
import { getters, actions } from 'vstore/store';
import SlideOut from 'vcomponents/SlideOut';

export default {
	name: 'DonationForm',
	components: {
		SlideOut,
	},
	props: {
		bgColor: {
			type: String,
			default: '',
		},
		buttonColor: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: 'Make a Donation',
		},
		campaign: {
			type: String,
			default: '',
		},
		currentUser: {
			type: String,
			required: true,
		},
		formHandle: {
			type: String,
			required: true,
		},
		formId: {
			type: String,
			required: true,
		},
		formSubmit: {
			type: String,
			default: 'Make Donation',
		},
		formText: {
			type: String,
			default: '',
		},
		other: {
			type: String,
			required: true,
		},
		showButton: {
			type: Boolean,
			default: false,
		},
		stripePk: {
			type: String,
			default: '',
		},

		successImage: {
			type: String,
			default: '',
		},
		successMessage: {
			type: String,
			default: 'You’re amazing. \n Thanks for the support',
		},
		successPrompt: {
			type: String,
			default: '',
		},
		successButton: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: 'Donation',
		},
		testMode: {
			type: Boolean,
			default: true,
		},
		customAmounts: {
			type: Array,
			default: () => [],
		},
	},
	emits: ['money'],
	data() {
		return {
			amountsOne: [40, 80, 120],
			amountsMonth: [10, 20, 50],
			cardNumber: '',
			cardExpiry: '',
			cardCvc: '',
			loading: false,
			formValues: {amount: 5},
			open: false,
			success: false,
			error: '',
		};
	},
	computed: {
		amounts() {
			if (this.customAmounts.length) {
				return this.customAmounts.map(amount => amount.amount);
			}
			if (this.formHandle.includes('one')) {
				return this.amountsOne;
			}
			return this.amountsMonth;
		},
		csrf() {
			return {
				name: window.csrfName,
				token: window.csrfToken,
			};
		},
		gscore(){
			return getters.gScore()
		},
		stripe() {
			return window.Stripe(this.stripePk);
		},
	},
	mounted() {
		setLabelPositions(this.$refs.donationForm.$el);
		this.initStripe();
		actions.csrfSet();

		this.triggers = document.querySelectorAll(`[href="#${this.formHandle}-donation"]`);
		console.log(`[href="#${this.formHandle}-donation"]`);
		if (this.triggers.length) {
			this.triggers.forEach((trigger) => {
				console.log(trigger);
				trigger.addEventListener('click', this.handleOpen);
			});
		}
	},
	methods: {
		marked(value, options) {
			return actions.marked(value, options);
		},
		handleOpen() {
			this.open = true;
		},
		handleClose() {
			this.open = false;
		},
		toggleAmount(e) {
			e.preventDefault();
			// console.log(e.target.value);
			this.$emit('money', e.target.value);
			this.formValues.amount = e.target.value;
			this.$refs.donationForm.$el.querySelector('[name="amount"]').focus();
			// console.log(this.$refs.donationForm.$el);
		},
		setError(event) {
			if (event.error) {
				this.error = event.error.message;
			} else {
				this.error = '';
			}
		},
		initStripe() {
			const elements = this.stripe.elements();
			const style = {
				base: {
					color: '#fff',
					fontSmoothing: 'antialiased',
					fontSize: '15px',
					'::placeholder': {
						color: '#fff',
					},
				},
				invalid: {
					color: '#fff',
					iconColor: '#fff',
				},
			};

			this.cardNumber = elements.create('cardNumber', { style, placeholder: 'Card Number' });
			this.cardNumber.mount(this.$refs.stripeNumber);
			this.cardNumber.on('change', (event) => {
				this.setError(event);
			});

			this.cardExpiry = elements.create('cardExpiry', { style });
			this.cardExpiry.mount(this.$refs.stripeExpiry);
			this.cardExpiry.on('change', (event) => {
				this.setError(event);
			});

			this.cardCvc = elements.create('cardCvc', { style });
			this.cardCvc.mount(this.$refs.stripeCvc);
			this.cardCvc.on('change', (event) => {
				this.setError(event);
			});
		},
		openOther() {
			this.handleClose();
			let otherButton;
			if (document.querySelector(`#${this.other}`)) {
				otherButton = document.querySelector(`#${this.other}`);
			} else {
				otherButton = document.querySelector(`[href="#${this.other}-donation"`);
			}
			otherButton.click();
		},
		async handleSubmit() {
			this.loading = true;

			const formData = new FormData();
			// if the google recaptcha score is lower than this, don't submit the form

			if (this.gScore < 0.8) {
				this.error = 'Sorry, but there was an error authenticating you. Please contact us for assistance';
				this.loading = false;
				return false;
			}
				// check stripe payment, and create payment method id
			const token = await this.stripe.createToken(this.cardNumber).then((result) => {
				if (result.error) {
					// Show the user any errors
					const errorElement = this.$refs.stripeElements.querySelector('.card-errors');
					errorElement.textContent = result.error.message;
					return false;
				}
				return result.token;
			});
			
			if (token !== false) {
				formData.append('action', '/enupal-stripe/stripe/save-order');
				formData.append(this.csrf.name, this.csrf.token);
				formData.append('enupalStripe[token]', token.id);
				formData.append('enupalStripe[testMode]', this.testMode ? 1 : 0);
				formData.append('enupalStripe[formId]', this.formId);
				formData.append('enupalStripe[email]', this.formValues.email);
				formData.append('enupalStripe[amount]', this.formValues.amount * 100);
				formData.append('enupalStripe[customPlanAmount]', this.formValues.amount);
				formData.append('enupalStripe[quantity]', 1);
				formData.append('enupalStripe[country]', 'NZ');
				formData.append('enupalStripe[address][country]', 'NZ');
				formData.append('enupalStripe[address][name]', `${this.formValues.firstName} ${this.formValues.lastName}`);
				formData.append('enupalStripe[address][line1]', this.formValues.addressLine1);
				formData.append('enupalStripe[address][line2]', this.formValues.addressLine2);
				formData.append('enupalStripe[address][city]', this.formValues.city);
				formData.append('enupalStripe[address][zip]', this.formValues.postCode);
				formData.append('enupalStripe[metadata][phone]', this.formValues.phone);
				formData.append('enupalStripe[metadata][addressLine1]', this.formValues.addressLine1);
				formData.append('enupalStripe[metadata][addressLine2]', this.formValues.addressLine2);
				formData.append('enupalStripe[metadata][city]', this.formValues.city);
				formData.append('enupalStripe[metadata][postCode]', this.formValues.postCode);
				formData.append('enupalStripe[metadata][newsletter]', this.formValues.newsletter);
				if (this.campaign.length) {
					formData.append('enupalStripe[metadata][campaign]', this.campaign);
				}

				axios.post('/', formData, {
					headers: {
						Accept: 'application/json',
					},
				})
					.then(response => {
						return response.data
					})
					.then((data) => {
					// console.log('Success:', data);
						this.loading = false;
						if (data.errors) {
							this.error = data.errors.stripePayments[0];
							console.error(data, data.errors);
						} else {
							this.success = true;
						}
					})
					.catch((error) => {
						this.loading = false;
						this.error = error;
						console.error(error);
					// console.error('Error:', error);
					});
			} else {
				this.loading = false;
			}
		},

	},
};
</script>
