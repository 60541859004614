<template>
<section class="w-full">
	<div class="shadow-standard px-30 py-40">
		<div class="row">
			<div class="col w-full">
				<h2 class="h3 mb-30">
					Recommendations for
					{{ activeChild ? activeChild.name : '...' }}
				</h2>
			</div>
		</div>
		<SmoothReflow>
			<div v-if="recommendedArticles.length" class="row">
				<div
					v-for="(article, index) in recommendedArticles"
					:key="`${article.id}-${index}`"
					class="col w-full md:w-1/2 mb-30 fade-in"
				>
					<card :content="article" />
				</div>
			</div>
		</SmoothReflow>
		<div v-if="!recommendedArticles.length && !loading || !children" class="row">
			<div class="col w-full ">
				<h4 v-if="children && children.length > 0" class="h5 fade-in">
					Add some tags to
					{{ activeChild ? activeChild.name : '' }} so we can give
					you some recommendations!
				</h4>
				<h4 v-else class="h5 fade-in">Tell us about your child so we can reccomend you some helpful content</h4>
			</div>
		</div>
		<div v-if="recommendedArticles.length && recommendedArticles.length < entryCount" class="row justify-center">
			<button class="mt-40 btn btn--primary" @click="getLatestReccomendations">View More</button>
		</div>
	</div>
	</div>
</section>
</template>

<script>

import axios from 'axios';
import Card from 'vcomponents/Card';

import { getters } from 'vstore/store';

export default {
	name: 'MyFamily',
	components: { Card },

	data() {
		return {
			entryCount: 0,
			increment: 6,
			loading: false,
			offset: 0,
			recommendedArticles: [],

		};
	},

	computed: {
		activeChild() {
			return getters.activeChild();
		},
		children() {
			return getters.childrenData();
		},
		selectedChildAge() {
			if (this.activeChild) {
				const bday = new Date(this.activeChild.dateOfBirth.date);
				return bday;
			}
			return undefined;
		},
		ageGroup() {
			const age = this.selectedChildAge;
			switch (true) {
			case age <= 1:
				return 'new-born';
			case age <= 4:
				return 'toddlers';
			case age <= 9:
				return 'primary-years';
			case age <= 12:
				return 'pre-teen';
			case age >= 13:
				return 'teenagers';
			default:
				return undefined;
			}
		},
	},
	watch: {
		activeChild: {
			handler() {
				this.offset = 0;
				this.recommendedArticles = [];
				this.getLatestReccomendations();
			},
			deep: true,
		},
	},
	methods: {
		getLatestReccomendations() {
			const tags = this.activeChild.childTags;
			this.getRecommendations(this.RelatedArticlesQuery(tags));
		},
		RelatedArticlesQuery(tags) {
			let tagArray = [];
			if (tags !== null && tags.length > 0) {
				tags.forEach((tag) => {
					tagArray.push(`"${tag.slug}"`);
				});
			} else {
				tagArray = [];
			}
			if (this.ageGroup) {
				tagArray.push(`"${this.ageGroup}"`);
			}

			return `query articles {
				entries(type: ["courses","resources"], orderBy: "postDate DESC", relatedToCategories: { slug: [${tagArray}]}, limit:${this.increment}, offset: ${this.offset}) {
					... on resources_resources_Entry {
						id
						type :typeHandle
						title
						url
						text: blurb
						categories: articleCategory {
				      title
				    }
						image {
							id
							url
							title
							path
						}
					}
					... on courses_courses_Entry {
			      id
			      title
			      url
			      blurb
			      image {
			        id
			        url
							title
							path
			      }
			    }
				}
				total: entryCount(type: ["courses","resources"], relatedToCategories: { slug: [${tagArray}]})
			}`;
		},
		getRecommendations(qObject) {
			this.loading = true;
			// console.log(qObject);
			axios('/api', {
				method: 'post',
				data: {
					query: qObject,
				},
			})
				.then((response) => {
					// console.log(response.data);
					if (response.data.errors) {
						return false;
					}
					return response.data;
				})
				.then((response) => {
					if (response.data) {
						// console.log(response.data.entries);
						this.recommendedArticles = this.recommendedArticles.concat(response.data.entries);
						this.entryCount = response.data.total;
						this.offset += this.increment;
						this.loading = false;
					}
				})
				.catch((error) => {
					console.error(error);
					this.loading = false;
				});
		},
	},
};
</script>
