<template>
  <div class="payment-form checkout-step">
    <div class="checkout-step__header">
      <h2
        class="checkout-step__title h5 smaller"
        @click="valid ? (valid = false) : (valid = true)"
      >
        4. Payment
      </h2>
    </div>
    <SmoothReflow
      :options="{
        transitionEvent: {
          selector: '.reflow-element',
          propertyName: 'opacity',
        },
      }"
      class="reflow"
    >
      <transition-group name="fade">
        <div
          v-if="step === 4 && defaultPaymentSet"
          key="default-payment"
          class="default-payment reflow-element"
        >
          <div class="card pt-25 ">
            <p class="body-text mb-50">
              <strong>Default Payment Option</strong>
              <br />
              {{ defaultPaymentOption.description }}
              <br />
              {{
                `${defaultPaymentDetails.card.brand[0].toUpperCase() +
                  defaultPaymentDetails.card.brand.slice(1)} ending in ${
                  defaultPaymentDetails.card.last4
                }`
              }}
            </p>
            <button
              id="submit-button"
              class="btn btn--primary"
              :class="{ ' loading ': loading }"
              :disabled="loading"
              @click="handleSubmit"
            >
              Complete order
            </button>
          </div>
        </div>
        <FormulateForm
          v-show="step === 4 && !defaultPaymentSet"
          id="sub-payment-form"
          ref="payForm"
          key="form"
          v-model="values"
          class="checkout-step__form form pt-25 reflow-element"
          method="post"
          @submit="handleSubmit"
        >
          <input type="hidden" name="redirect" :value="redirectUrl" />
          <input type="hidden" name="cancelUrl" :value="cancelUrl" />

          <div class="form__row pb-25">
            <div class="col w-full px-30 pt-30 pb-5 border-1">
              <div class="row">
                <div class="col w-full md:w-1/2 mb-15">
                  <FormulateInput
                    v-model="values.firstName"
                    :name="`${paymentNamespace}[firstName]`"
                    label="First Name"
                    validation-name="First Name"
                    validation="required"
                  />
                </div>
                <div class="col w-full md:w-1/2 mb-15">
                  <FormulateInput
                    v-model="values.lastName"
                    :name="`${paymentNamespace}[lastName]`"
                    label="Last Name"
                    validation-name="Last Name"
                    validation="required"
                  />
                </div>
                <div class="col w-full ">
                  <div id="number-element" class="stripe-input">
                    <!-- {# Stripe's JavaScript will insert Stripe Elements here #} -->
                  </div>
                </div>
                <div class="col w-full md:w-6/12">
                  <div id="expiry-element" class="stripe-input">
                    <!-- {# Stripe's JavaScript will insert Stripe Elements here #} -->
                  </div>
                </div>
                <div class="col w-full md:w-6/12">
                  <div id="cvc-element" class="stripe-input">
                    <!-- {# Stripe's JavaScript will insert Stripe Elements here #} -->
                  </div>
                </div>
                <!-- </div> -->
              </div>
              <!-- 		{# Used to display form errors #} -->
              <SmoothReflow>
                <div
                  v-show="error != ''"
                  id="card-errors"
                  role="alert"
                  class="col"
                >
                  <p>{{ error }}</p>
                </div>
              </SmoothReflow>
            </div>
            <button
              id="submit-button"
              type="submit"
              class="btn btn--primary mt-25"
              :class="{ ' loading ': loading }"
              :disabled="loading"
            >
              Complete order
            </button>
          </div>
        </FormulateForm>
      </transition-group>
    </SmoothReflow>
    <SmoothReflow>
      <div
        v-if="error !== ''"
        ref="error-container"
        class="error pt-25 reflow-element"
      >
        <p class="body-copy text-red">{{ error }}</p>
      </div>
    </SmoothReflow>
  </div>
</template>
<script>
// Make sure you are including the stripe js on the page.
// It can not be included as a package
// https://stripe.com/docs/js
import axios from 'axios';
import { getters, mutations } from 'vstore/store';

import setLabelPositions from 'utils/setLabelPosition';

export default {
  name: 'CartPayment',
  props: {
    defaultPaymentOption: {
      type: Object,
      required: true,
    },
    gateway: {
      type: Object,
      required: true,
    },
    newUser: {
      type: Object,
      required: true,
    },
    paymentNamespace: {
      type: String,
      required: true,
    },
    redirectUrl: {
      type: String,
      required: true,
    },
    cancelUrl: {
      type: String,
      required: true,
    },
    stripePk: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['payed', 'createUser'],
  data() {
    return {
      card: '',
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      loading: false,
      showPassword: false,
      valid: false,
      values: {},
      error: '',
      storedValues: {},
      paymentMethodId: null,
      defaultError: false,
    };
  },
  computed: {
    cart() {
      return getters.cart();
    },
    csrf() {
      return {
        name: getters.csrfName(),
        token: getters.csrfToken(),
      };
    },
    stripe() {
      return window.Stripe(this.stripePk);
    },
    defaultPaymentSet() {
      return this.defaultPaymentOption.response !== undefined;
    },
    defaultPaymentDetails() {
      return this.defaultPaymentSet ? this.defaultPaymentOption.response : '';
    },
  },
  mounted() {
    // Handle Stripe Gateway
    this.initStripe();
    setLabelPositions(this.$el);
  },
  methods: {
    async handleSubmit() {
      this.loading = true;

      let data;
      console.log(this.$refs.payForm.$el);
      if (this.defaultPaymentSet) {
        data = new FormData();
      } else {
        data = new FormData(this.$refs.payForm.$el);
        console.log(data);
      }
      const stripeSuccess = this.defaultPaymentSet
        ? true
        : await this.handleStripeSubmit(data);

      if (stripeSuccess !== false && this.newUser.email) {
        this.createUser().then(() => {
          this.processPayment(data, stripeSuccess);
        });
      } else if (stripeSuccess !== false) {
        this.processPayment(data, stripeSuccess);
      } else {
        this.loading = false;
      }
    },
    async processPayment(formData, stripeSuccess) {
      formData.delete(this.csrf.name);
      console.log(formData);

      if (this.defaultPaymentSet) {
        formData.append(
          `${this.paymentNamespace}[paymentMethodId]`,
          this.defaultPaymentOption.id
        );
      } else {
        formData.append(
          `${this.paymentNamespace}[paymentMethodId]`,
          stripeSuccess
        );
      }

      formData.append(this.csrf.name, this.csrf.token);
      formData.append('action', 'commerce/payments/pay');
      formData.append('gatewayId', this.gateway.id);

      let logData = Object.fromEntries(formData);
      console.log(logData);

      axios
        .post('/', formData, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then((response) => response.data)
        .then((response) => {
          if (response.error) {
            this.loading = false;
            this.error = response.error;
            return;
          }
          console.log(response);

          this.$emit('payed', true);
          this.loading = false;
          this.valid = true;

          if (response.redirect) {
            this.error = 'Redirecting to authenticate payment';
            setTimeout(window.location.assign(response.redirect), 1000);
          } else {
            window.swup.loadPage({
              url: `/checkout/order?order=${this.cart.number}`,
            });
          }
        })
        .catch((error) => {
          this.loading = false;

          this.error = error.response.data.message;
        });
    },
    async handleStripeSubmit() {
      const self = this;
      // set payment data

      const paymentData = {
        // Stripe requires underscore
        // eslint-disable-next-line camelcase
        billing_details: {
          email: this.newUser.email ? this.newUser.email : this.cart.email,
          name: this.values.firstName + ' ' + this.values.lastName,
        },
      };
      // console.log(paymentData);
      // paymentData = { ...paymentData };
      const createPaymentMethod = await this.stripe
        .createPaymentMethod('card', this.cardNumber, paymentData)
        .then((result) => {
          if (result.error) {
            // Show the user any errors
            const errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
            return false;
          }
          // Insert the token data.append(this.csrf.name, this.csrf.token);
          // into the form so it gets submitted to the server
          self.paymentMethodId = result.paymentMethod.id;
          // console.log('handle Stripe', result);
          return result.paymentMethod.id;
        });
      return createPaymentMethod;
    },
    initStripe() {
      const elements = this.stripe.elements();
      const style = {
        base: {
          color: '#000',
          fontSmoothing: 'antialiased',
          fontSize: '15px',
          '::placeholder': {
            color: '#000',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };

      this.cardNumber = elements.create('cardNumber', {
        style,
        placeholder: '0000 0000 0000 0000',
      });
      this.cardNumber.mount('#number-element');
      this.cardNumber.on('change', (event) => {
        this.setError(event);
      });
      this.cardExpiry = elements.create('cardExpiry', { style });
      this.cardExpiry.mount('#expiry-element');
      this.cardExpiry.on('change', (event) => {
        this.setError(event);
      });
      this.cardCvc = elements.create('cardCvc', { style });
      this.cardCvc.mount('#cvc-element');
      this.cardCvc.on('change', (event) => {
        this.setError(event);
      });
    },
    async createUser() {
      this.loading = true;
      const data = new FormData();
      data.append(this.csrf.name, this.csrf.token);
      data.append('action', 'users/save-user');
      data.append('firstName', this.newUser.firstName);
      data.append('lastName', this.newUser.lastName);
      data.append('email', this.newUser.email);
      data.append('password', this.newUser.password);

      return axios
        .post('/', data, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => response.data)
        .then((response) => {
          if (response.errors) {
            this.error = response.errors;
          } else {
            this.valid = true;
            // console.log('create-user',response);
            mutations.setCsrfToken(response.csrfTokenValue);
            this.$emit('createUser');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          // console.log('createUser error');
        });
    },
    setError(event) {
      if (event.error) {
        this.error = event.error.message;
      } else {
        this.error = '';
      }
    },
  },
};
</script>
