<template>
  <nav
    class="vue-filter w-full border-b-0 special:border-b-1 border-grey-200 flex justify-center "
    :class="stacked ? 'mb-sm md:mb-rg' : 'mb-rg'"
  >
    <div class="buttons hidden special:block">
      <button
        v-for="(tag, i) in JSON.parse(JSON.stringify(tags))"
        :key="`resource-button-${i}`"
        class="btn btn--tab ml-0 uppercase tracking-small text-small"
        :class="{ active: filters.tag === handelize(tag.handle) }"
        @click="updateFilterTag(tag)"
      >
        {{ tag.title }}
      </button>
    </div>
    <div
      class="select flex justify-center items-center special:hidden font-gilroy text-grey-500 tracking-small uppercase leading-standard text-small  "
    >
      <label
        class="font-gilroy text-grey-500 tracking-small uppercase leading-standard text-small "
        :class="stacked ? 'xs:min-w-100 ' : ''"
      >
        Filter:
      </label>
      <Dropdown
        :options="JSON.parse(JSON.stringify(tags))"
        placeholder="1"
        :bg="`bg-${bg}`"
        @selected="updateFilterTag"
      />
    </div>
  </nav>
</template>

<script>
  import { throttle } from 'utils/utilities';
  import Dropdown from 'vcomponents/Dropdown';

  export default {
    name: 'ContentFilter',
    components: {
      Dropdown,
    },
    props: {
      bg: {
        type: String,
        default: 'transparent',
      },
      tags: {
        type: Array,
        required: true,
      },
      stacked: {
        type: Boolean,
        default: false,
      },
      selected: {
        type: String,
        default: undefined,
      },
    },
    emits: ['updated'],
    data() {
      return {
        windowWidth: '',
        loading: false,

        filters: {
          tag: '',
        },
      };
    },
    mounted() {
      this.windowWidth = window.innerWidth;
      window.addEventListener(
        'resize',
        throttle(() => {
          // console.log('resize');
          this.windowWidth = window.innerWidth;
        }, 200)
      );
      if (this.selected !== undefined) {
        this.filters.tag = this.selected;
      } else {
        this.filters.tag = JSON.parse(JSON.stringify(this.tags))[0].handle;
      }
    },
    methods: {
      handelize(str) {
        return str
          .replace('& ', '')
          .replace(/\s/g, '-')
          .toLowerCase();
      },
      updateFilterTag(selected) {
        this.filters.tag = this.handelize(selected.handle);
        this.$emit('updated', this.handelize(selected.handle));
      },
    },
  };
</script>
