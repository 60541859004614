<template>
  <div class="simple-add-to-cart-wrap">
    <div v-if="cartError" class="error">
      <p v-for="error in cartError" class="mb-xs">{{ error }}</p>
    </div>
    <button
      ref="button"
      :class="[btnClass, { loading: loading }]"
      :disabled="loading"
      @click="addToCart"
    >
      <slot> add to cart</slot>
    </button>
  </div>
</template>

<script>
// import axios from 'axios';
import { getters, actions } from 'vstore/store';

export default {
  name: 'SimpleAddToCart',
  props: {
    btnClass: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      required: true,
    },
  },
  emits: ['addToCart'],

  data() {
    return {
      quantity: 1,
      loading: false,
    };
  },
  computed: {
    cartError() {
      return getters.cartError();
    },
  },
  mounted() {
    actions.csrfSet();
  },
  methods: {
    addToCart() {
      // console.log('test');
      this.$emit('addToCart');
      this.loading = true;
      const productId = this.product.defaultVariantId;
      const qty = this.quantity;
      // eslint-disable-next-line quote-props
      actions
        .addToCart({ purchasableId: productId, quantity: qty })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
    },
  },
};
</script>
