<template>
<div class="add-to-cart-events-wrap">
	<FormulateForm>
		<div class="form-group form-group--inline flex justify-between items-end">
			<FormulateInput
				v-if="ticketOptions.length > 1"
				v-model="purchasableId"
				type="select"
				:options="ticketOptions"
				outer-class="form-group form-group--styled form-group--formulate pl-15 pr-30"
			/>
			<div v-else class="flex-shrink-0 ">
				<p class="body-text font-gilroy">
					<!-- {{ this.tickets[0].title }} -->
					${{ parseInt(tickets[0].price) }}
					<span class="small-caps">per ticket</span>
				</p>
			</div>
			<FormulateInput
				v-model="quantity"
				name="qty"
				type="number"
				value="1"
				outer-class="form-group form-group--formulate form-group--styled w-50 text-center"
				element-class=" w-full"
			/>
		</div>
	</FormulateForm>
	<button
		:class="{ loading: loading }"
		class="btn btn--primary"
		@click="addToCart"
	>
		Add to Cart
	</button>
</div>
</template>

<script>
// import axios from 'axios';
import { actions } from 'vstore/store';

export default {
	name: 'AddToCartEvent',
	props: {
		event: {
			type: Object,
			required: true,
		},
		tickets: {
			type: Array,
			required: true,
		},
	},
	emits: ['addToCart'],

	data() {
		return {
			quantity: 1,
			purchasableId: '',
			loading: false,
		};
	},

	computed: {
		ticketOptions() {
			const ticketsArray = [];
			this.tickets.forEach((item) => {
				const ticket = {
					value: item.id,
					label: item.title,
				};
				ticketsArray.push(ticket);
			});
			return ticketsArray;
		},
	},
	mounted() {
		actions.csrfSet();
		// console.log()
		this.purchasableId = this.tickets[0].id;
	},

	methods: {
		addToCart() {
			// console.log('test');
			this.$emit('addToCart');
			this.loading = true;
			const eventId = this.purchasableId;
			const qty = this.quantity;
			// eslint-disable-next-line quote-props
			actions.addToCart({ purchasableId: eventId, quantity: qty }).then(() => {
				this.loading = false;
			});
		},
	},
};
</script>
