var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "card-carousel" } },
    [
      _vm.windowWidth > 767
        ? _c(
            "Swiper",
            {
              ref: "swiper",
              staticClass: "vue-card-carousel",
              attrs: { type: "carousel", options: _vm.swiperOptions }
            },
            [
              _c(
                "button",
                {
                  staticClass: "swiper-button-next",
                  attrs: { slot: "button-next" },
                  slot: "button-next"
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "45",
                        height: "22",
                        viewBox: "0 0 45 22",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M44.5655 12.0562C45.1448 11.4729 45.1448 10.5271 44.5655 9.94376L35.1222 0.437512C34.5426 -0.145837 33.603 -0.145837 33.0237 0.437512C32.4441 1.02086 32.4441 1.96666 33.0237 2.55L41.4176 11L33.0237 19.45C32.4441 20.0333 32.4441 20.9791 33.0237 21.5625C33.603 22.1458 34.5426 22.1458 35.1222 21.5625L44.5655 12.0562ZM0.5 12.4938H43.5161V9.50624H0.5V12.4938Z",
                          fill: "#E63331"
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "swiper-button-prev",
                  attrs: { slot: "button-prev" },
                  slot: "button-prev"
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "44",
                        height: "22",
                        viewBox: "0 0 44 22",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M0.434479 9.94376C-0.144824 10.5271 -0.144824 11.4729 0.434479 12.0562L9.87782 21.5625C10.4574 22.1458 11.397 22.1458 11.9763 21.5625C12.5559 20.9791 12.5559 20.0333 11.9763 19.45L3.58237 11L11.9763 2.55001C12.5559 1.96666 12.5559 1.02087 11.9763 0.437531C11.397 -0.145843 10.4574 -0.145843 9.87782 0.437531L0.434479 9.94376ZM44.5 9.50624L1.48387 9.50624V12.4938L44.5 12.4938V9.50624Z",
                          fill: "#E63331"
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.slides, function(slide, i) {
                return _c("SwiperSlide", { key: i, class: _vm.slideClass }, [
                  _c(
                    "div",
                    { staticClass: "card-wrapper mb-30 sm:mb-0" },
                    [
                      _c("card", { attrs: { content: slide } }),
                      _vm._v(" "),
                      slide.caption
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "body-text text-grey-500 text-center block mt-sm"
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(slide.caption) +
                                  "\n\t\t\t\t\t"
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "underline u-general-hover",
                                  attrs: { href: slide.url }
                                },
                                [_vm._v("View more\n\t\t\t\t\t")]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        : _c(
            "div",
            _vm._l(_vm.slides, function(slide, i) {
              return _c(
                "div",
                { key: i, staticClass: "card-wrapper px-30 mb-30" },
                [_c("Card", { attrs: { content: slide } })],
                1
              )
            }),
            0
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }