<template>
<div class="search">
	<form action="/search" class="w-full flex items-center relative">
		<input
			v-model="query"
			type="search"
			name="q"
			aria-label="Search"
			placeholder="I'm searching for..."
			class="mx-auto px-sm text-mob-h4 sm:text-h4 bg-transparent text-white xs:pl-0 xs:w-385"
			autocomplete="off"
			@input="throttleInput"
		>
		<button
			type="submit"
			class="absolute center-y w-30 md:w-auto right-0 lg:right-60  p-0 general-hover"
		>
			<svg
				width="47"
				height="8"
				viewBox="0 0 47 8"
				fill="none"
			>
				<path
					d="M46.3536 4.35355C46.5488 4.15829 46.5488 3.84171 46.3536 3.64645L43.1716 0.464466C42.9763 0.269204 42.6597 0.269204 42.4645 0.464466C42.2692 0.659728 42.2692 0.976311 42.4645 1.17157L45.2929 4L42.4645 6.82843C42.2692 7.02369 42.2692 7.34027 42.4645 7.53553C42.6597 7.7308 42.9763 7.7308 43.1716 7.53553L46.3536 4.35355ZM0 4.5H46V3.5H0V4.5Z"
					stroke="white"
				/>
			</svg>
		</button>
	</form>

	<div class="suggestions mx-auto max-w-385">
		<SmoothReflow transition="height 0.25s ease-out" :self-update="true">
			<transition-group
				ref="transition"
				name="list-fade"
				tag="div"
				appear
				@before-leave="listLeave"
			>
				<div
					v-for="(suggestion, index) in suggestions"
					:key="`${suggestion.handle}-${index}`"
					class="suggestions__item block  py-5"
					:style="`--i: ${index}`"
				>
					<a :href="`/${suggestion.url}`" class="general-hover block">
						<p class="suggestions__title body-text">{{ suggestion.title }}</p>
						<p
							v-if="suggestion.text"
							class="suggestions__abstract body-text  text-small"
						>
							{{ suggestion.text }}
						</p>
					</a>
				</div>
			</transition-group>
		</SmoothReflow>
	</div>
</div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash/debounce';
import SmoothReflow from 'vcomponents/SmoothReflow';

export default {
	name: 'SiteSearch',
	components: {
		SmoothReflow,
	},
	data() {
		return {
			resultsPerPage: 6,
			loading: false,
			savedSuggestions: [],
			suggestions: [],
			query: '',
			queryRunning: false,
		};
	},
	computed: {
		formattedSuggestions() {
			const formattedArray = [];
			this.suggestions.forEach((item) => {
				formattedArray.push([
					{
						title: item.title,
						abstract: item.text,
						handle: item.id,
					},
				]);
			});
			return formattedArray;
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		suggestedEntries() {
			const formattedQuery = this.query.replace(' ', ' OR ');
			const params = [];
			params.push(`search: "${formattedQuery}"`);
			params.push(`limit : ${this.resultsPerPage}`);

			return `query suggestions {
				entries(${params.join(', ')}, section : ["resources", "courses"]) {
					handle: slug
					title
					url: uri
					... on resources_resources_Entry {
     				text: blurb
    			}
					 ... on courses_courses_Entry {
						text: blurb
					}
				}
			}`;
		},
		suggestedProducts() {
			const formattedQuery = this.query.replace(' ', 'OR');
			const params = [];
			params.push(`search: "${formattedQuery}"`);
			params.push(
				`limit : ${this.resultsPerPage - this.savedSuggestions.length}`,
			);
			return `query suggestions {
				products(${params.join(', ')}) {
					handle: slug
					title
					url: uri
					... on general_Product {
						text: abstract
					}
				}
			}`;
		},

		async graphQlQuery(qObject) {
			this.loading = true;

			return axios('/api', {
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					[window.csrfName]: [window.csrfToken],
				},
				data: {
					query: qObject,
				},
			})
				.then(response => response.data)
				.then((response) => {
					// console.log(Object.values(response.data)[0]);
					if (
						this.savedSuggestions.length < this.resultsPerPage
						&& this.savedSuggestions != Object.values(response.data)[0]
					) {
						this.savedSuggestions = this.savedSuggestions.concat(
							Object.values(response.data)[0],
						);
					}
				})
				.catch((error) => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		fetchSuggestions() {
			if (!this.queryRunning) {
				this.suggestions = [];
				this.savedSuggestions = [];
				this.queryRunning = true;
				this.graphQlQuery(this.suggestedEntries())
					.then(() => {
						if (this.savedSuggestions.length < this.resultsPerPage) {
							return this.graphQlQuery(this.suggestedProducts()).then(
								() => true,
							);
						}
						return true;
					})
					.finally(() => {
						this.suggestions = this.savedSuggestions;
						this.queryRunning = false;
					});
			}
		},
		throttleInput: debounce(function () {
			this.fetchSuggestions();
		}, 300),
		listLeave() {
			this.$refs.transition.$el.style.height = `${
				this.$refs.transition.$el.offsetHeight
			}px`;
			setTimeout(() => {
				this.$refs.transition.$el.style.height = '';
			}, 200);
		},
	},
};
</script>
