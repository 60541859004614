var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swiper--fade", attrs: { id: "TestimonialsCarousel" } },
    [
      _c(
        "Swiper",
        {
          ref: "swiper",
          attrs: { type: "carousel", options: _vm.swiperOptions }
        },
        _vm._l(_vm.slides, function(slide, i) {
          return _c("SwiperSlide", { key: i, staticClass: "h-auto" }, [
            _c(
              "div",
              {
                staticClass: "min-h-50vh h-full flex items-center",
                style: "background-color:" + slide.colour + ";"
              },
              [
                _c("div", { staticClass: "container py-rg md:py-lg h-full" }, [
                  _c(
                    "div",
                    { staticClass: "row justify-center h-full items-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col w-full md:w-8/12 text-center py-md md:py-lg"
                        },
                        [
                          _c("p", {
                            staticClass: "h4 mb-30 text-purple-dark",
                            domProps: {
                              innerHTML: _vm._s(
                                "" + _vm.marked(slide.text, { inline: true })
                              )
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "body-text text-purple-dark " },
                            [_vm._v(" " + _vm._s(slide.person))]
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }