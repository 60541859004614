<template>
	<Modal :open="open" @close="handleClose">
		<h1 class="text-mob-h3 md:text-h2 mb-sm">
			{{ childExists ? 'Update' : 'Add a child' }}
		</h1>
		<p class="body-text mb-md text-grey-500">
			{{ childExists ? 'Update your' : 'Add a ' }} child to receive personalised
			content recommendations
		</p>
		<FormulateForm ref="signupForm" @submit="saveChild()">
			<div v-for="(child, index) in children" :id="`${child.name.replace(' ', '-')}-${index}`"
				:key="`${child.name.replace(' ', '-')}-${index}`">
				<input type="hidden" :name="`fields[userChildren][${index}][type]`" value="child">
				<input type="hidden" :name="`fields[userChildren][${index}][enabled]`" value="1">
				<input type="hidden" :name="`fields[userChildren][${index}][fields][childName]`" :value="child.name">
				<input type="hidden" :name="`fields[userChildren][${index}][fields][dateOfBirth]`"
					:value="child.dateOfBirth.date">
				<div v-if="index !== activeId">
					<input v-for="tag in child.childTags" :key="`${tag.id}-${tag.handle}`" type="hidden"
						:name="`fields[userChildren][${index}][fields][childTags][]`" :value="tag.id">
				</div>
			</div>

			<input v-if="!remove" type="hidden" :name="`${newFieldNamePrefix}[enabled]`" value="1">
			<input type="hidden" :name="`${newFieldNamePrefix}[type]`" :value="remove ? 'null' : 'child'">

			<div class="step-1">
				<FormulateInput v-model="childName" type="text" :name="`${newFieldNamePrefix}[fields][childName]`" label="Name"
					validation="required" validation-name="Name" />
				<FormulateInput v-model="childAge" type="date" :name="`${newFieldNamePrefix}[fields][dateOfBirth]`"
					label="Date of Birth" label-class="label--small" outer-class="form-group--styled mt-sm" validation="required"
					validation-name="Date of Birth" />
			</div>
			<div class="step-2 ">
				<button v-for="cat in categories" :key="cat.id" role="button" type="button" class="btn btn--pill mt-15 mx-5"
					:class="{ active: childTags.includes(cat.id) }" @click="toggleTag(cat.id)">
					{{ cat.title }}
				</button>
			</div>
			<!-- <input type="submit" name="remove" value="Remove"> -->
			<button class="btn btn--outline mt-md" :class="{ loading: loading }" type="submit">
				{{ remove ? 'Remove' : 'Save' }}
			</button>
			<button v-if="childExists" class="btn btn--outline mt-md" type="button" :disabled="loading"
				:class="{ loading: loading && remove }" @click="removeChild(editableIndex)">
				Remove
			</button>
		</FormulateForm>
	</Modal>
</template>

<script>
import axios from 'axios';
import { getters, actions } from 'vstore/store';
import labelSlide from 'modules/label-slide';
import Modal from 'vcomponents/Modal';

export default {
	name: 'AddChild',
	components: {
		Modal,
	},
	props: {
		categories: {
			type: Array,
			required: true,
		},
		open: {
			type: Boolean,
			defautl: false,
		},
		children: {
			type: Array,
			required: true,
		},
		activeId: {
			type: Number,
			required: true,
		},
	},
	emits: ['submit', 'close'],
	data() {
		return {
			loading: false,
			// step: 0,
			// open: false,
			childName: undefined,
			childAge: undefined,
			childTags: [],
			//
			remove: false,
		};
	},
	computed: {
		newFieldNamePrefix() {
			return `fields[userChildren][${this.editableIndex}]`;
		},
		childExists() {
			return this.activeId !== this.children.length + 1;
		},
		childrenArray() {
			if (this.childExists && this.remove) {
				return this.filterOutActive();
			}
			return this.children;
		},
		editableIndex() {
			if (this.childExists) {
				return `${this.activeId}`;
			}
			return `new__${this.activeId}`;
		},
		activeChild() {
			if (this.childExists) {
				return this.children[this.activeId];
			}
			return undefined;
		},
		user() {
			return getters.user();
		},
		userId() {
			return getters.user().id;
		},
	},

	mounted() {
		actions.userInit();
		if (this.childExists) {
			this.childName = this.children[this.activeId].name;
			const activeChild = this.children[this.activeId];
			const dateOfBirth = activeChild.dateOfBirth.date.split(' ', 1);
			this.childAge = dateOfBirth[0];

			this.children[this.activeId].childTags.forEach((val) => {
				this.childTags.push(val.id);
			});
			// this.childTags = this.children[this.activeId].childTags;
		}
	},
	methods: {
		filterOutActive() {
			if (this.childExists) {
				const filtered = this.children.filter(
					(value, index) => index !== this.activeId,
				);
				// console.log(tempArray);
				return filtered;
			}
			return this.children;
		},
		stateCheck() {
			if (this.step >= 2) {
				const inputs = this.$el.querySelectorAll('input');
				labelSlide.init(inputs);
			}
		},

		handleClose() {
			this.$emit('close');
		},
		handleSignup() {
			this.loading = true;
		},
		toggleTag(val) {
			if (this.childTags.includes(val)) {
				this.childTags = this.childTags.filter(tag => tag !== val);
			} else {
				this.childTags.push(val);
			}
		},
		removeChild() {
			this.remove = true;
			this.$nextTick(() => {
				this.saveChild();
			});
		},
		saveChild() {
			this.loading = true;

			const formData = new FormData(this.$refs.signupForm.$el);

			formData.append('action', 'users/save-user');
			formData.append('CRAFT_CSRF_TOKEN', window.csrfToken);
			formData.append('userId', this.userId);
			this.childTags.forEach((val) => {
				formData.append(
					`fields[userChildren][${this.editableIndex}][fields][childTags][]`,
					val,
				);
			});

			axios
				.post('/', formData)
				.then((response) => {
					this.handleClose();
					this.$emit('submit', this.activeId);
					this.loading = false;
					return response.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
};
</script>
