var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-dropdown", class: { isOpen: _vm.isOpen } },
    [
      _c(
        "div",
        {
          staticClass: "selected relative",
          class: _vm.backgroundColor,
          on: { click: _vm.toggleIsOpen }
        },
        [
          _vm._v("\n\t\t" + _vm._s(_vm.selectedText) + "\n\t\t"),
          _c(
            "div",
            { staticClass: "chevron-wrap center-y right-neg-5 w-10 h-10" },
            [
              _c(
                "svg",
                {
                  staticClass:
                    "chevron w-full transition-transform duration-200 transform  origin-center ",
                  class: [_vm.isOpen ? "rotate-180" : "rotate-0"],
                  attrs: { viewBox: "0 0 9 5", fill: "none" }
                },
                [
                  _c("path", {
                    attrs: { d: "M1 1L4.5 4L8 1", stroke: "#E63331" }
                  })
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "list",
          staticClass: "vue-dropdown__option-list ",
          class: { isOpen: _vm.isOpen }
        },
        [
          _c(
            "div",
            { staticClass: "vue-dropdown__list-container" },
            _vm._l(_vm.options, function(o, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "vue-dropdown__list-item",
                  class: {
                    active:
                      _vm.selected &&
                      (_vm.selected === o.handle ||
                        _vm.selected === o.value ||
                        _vm.selected === o)
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleClick(o)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(o.title ? o.title : o) + "\n\t\t\t"
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }