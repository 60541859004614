<template>
  <SlideOut
    :open="slideOutOpen"
    wrap-class="bg-bodyColor"
    @close="slideOutOpen = false"
  >
    <div class="max-w-500">
      <h2 class="mb-30">How it works</h2>
      <div
        v-if="text.length"
        class="markdown markdown--reduced-spacing"
        v-html="marked(text)"
      />
      <div class="bg-white shadow-standard mt-40 p-sm md:p-40">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="flex"
          :class="{ 'mb-40': index < list.length - 1 }"
        >
          <span class="h2 pr-30 xs:min-w-100"> {{ getNumber(index) }}. </span>
          <div
            class="pr-sm markdown markdown--no-spacing"
            v-html="marked(item.listItem)"
          />
        </div>
      </div>
    </div>
  </SlideOut>
</template>

<script>
import { actions } from 'vstore/store';
import SlideOut from 'vcomponents/SlideOut';

export default {
  name: 'HowItWorks',
  components: {
    SlideOut,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slideOutOpen: false,
    };
  },
  mounted() {
    document.querySelector('#how-it-works').addEventListener('click', () => {
      this.slideOutOpen = true;
    });
  },
  methods: {
    getNumber(index) {
      return index + 1 < 10 ? '0' + (index + 1) : index + 1;
    },
    marked(value, options) {
      return actions.marked(value, options);
    },
  },
};
</script>
