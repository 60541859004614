<template>
<div id="card-carousel">
	<div
		v-if="windowWidth > 767"
		ref="swiper"
		type="carousel"
		class="vue-card-carousel"
		:class="[{ 'fade-in': !loading }, { 'fade-out': loading }]"
	>
		<Swiper
			type="carousel"
			class="vue-card-carousel"
			:options="{ ...swiperOptions, ...{ loopedSlides: slides.length } }"
		>
			<button slot="button-next" class="swiper-button-next" />
			<button slot="button-prev" class="swiper-button-prev" />
			<!-- Slide -->
			<SwiperSlide
				v-for="(slide, i) in slides"
				:key="`slide-${i}`"
				:class="slideClass"
			>
				<!-- CARD -->
				<div class="card-wrapper">
					<card :content="slide" />
				</div>
			</SwiperSlide>
		</Swiper>
	</div>
	<div v-else>
		<div
			v-for="(slide, i) in slides"
			:key="i"
			class="card-wrapper px-sm mb-30"
		>
			<card :content="slide" />
		</div>
	</div>
</div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { Swiper as SwiperClass, Autoplay, Navigation } from 'swiper/core';

import Card from 'vcomponents/Card';
// import ContentFilter from 'vcomponents/ContentFilter';

SwiperClass.use([Autoplay, Navigation]);
export default {
	name: 'RelatedCarousel',
	components: {
		Card,
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	props: {
		slides: {
			type: Array,
			required: true,
		},
	},
	emits: [],
	data() {
		return {
			windowWidth: '',
			loading: false,
			swiperOptions: {
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				centeredSlides: true,
				loop: true,
				slidesPerView: 2,
				spaceBetween: 30,
				autoplay: {
					delay: 5000,
				},
				breakpoints: {
					580: {
						slidesPerView: 2,
						spaceBetween: 30,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 30,
					},
					998: {
						slidesPerView: 4,
					},
					1200: {
						slidesPerView: 4,
					},
					1468: {
						slidesPerView: 5,
					},
				},
			},
		};
	},
	computed: {
		slideClass() {
			if (this.windowWidth >= 998) {
				return 'flex flex-nowrap';
			}
			return '';
		},
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.getWindowWidth);
			this.getWindowWidth();
		});
	},
	methods: {
		getWindowWidth() {
			this.windowWidth = window.innerWidth;
		},
	},
};
</script>
