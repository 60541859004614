<template>
<div id="resource-filter" class="overflow-hidden">
	<div v-if="tags.length" class="container">
		<div class="row">
			<content-filter
				:tags="tags"
				placeholder="1"
				bg="white"
				@updated="handleUpdate"
			/>
		</div>
	</div>
	<SmoothReflow>
		<div
			v-for="(item, i) in normaliseMembers"
			:key="`member-${handelize(item.name)}-${i}`"
			class="w-full xs:w-1/2 md:w-4/12 float-left relative overflow-hidden"
			:class="[{ 'fade-in': !loading }, { 'fade-out': loading }]"
		>
			<component
				:is="setVideoUrlString(item.videoLarge) ? 'a' : 'div'"
				class="member-tile zoom-image "
				:class="{ 'glightbox-team': item.videoLarge }"
				:href="setVideoUrlString(item.videoLarge)"
			>
				<p class="text-white z-40 absolute top-30 left-30">{{ item.name }}</p>
				<div
					class="bg-purple-dark w-fitFix h-fitFix p-20 md:p-30 z-40 member-tile__bio overflow-y-scroll"
				>
					<p v-if="item.type" class="text-white body-text mb-sm">
						<span class="text-lead capitalize">{{ item.type }} </span>
					</p>

					<div
						v-if="item.description !== null"
						class="markdown markdown--reduced-spacing text-white "
						v-html="marked(item.description)"
					/>
				</div>
				<img
					v-if="item.img"
					:src="item.img.url"
					:alt="item.img.title"
					class="object-fit center w-ftFix h-fitFix member-tile__image lazyload"
				>
				<video
					v-if="item.videoSmall"
					:src="item.videoSmall"
					loop
					autoplay
					allow="autoplay"
					playsinline
					muted
					play="true"
					class="w-ftFix h-fitFix object-cover center"
				/>
				<img
					v-if="item.videoSmall || item.videoLarge"
					src="/icons/play-video.svg"
					alt="play"
					class="z-10 center play-button"
				>
			</component>
		</div>
	</SmoothReflow>
</div>
</template>

<script>
import marked from 'marked';
import GLightbox from 'glightbox';
import { actions } from 'vstore/store';
import ContentFilter from 'vcomponents/ContentFilter';

export default {
	name: 'TeamFilter',
	components: {
		ContentFilter,
	},
	props: {
		tags: {
			type: Array,
			required: true,
		},
		members: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			loading: false,

			filters: {
				tag: '',
			},
			glightbox: '',
			allMembers: [],
			shownMembers: [],
		};
	},
	computed: {
		normaliseMembers() {
			const newArray = [];
			this.shownMembers.forEach((item) => {
				const newItem = item;
				newItem.img = actions.imgixImg(item.img);
				newArray.push(newItem);
			});
			return newArray;
		},
	},
	watch: {
		filters: {
			handler(val) {
				this.loading = true;
				this.shownMembers = [];
				if (this.glightbox) {
					this.glightbox.destroy();
				}
				const valCheck = this.handelize(val.tag);
				if (valCheck === 'all') {
					this.shownMembers = this.members;
					setTimeout((this.loading = false), 200);
					return;
				}

				this.shownMembers = this.members.filter(member => {
					let fitsFilter = false;
					member.category.forEach(cat => {
						if(	cat.slug === valCheck ){
							fitsFilter = true;
						}
					})
					return fitsFilter;
				});
				setTimeout((this.loading = false), 200);
				this.initGlightbox();
			},
			deep: true,
		},
	},
	mounted() {
		this.shownMembers = this.members;
		this.initGlightbox();
		if (this.tags.length) {
			this.filters.tag = this.tags[0].handle;
		}
	},
	methods: {
		marked(val) {
			return marked(val);
		},
		initGlightbox() {
			setTimeout(() => {
				this.glightbox = GLightbox({
					touchNavigation: true,
					loop: true,
					autoplayVideos: true,
					width: 'auto',
					selector: '.glightbox-team',
					openEffect: 'gfade',
					closeEffect: 'gfade',
					cssEfects: {
						// This are some of the animations included, no need to overwrite
						gfade: { in: 'gfadeIn', out: 'gfadeOut' },
					},
				});
			}, 200);
		},
		setVideoUrlString(url) {
			return url || false;
		},
		getVideos() {
			const elementArray = [];
			this.shownMembers.forEach((item) => {
				if (item.videoLarge) {
					elementArray.push({ href: item.videoLarge });
				}
			});
		},
		handleVideoModal(item) {
			if (item.videoLarge) {
				this.glightbox.open();
			}
		},
		handelize(str) {
			return str
				.replace('& ', '-')
				.replace(/\s/g, '-')
				.toLowerCase();
		},
		handleUpdate(value) {
			this.loading = true;
			// console.log(value);
			setTimeout(() => {
				this.filters.tag = value;
				this.loading = false;
			}, 200);
		},
	},
};
</script>
