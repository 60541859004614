var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlideOut",
    {
      attrs: { open: _vm.slideOutOpen },
      on: {
        close: function($event) {
          _vm.slideOutOpen = false
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "w-full " },
        [
          _c("div", { staticClass: "mb-40 md:mb-rg" }, [
            _c("h3", { staticClass: "text-white h3 " }, [
              _vm._v(_vm._s(_vm.heading))
            ]),
            _vm._v(" "),
            _vm.text.length
              ? _c("p", { staticClass: "text-white mt-sm" }, [
                  _vm._v("\n        " + _vm._s(_vm.text) + "\n      ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "FormulateForm",
            {
              ref: "form",
              class: { hidden: _vm.success },
              attrs: { method: "post", id: "newsletter-form" },
              on: { submit: _vm.handleSubmit }
            },
            [
              _c("input", {
                attrs: { type: "hidden", name: "tag" },
                domProps: { value: _vm.tag }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "hidden", name: "action" },
                domProps: { value: _vm.listId }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col w-full md:w-1/2" },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        type: "text",
                        name: "firstname",
                        label: "First Name",
                        "wrapper-class":
                          "formulate-input-wrapper form-group--white",
                        validation: "required"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col w-full md:w-1/2" },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        type: "text",
                        name: "lastname",
                        label: "Last Name",
                        "wrapper-class":
                          "formulate-input-wrapper form-group--white",
                        validation: "required"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col w-full md:w-1/2" },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        type: "text",
                        name: "phone",
                        label: "Phone",
                        "wrapper-class":
                          "formulate-input-wrapper form-group--white"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col w-full md:w-1/2" },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        type: "email",
                        name: "email",
                        label: "Email address",
                        "wrapper-class":
                          "formulate-input-wrapper form-group--white",
                        validation: "required"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("FormulateInput", {
                attrs: {
                  type: "checkbox",
                  name: "campaign",
                  label:
                    "I would like to receive Parenting Place emails with latest articles and parenting information.*",
                  "wrapper-class": "formulate-input-wrapper form-group--white",
                  validation: "required"
                },
                model: {
                  value: _vm.accepted,
                  callback: function($$v) {
                    _vm.accepted = $$v
                  },
                  expression: "accepted"
                }
              }),
              _vm._v(" "),
              _c("FormulateInput", {
                attrs: {
                  type: "checkbox",
                  name: "privacy",
                  "wrapper-class": "formulate-input-wrapper form-group--white",
                  validation: "required"
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function(ref) {
                      var id = ref.id
                      return [
                        _c("label", { attrs: { for: id } }, [
                          _vm._v(
                            "\n            I have read and agreed to the parentingplace.nz\n            "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/privacy-policy",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Privacy Policy *")]
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn--outline-white mt-40 md:mt-rg",
                  class: { loading: _vm.loading }
                },
                [_vm._v("\n        Subscribe\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.error.length
            ? _c("p", { staticClass: "error pt-sm text-white " }, [
                _vm._v(_vm._s(_vm.error))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "text-white",
              class: { hidden: !_vm.success },
              attrs: { id: "newsletter-success" }
            },
            [_vm._v("\n      " + _vm._s(_vm.successMessage) + "\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }