<template>
<div class="slideout-container">
	<aside class="js-popout " :class="[{ active: open }, classList]">
		<button
			class="js-close absolute top-25 right-30 lg:right-60 general-hover"
			:class="wrapClass === 'bg-bodyColor' ? 'text-navy' : 'text-white'"
			@click="handleClose()"
		>
			<svg width="20" height="20" viewBox="0 0 20 20">
				<path d="M1 1L20 20" />
				<path d="M20 1L1 20" />
			</svg>
		</button>

		<div
			class="px-30 sm:px-rg  py-lg sm:py-xl flex items-center flex-wrap text-left"
		>
			<slot />
		</div>
	</aside>
	<backdrop :open="open" @close="handleClose()" />
</div>
</template>

<script>
import { stopScroll, resumeScroll } from 'utils/scrollControl';
import Backdrop from 'vcomponents/Backdrop';

export default {
	name: 'SlideOut',
	components: {
		Backdrop,
	},
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		wrapClass: {
			type: String,
			default: 'bg-red',
		},
	},
	emits: ['close'],
	computed: {
		classList() {
			let classList = '';
			if (open === true) {
				classList = ` active ${this.wrapClass}`;
			} else {
				classList = this.wrapClass;
			}
			return classList;
		},
	},
	watch: {
		open(newValue) {
			if (newValue) {
				stopScroll();
			} else {
				resumeScroll();
			}
		},
	},
	methods: {
		handleClose() {
			// this.open = false;
			this.$emit('close', true);
		},
	},
};
</script>
