var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-hidden", attrs: { id: "resource-filter" } },
    [
      _vm.tags.length
        ? _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("content-filter", {
                  attrs: { tags: _vm.tags, placeholder: "1", bg: "white" },
                  on: { updated: _vm.handleUpdate }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "SmoothReflow",
        _vm._l(_vm.normaliseMembers, function(item, i) {
          return _c(
            "div",
            {
              key: "member-" + _vm.handelize(item.name) + "-" + i,
              staticClass:
                "w-full xs:w-1/2 md:w-4/12 float-left relative overflow-hidden",
              class: [{ "fade-in": !_vm.loading }, { "fade-out": _vm.loading }]
            },
            [
              _c(
                _vm.setVideoUrlString(item.videoLarge) ? "a" : "div",
                {
                  tag: "component",
                  staticClass: "member-tile zoom-image ",
                  class: { "glightbox-team": item.videoLarge },
                  attrs: { href: _vm.setVideoUrlString(item.videoLarge) }
                },
                [
                  _c(
                    "p",
                    { staticClass: "text-white z-40 absolute top-30 left-30" },
                    [_vm._v(_vm._s(item.name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-purple-dark w-fitFix h-fitFix p-20 md:p-30 z-40 member-tile__bio overflow-y-scroll"
                    },
                    [
                      item.type
                        ? _c(
                            "p",
                            { staticClass: "text-white body-text mb-sm" },
                            [
                              _c(
                                "span",
                                { staticClass: "text-lead capitalize" },
                                [_vm._v(_vm._s(item.type) + " ")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.description !== null
                        ? _c("div", {
                            staticClass:
                              "markdown markdown--reduced-spacing text-white ",
                            domProps: {
                              innerHTML: _vm._s(_vm.marked(item.description))
                            }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  item.img
                    ? _c("img", {
                        staticClass:
                          "object-fit center w-ftFix h-fitFix member-tile__image lazyload",
                        attrs: { src: item.img.url, alt: item.img.title }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.videoSmall
                    ? _c("video", {
                        staticClass: "w-ftFix h-fitFix object-cover center",
                        attrs: {
                          src: item.videoSmall,
                          loop: "",
                          autoplay: "",
                          allow: "autoplay",
                          playsinline: "",
                          muted: "",
                          play: "true"
                        },
                        domProps: { muted: true }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.videoSmall || item.videoLarge
                    ? _c("img", {
                        staticClass: "z-10 center play-button",
                        attrs: { src: "/icons/play-video.svg", alt: "play" }
                      })
                    : _vm._e()
                ]
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }