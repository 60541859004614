<template>
  <div id="cart-slideout">
    <div class="js-popout px-30 pt-30 bg-white" :class="{ active: open }">
      <div v-if="lineItems && lineItems.length" class="w-full">
        <button
          class="close absolute top-25 right-30 general-hover"
          @click="closeCart"
        >
          <svg width="20" height="20" viewBox="0 0 20 20">
            <path d="M1 1L20 20" stroke="black" />
            <path d="M20 1L1 20" stroke="black" />
          </svg>
        </button>
        <div class="flex flex-wrap justify-center">
          <div class="col pb-sm">
            <h6 class="h6">Shopping Cart</h6>
          </div>

          <div class="cart__items" :class="{ loading: loading }">
            <SmoothReflow>
              <div
                v-for="(item, index) in lineItems"
                :key="`${item.handle}-${index}`"
                class="cart__item "
              >
                <div class="w-4/12 md:w-3/12 p">
                  <img :src="item.image" />
                </div>
                <div class="w-8/12 md:w-4/12 cart__item-title mb-sm lg:mb-0">
                  <p class="body-copy">
                    {{ item.title }}
                  </p>
                  <button
                    class="underline body-text text-left text-sm pt-xs"
                    @click="removeLineItem(item.id, item.qty, item.type)"
                  >
                    Remove
                  </button>
                </div>

                <div class="w-5/12 xs:w-4/12 lg:w-2/12 text-right">
                  <p class="body-copy">
                    {{ item.salePrice ? item.salePrice : item.variantPrice }}
                  </p>
                </div>
                <div
                  class="w-5/12 xs:w-4/12 lg:w-3/12 flex flex-nowrap items-start justify-end"
                >
                  <p class="body-text">Qty: &nbsp;</p>
                  <input
                    v-model="item.qty"
                    type="number"
                    name="qty"
                    min="1"
                    class="border-black border-b-1 w-50 text-center"
                    @blur="updateQty(item.id, $event.target.value)"
                  />

                  <!---->
                  <!-- <p class="body-copy">Qty: {{ item.qty}}</p> -->
                </div>
              </div>
            </SmoothReflow>
          </div>

          <div
            class="errors  w-full"
            :style="{ 'height:auto;': cartError != '' }"
          >
            <p v-if="cartError != ''" class="body-text text-red  py-15">
              {{ cartError }}
            </p>
          </div>

          <div class="w-full pb-sm">
            <div
              class="pt-sm pb-sm border-t-1 border-b-1 relative flex flex-wrap items-center justify-between"
            >
              <p
                v-if="couponMessage"
                class="body-copy text-red pt-xs pr-sm w-full lg:w-1/2"
              >
                {{ couponMessage }}
              </p>
              <div class="flex justify-end items-end ml-auto ">
                <input
                  v-model="couponCode"
                  type="text"
                  class="form-field body-text pt-xs border-b-1 border-black mr-md"
                  placeholder="Discount Code"
                />

                <button
                  class="border-b-1 text-12 px-5"
                  @click="submitDiscountCode()"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap justify-center items-end">
          <div class="pb-sm md:w-1/2">
            <a href="/checkout" class="btn btn--primary" @click="closeCart()">
              Proceed to checkout</a
            >
          </div>
          <div class="pb-sm md:w-1/2">
            <div class="flex flex-wrap">
              <div class="offset-3/12 w-9/12 flex justify-between mb-xs">
                <p class="body-copy">Subtotal:</p>
                <p class="body-copy">{{ formatMoney(cart.itemSubtotal) }}</p>
              </div>
              <div
                v-if="cart.totalDiscount != 0"
                class="offset-3/12 w-9/12 flex justify-between mb-xs"
              >
                <p class="body-copy">Discount:</p>
                <p class="body-copy">{{ cart.totalDiscountAsCurrency }}</p>
              </div>

              <div
                v-if="cart.totalSaleAmount"
                class="offset-3/12 w-9/12 flex justify-between mb-xs"
              >
                <p class="body-copy">Sale (incl):</p>
                <p class="body-copy">{{ formatMoney(cart.totalSaleAmount) }}</p>
              </div>

              <div
                v-if="cart.storedTotalShippingCost !== 0.0"
                class="offset-3/12 w-9/12 flex justify-between mb-xs"
              >
                <p class="body-copy">Total Shipping:</p>
                <p class="body-copy">
                  {{ cart.storedTotalShippingCostAsCurrency }}
                </p>
              </div>
              <div
                v-for="(adj, index) in cart.orderAdjustments"
                :key="`adj-${index}`"
                class="offset-3/12 w-9/12 flex justify-between mb-xs"
              >
                <span v-if="adj.type === 'tax'">
                  <p class="body-copy">GST (incl):</p>
                  <p class="body-copy">{{ formatMoney(adj.amount) }}</p>
                </span>
              </div>
              <div class="offset-3/12 w-9/12 flex justify-between">
                <p class="body-copy">Total</p>
                <p class="body-copy">{{ formatMoney(cart.total) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="col w-full ">
        <div class="py-40  relative">
          <p class="body-copy mr-xs">There are no items in your cart</p>
          <button
            class="btn btn--primary mt-sm inline-block w-auto"
            @click="closeCart()"
          >
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
    <Backdrop :open="open" @close="closeCart()" @click="closeCart()" />
  </div>
</template>

<script>
import axios from 'axios';
import { mutations, getters, actions } from 'vstore/store';
import Backdrop from 'vcomponents/Backdrop';
import SmoothReflow from '../components/SmoothReflow.vue';

export default {
  name: 'Cart',
  components: {
    Backdrop,
    SmoothReflow,
  },
  props: ['check'],
  data() {
    return {
      states: [],
      selectedState: null,
      customerAddress: null,
      couponCode: '',
    };
  },
  computed: {
    csrf() {
      return {
        name: getters.csrfName(),
        token: getters.csrfToken(),
      };
    },
    open() {
      return getters.cartOpen();
    },
    cart() {
      return getters.cart();
    },
    lineItems() {
      return getters.lineItems();
    },
    couponMessage() {
      return getters.couponMessage();
    },
    loading() {
      return getters.loading();
    },
    cartError() {
      return getters.cartError();
    },
  },

  watch: {
    open() {
      actions.fetchCart();
      actions.fetchLineItems();
    },
  },
  mounted() {
    actions.fetchCart();
    actions.fetchLineItems();
  },

  methods: {
    console(test) {
      console.log(test);
    },
    isSub(type) {
      return type.toLowerCase() === 'subscriptions';
    },
    closeCart() {
      mutations.setCartOpen(false);
    },
    formatMoney(amount) {
      const formatter = new Intl.NumberFormat('en-NZ', {
        style: 'currency',
        currency: 'NZD',
      });

      return formatter.format(amount);
    },
    submitDiscountCode() {
      actions.submitDiscountCode(this.couponCode);
    },
    updateQty(id, qty) {
      actions.updateQty(id, qty).then(() => {
        actions.fetchCart();
      });
    },
    removeLineItem(id, qty, type) {
      const data = new FormData();
      data.append(this.csrf.name, this.csrf.token);
      data.append('action', '/commerce/cart/update-cart');
      data.append(`lineItems[${id}][remove]`, 1);

      // needs to go one after the other async or sync
      axios
        .post('/', data, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => {
          if (response.status == '200') {
            actions.fetchCart();
            actions.fetchLineItems();
          }
        });
    },

    updateShippingMethod(id, qty) {
      const data = new FormData();
      data.append(this.csrf.name, this.csrf.token);
      data.append('action', '/commerce/cart/update-cart');
      data.append('shippingAddress[countryId]', 171);
      data.append('shippingAddress[stateValue]', this.selectedState.id);
      data.append('billingAddressSameAsShipping', 1);

      axios
        .post('/', data, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => {
          if (response.data.success) {
            // console.log(response);
            actions.fetchCart();
          }
        });
    },
  },
};
</script>

<style lang="scss">
.cart__item {
  @apply flex flex-wrap py-4 border-t relative justify-end;
  opacity: 1;
  transition: opacity 0.2s ease;
}
.cart__item-title {
  @apply flex flex-col justify-between text-left px-xs;
}
.cart__items {
  @apply w-full;
}
.cart__items.loading {
  position: relative;
  .cart__item {
    opacity: 0.2;
    pointer-events: none;
  }
  &:after {
    content: '';
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px red solid;
    border-right: 2px transparent solid !important;
    animation: spin-centered 0.5s infinite both linear;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
</style>
