var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "course-venue-selector" } },
    [
      _vm.collapse
        ? _c(
            "div",
            {
              staticClass:
                "select flex flex-wrap items-center font-diatype text-grey-500 uppercase text-small mb-sm"
            },
            [
              _c(
                "label",
                {
                  staticClass: "font-diatype text-grey-500 uppercase text-small"
                },
                [_vm._v("\n\t\t\tLocation:\n\t\t")]
              ),
              _vm._v(" "),
              _c("Dropdown", {
                attrs: {
                  value: _vm.activeVenue,
                  placeholder: "1",
                  options: _vm.dropdownOptions
                },
                on: { selected: _vm.updateActiveVenue }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("SmoothReflow", [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }