<template>
  <SlideOut :open="slideOutOpen" @close="slideOutOpen = false">
    <div class="w-full ">
      <div class="mb-40 md:mb-rg">
        <h3 class="text-white h3 ">{{ heading }}</h3>
        <p class="text-white mt-sm" v-if="text.length">
          {{ text }}
        </p>
      </div>
      <FormulateForm method="post" @submit="handleSubmit" ref="form" id="newsletter-form" :class="{ hidden: success }">
        <input type="hidden" name="tag" :value="tag" />
        <input type="hidden" name="action" :value="listId" />
        <div class="row">
          <div class="col w-full md:w-1/2">
            <FormulateInput type="text" name="firstname" label="First Name"
              wrapper-class="formulate-input-wrapper form-group--white" validation="required" />
          </div>
          <div class="col w-full md:w-1/2">
            <FormulateInput type="text" name="lastname" label="Last Name"
              wrapper-class="formulate-input-wrapper form-group--white" validation="required" />
          </div>
          <div class="col w-full md:w-1/2">
            <FormulateInput type="text" name="phone" label="Phone"
              wrapper-class="formulate-input-wrapper form-group--white" />
          </div>

          <div class="col w-full md:w-1/2">
            <FormulateInput type="email" name="email" label="Email address"
              wrapper-class="formulate-input-wrapper form-group--white" validation="required" />
          </div>
        </div>
        <FormulateInput type="checkbox" name="campaign"
          label="I would like to receive Parenting Place emails with latest articles and parenting information.*"
          wrapper-class="formulate-input-wrapper form-group--white" validation="required" v-model="accepted" />
        <FormulateInput type="checkbox" name="privacy" wrapper-class="formulate-input-wrapper form-group--white"
          validation="required">
          <template #label="{ id }">
            <label :for="id">
              I have read and agreed to the parentingplace.nz
              <a href="/privacy-policy" target="_blank">Privacy Policy *</a>
            </label>
          </template>
        </FormulateInput>

        <button class="btn btn--outline-white mt-40 md:mt-rg" :class="{ loading: loading }">
          Subscribe
        </button>
      </FormulateForm>
      <p v-if="error.length" class="error pt-sm text-white ">{{ error }}</p>
      <p id="newsletter-success" class="text-white" :class="{ hidden: !success }">
        {{ successMessage }}
      </p>
    </div>
  </SlideOut>
</template>

<script>
import axios from 'axios';
import { actions, getters } from 'vstore/store';
import SlideOut from 'vcomponents/SlideOut';

export default {
  name: 'NewsletterSlideout',
  components: {
    SlideOut,
  },
  props: {
    listId: {
      type: String,
      default: 'donation/hub-spot/add-contact',
    },
    heading: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'Parenting Tips',
    },
    trigger: {
      type: String,
      default: undefined,
    },
    successMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      slideOutOpen: false,
      success: false,
      triggers: undefined,
      accepted: true,
      error: '',
    };
  },

  mounted() {
    let selector = this.trigger ? this.trigger : '#newsletter-signup';

    this.triggers = document.querySelectorAll(`[href="${selector}"]`);
    this.triggers.forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        e.stopPropagation();
        this.slideOutOpen = true;
      });
    });
    if (window.location.href.includes('#newsletter-signup')) {
      this.slideOutOpen = true;
    }
  },
  methods: {
    marked(value, options) {
      return actions.marked(value, options);
    },
    handleSubmit(event) {
      this.loading = true;
      const data = new FormData(this.$refs.form.$el);
      data.append(getters.csrfName(), getters.csrfToken());
      axios
        .post('/', data)
        .then((response) => {
          console.log(response)
          this.loading = false;
          console.log(typeof response.data)
          if (typeof response.data === "string" && response.data.includes("Contact already exists")) {
            this.error = "You're already subscribed!";
            return;
          }
          this.error = '';
          this.success = true;
          return;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          console.log(error);
          this.error = 'Sorry, something went wrong. Please try again later';
        });
    },
  },
};
</script>
