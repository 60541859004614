<template>
<div id="TestimonialsCarousel" class="swiper--fade">
	<Swiper ref="swiper" type="carousel" :options="swiperOptions">
		<SwiperSlide v-for="(slide, i) in slides" :key="i" class="h-auto">
			<div
				class="min-h-50vh h-full flex items-center"
				:style="`background-color:${slide.colour};`"
			>
				<div class="container py-rg md:py-lg h-full">
					<div class="row justify-center h-full items-center">
						<div class="col w-full md:w-8/12 text-center py-md md:py-lg">
							<p class="h4 mb-30 text-purple-dark" v-html="`${marked(slide.text, {inline: true} )}`" />
							<p class="body-text text-purple-dark "> {{ slide.person }}</p>
						</div>
					</div>
				</div>
			</div>
		</SwiperSlide>
	</Swiper>
</div>
</template>

<script>
import { Swiper as SwiperClass, Autoplay, EffectFade } from 'swiper/core';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

import { actions } from 'vstore/store';

SwiperClass.use([Autoplay, EffectFade]);

export default {
	name: 'TestimonialsCarousel',
	components: {
		Swiper,
		SwiperSlide,
	},
	directives: {
		swiper: directive,
	},
	props: {
		slides: {
			type: Array,
			required: true,
		},
	},
	emits: [],
	data() {
		return {
			activeSlide: '',

			swiperOptions: {
				effect: 'fade',
				fadeEffect: { crossFade: true },
				centeredSlides: true,
				loop: true,
				loopedSlides: this.slides.length,
				slidesPerView: 1,
				spaceBetween: 0,
				// Responsive breakpoints
				autoplay: {
					delay: 7000,
				},
			},
		};
	},

	methods: {
		marked(val, options) {
			return actions.marked(val, options);
		},
	},
};
</script>
