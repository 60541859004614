<template>
  <div class="shipping-method-form checkout-step">
    <div class="checkout-step__header">
      <h2 class="checkout-step__title h5 ">3. Shipping</h2>
    </div>
    <SmoothReflow
      :options="{
        transitionEvent: {
          selector: '.reflow-element',
          propertyName: 'opacity',
        },
      }"
      class="reflow"
    >
      <transition-group name="fade">
        <!-- Shipping Mehtod Form -->
        <div
          v-show="!valid && step == 3"
          key="form"
          class="checkout-step__inner reflow-element pt-25"
        >
          <FormulateForm
            v-model="values"
            class="checkout-step__form form"
            @submit="handleSubmit"
          >
            <div class="form__hidden" />
            <div class="form__inner">
              <div
                v-if="Object.keys(shippingMethods).length > 1"
                class="form__row"
              >
                <FormulateInput
                  v-model="selectedShippingMethod"
                  type="radio"
                  name="shippingMethodHandle"
                  :options="shippingMethods"
                  validation="^required"
                  validation-name="This field is required"
                  wrapper-class="py-xs"
                  outer-class=""
                />
              </div>
              <div v-else class="free-shipping">
                Standard Shipping
              </div>

              <div class="form__row">
                <div class="form__submit-wrapper pt-25">
                  <button
                    class="btn btn--primary"
                    :class="{ loading: loading }"
                    type="submit"
                  >
                    Save &amp; continue
                  </button>
                </div>
              </div>
            </div>
          </FormulateForm>
        </div>
        <!-- Summary -->
        <div
          v-show="valid"
          key="summary"
          class="checkout-step__summary reflow-element pt-25"
        >
          <div class="address">
            <p class="address__row">
              {{ selectedShippingMethod }}
            </p>
            <button
              class="underline"
              type="button"
              value="Edit"
              @click="stepBack"
            >
              Edit
            </button>
          </div>
        </div>
      </transition-group>
    </SmoothReflow>
  </div>
</template>

<script>
import axios from 'axios';
import { getters, mutations } from 'vstore/store';

export default {
  name: 'ShippingMethod',
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['updated', 'shipping', 'edit'],
  data() {
    return {
      loading: false,
      valid: false,
      values: {},
      selectedShippingMethod: '',
    };
  },
  computed: {
    cart() {
      return getters.cart();
    },
    shippingMethods() {
      const shippingObject = {};

      if (this.cart.availableShippingMethodOptions) {
        const availableMethods = this.cart.availableShippingMethodOptions;
        const array = Object.keys(this.cart.availableShippingMethodOptions);
        array.forEach((item) => {
          // console.log(item);
          const object = {
            [item]: `${availableMethods[item].name} $${
              availableMethods[item].price
            }`,
          };
          Object.assign(shippingObject, object);
        });
      }
      return shippingObject;
    },
  },

  watch: {
    step() {
      if (this.shippingMethods && this.cart.availableShippingMethodOptions) {
        const firstMethod = this.cart.availableShippingMethodOptions[
          this.selectedShippingMethod
        ];
        this.values.shippingMethodHandle = firstMethod ? firstMethod.name : '';
      }
    },
    selectedShippingMethod(newValue) {
      this.$emit('shipping', newValue);
    },
  },

  methods: {
    handleSubmit() {
      this.loading = true;
      if (this.selectedShippingMethod === '') {
        this.selectedShippingMethod = 'Standard Shipping';
        this.values.shipping = 'Standard Shipping';
      }
      this.storedValues = this.values;

      // parse the data
      const data = new FormData();
      data.append('action', 'commerce/cart/update-cart');
      data.append('CRAFT_CSRF_TOKEN', getters.csrfToken());
      data.append('shippingMethodHandle', this.selectedShippingMethod);
      if (this.cart.couponCode) {
        data.append('couponCode', this.cart.couponCode);
      }
      // send the data
      axios
        .post('/', data, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => response.data)
        .then((response) => {
          // console.log('Success:', data);
          this.valid = true;
          this.loading = false;
          mutations.setCart(response.cart);
          this.$emit('updated', { data: response.cart });
        })
        .catch((error) => {
          console.error('Error:', error);
          this.loading = false;
        });
    },
    stepBack() {
      // this is step 3
      this.$emit('edit', 3);
      this.valid = false;
    },
  },
};
</script>
